import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';

const CollapsingAccordion = (props) => {
  const {
    title,
    children,
  } = props;

  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

CollapsingAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CollapsingAccordion;
