import styled, { css } from 'styled-components';
import { Input as SInput } from 'reactstrap';

export const Input = styled(SInput)`
  ${({ theme }) => css`
    &.form-control {
        padding: 0.675rem 1.875rem;
        height: 3.875rem;
        font-size: ${theme.fonts.sizes.medium};
    }
  `} 
`;
