import { ActionTypes } from '../actions/user';
import { getAuthData } from '../../storage';

const checkAuthentication = () => {
  const accessData = getAuthData();
  return Boolean(accessData && accessData.token);
};

const INITIAL_STATE = {
  id: '',
  name: '',
  email: '',
  isAuthenticated: checkAuthentication(),
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.DO_LOGIN:
      return {
        ...payload.user,
        isAuthenticated: checkAuthentication(),
      };
    case ActionTypes.DO_LOGOUT:
      return {
        ...INITIAL_STATE,
        isAuthenticated: false,
      };
    case ActionTypes.SET_USER_ID:
      return {
        id: payload.id,
        isAuthenticated: false,
      };
    default: return state;
  }
};

export default userReducer;
