import React from 'react';
import PropTypes from 'prop-types';
import { ContainerLabel } from './styles';

const FieldLabel = ({ required, htmlFor, labelText }) => (
  <ContainerLabel htmlFor={htmlFor}>
    {labelText}
    {required && (
      <span>
        *
      </span>
    )}
  </ContainerLabel>
);

FieldLabel.defaultProps = {
  required: false,
  labelText: '',
};

FieldLabel.propTypes = {
  required: PropTypes.bool,
  htmlFor: PropTypes.string.isRequired,
  labelText: PropTypes.string,
};

export default FieldLabel;
