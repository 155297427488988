import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 50px;
  margin-right: 2.5rem;
  padding: 0px;
  position: relative;
  cursor: initial;
`;

export const Title = styled.h2`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
  font-weight: bold;
  color: #303D31;
  font-size: 2.25rem;
`;

export const ImageDate = styled.h6`
  font-size: 14px;
  font-weight: bold;
  color: #707070;
  margin-left: 20px;
  margin-bottom: 16px;
`;

export const ContainerIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 3px;
  background-color: #fff;
  bottom: 260px;
  left: 10px;
  z-index: 10;
`;

export const ContainerLegend = styled.div`
  padding: 20px;
  flex: 1
`;
