import styled from 'styled-components';

const CardGrain = styled.div`
    height: 12.125rem;
    width: 12.75rem;
    border-radius: 10px;
    border: 1px solid #ECEFEA;
    background-color: #fff;
    text-align: center;
    padding: 1rem 1.2rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const CultureImg = styled.img`
    width: 2.688rem;
    height: 2.688rem;
    margin-top: 1rem;
`;

const TypeName = styled.span`
  font-size: 0.875rem;
  font-weight: normal;
  margin-top: 0.675rem;
  color: #314B4D;
`;

const Category = styled.span`
  margin-top: 10px;
  font-size: 1.125rem;
  font-weight: bold;
  color: #314B4D;
`;

export {
  CardGrain,
  TypeName,
  Category,
  Content,
  CultureImg,
};
