import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from './styles';

const SwitchField = ({
  id,
  label,
  onChange,
  value,
}) => (
  <Toggle
    id={id}
    type="switch"
    label={label}
    checked={value}
    onChange={onChange}
  />
);

SwitchField.defaultProps = {
  id: 'switch',
  label: '',
  onChange: null,
  value: true,
};

SwitchField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default SwitchField;
