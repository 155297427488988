import { DateTime } from 'luxon';

export const IRRIGATION_HUMIDITY_COLOR = '#64B433';
export const IRRIGATION_CRITICAL_HUMIDITY = '#FF4444';

export const FORECAST_HUMIDITY_COLOR = '#E3F5C4';
export const FORECAST_CRITICAL_HUMIDITY = '#FCABAB';

export const CLIMATE_COLOR_STANDARD_DEVIATION = '#E5F0F1';
export const CLIMATE_COLOR_MONTHLY_AVERAGE = '#A2C667';
export const COLOR_LAST_PRECIPITATION = '#068EC5';

export const PRECIPITATION_COLOR = '#008BC4';
export const FORESCAT_LIGHT_GREEN = '#E3F5C4';
export const FORESCAT_GREEN = '#9DD540';
export const FORESCAT_DARK_GREEN = '#668D30';

export const CLIMATE_COLOR_STANDARD_DEVIATION_MAX_TEMPERATURE = '#EFA2A8';
export const CLIMATE_COLOR_MONTHLY_AVERAGE_MAX_TEMPERATURE = '#DD1015';
export const COLOR_LAST_MAX_TEMPERATURE = '#15740E';
export const CLIMATE_COLOR_STANDARD_DEVIATION_MIN_TEMPERATURE = '#A1F2F5';
export const CLIMATE_COLOR_MONTHLY_AVERAGE_MIN_TEMPERATURE = '#0008E5';
export const COLOR_LAST_MIN_TEMPERATURE = '#E7B65A';

export const COLOR_NDVI_LAST_YEAR = '#4185F4';
export const COLOR_NDVI_CURRENT_YEAR = '#EA4335';
export const COLOR_NDVI_THEORETICAL_CURVE = '#FBBC02';

export const chartLegendList = {
  climate: [
    {
      color: COLOR_LAST_PRECIPITATION,
      label: 'Precipitação dos últimos 12 meses',
    },
    {
      color: CLIMATE_COLOR_MONTHLY_AVERAGE,
      label: 'Média mensal dos anos (2001 a 2019)',
    },
    {
      color: CLIMATE_COLOR_STANDARD_DEVIATION,
      label: 'Desvio Padrão',
    },
  ],
  temperature: [
    {
      color: COLOR_LAST_MAX_TEMPERATURE,
      label: 'Temperatura máxima dos últimos 12 meses',
    },
    {
      color: CLIMATE_COLOR_MONTHLY_AVERAGE_MAX_TEMPERATURE,
      label: 'Média mensal máxima dos anos (2001 a 2019)',
    },
    {
      color: COLOR_LAST_MIN_TEMPERATURE,
      label: 'Temperatura mínima dos últimos 12 meses',
    },
    {
      color: CLIMATE_COLOR_MONTHLY_AVERAGE_MIN_TEMPERATURE,
      label: 'Média mensal mínima dos anos (2001 a 2019)',
    },
  ],
  irrigation: [
    {
      color: IRRIGATION_HUMIDITY_COLOR,
      label: 'Favorável (%)',
    },
    {
      color: IRRIGATION_CRITICAL_HUMIDITY,
      label: 'Crítica (%)',
    },
    {
      color: FORECAST_HUMIDITY_COLOR,
      label: 'Previsão Favorável (%)',
    },
    {
      color: FORECAST_CRITICAL_HUMIDITY,
      label: 'Previsão Crítica (%)',
    },
  ],
  precipitation: [
    {
      color: PRECIPITATION_COLOR,
      label: 'Chuva Observada por Satélite',
    },
  ],
  ndvi: [
    {
      color: COLOR_NDVI_LAST_YEAR,
      label: `Anterior (${DateTime.now().year - 1})`,
    },
    {
      color: COLOR_NDVI_CURRENT_YEAR,
      label: `Atual (${DateTime.now().year})`,
    },
    {
      color: COLOR_NDVI_THEORETICAL_CURVE,
      label: 'Curva teórica',
    },
  ],
};
