import React from 'react';
import PropTypes from 'prop-types';
import SoilContent from '../soil';
import OtherFieldsContent from '../otherFields';

const StepOtherFields = ({
  values, setFieldValue,
}) => (
  <div>
    <OtherFieldsContent values={values} setFieldValue={setFieldValue} />
    {values.category && <SoilContent values={values} setFieldValue={setFieldValue} />}
  </div>
);

StepOtherFields.propTypes = {
  values: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default StepOtherFields;
