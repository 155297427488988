import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import { FormGroup } from 'reactstrap';

import {
  ButtonsField,
  ButtonCancel,
  ButtonNext,
} from './styles';

import CultureContent from '../../components/culture';
import { minDate } from '../../../../helpers/date-helper';

const Content = (props) => {
  const {
    handleSubmit,
    values,
    setFieldValue,
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <CultureContent values={values} setFieldValue={setFieldValue} />
      <FormGroup>
        <ButtonsField>
          <h6>* Campo de preenchimento obrigatório</h6>
          <div>
            <Link to="/fields">
              <ButtonCancel>Cancelar</ButtonCancel>
            </Link>
            <Link to="/fields">
              <ButtonNext>Salvar</ButtonNext>
            </Link>
          </div>
        </ButtonsField>
      </FormGroup>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  category: Yup.number().required('O campo cultivar é obrigatório'),
  subCategoryId: Yup.object().required('O campo cultivar é obrigatório').nullable(),
  sowingPlantingDate: Yup.date().required('O campo data de semeadura ou plantio é obrigatório').min(minDate),
  productionCycle: Yup.number().required('O campo ciclo de produção é obrigatório e deve ser um número inteiro positivo').positive().integer(),
});

Content.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validationSchema,
  mapPropsToValues: () => ({
    category: null,
    subCategoryId: null,
    sowingPlantingDate: '',
    productionCycle: 0,
    productionCycleUnit: 1,
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handleSaveField(values),
})(Content);
