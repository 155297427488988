import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import {
  Form,
  FormGroup,
} from 'reactstrap';
// Components
import { ButtonGreen, LinkButton } from '../../../../components/styles/button';
import FormField from '../../../../components/forms/formField';
import AboutContainer from '../../../../components/aboutContainer';

import * as S from '../../styles';

const ResetPasswordForm = (props) => {
  const {
    handleSubmit,
  } = props;

  return (
    <S.Layout>
      <S.LeftContainer width={500}>
        <S.TitleRegister>Alterar senha</S.TitleRegister>
        <S.SubtitleRegister>
          Digite o e-mail associado com sua conta, e nós enviaremos um e-mail com instruções para resetar sua senha.
        </S.SubtitleRegister>
        <Form onSubmit={handleSubmit} noValidate>
          <FormGroup>
            <FormField
              id="email"
              name="email"
              label="E-mail"
              placeholder="E-mail"
              isRequired
            />
          </FormGroup>
          <FormGroup>
            <S.ButtonsRegister>
              <ButtonGreen type="submit">ENVIAR INSTRUÇÕES</ButtonGreen>
              <LinkButton to="/" className="btn">VOLTAR</LinkButton>
            </S.ButtonsRegister>
          </FormGroup>
        </Form>
        <S.LinksContainer>
          <S.Label>
            Já possui um código?
            {' '}
            <S.LinkLabel to="/have-code">ENTRE AQUI.</S.LinkLabel>
          </S.Label>
        </S.LinksContainer>
      </S.LeftContainer>
      <S.Content>
        <AboutContainer />
      </S.Content>
    </S.Layout>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('O campo email é obrigatório'),
});

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema,
  mapPropsToValues: () => ({
    email: '',
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handleResetPassword(values),
})(ResetPasswordForm);
