import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const GoBackBtn = styled.div`
  position: absolute;
  z-index: 10;
  top: 5px;
  left: 5px;
`;

export const ContainerFlex = styled.div``;

export const ButtonCleanMap = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  background-color: #ffffff00;
  bottom: 80px;
  right: 50px;
  z-index: 10;
`;

export const ButtonConfirmDrawing = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  bottom: 58.5px;
  right: 100px;
  background-color: #ffffff00;
  cursor: pointer;
  & > img {
    width: 100%;
    height: 100%;
  }
`;
