import styled from 'styled-components';

export const Title = styled.h6`
  font-weight: bold;
  font-size: 34px;
  text-align: left;
  color: #303D31;
  margin-left: 0px;
  margin-top: 40px;
  margin-bottom: 50px;

  @media (max-width: 375px) {
    font-size: 26px;
  }
`;

export const FormCulture = styled.div`
  width: 714px;
  height: auto;
  margin-top: 20px;
  font-size: 16px;
  background-color: #ffffff00;
`;

export const ContainerCulture = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 0px;
  padding: 34px;
  border-radius: 3%;
  border-width: 1px;
  border-style: solid;
  border-color: #CED4DA;
  background-color: #fff;
  z-index: 2;
`;

export const ButtonsField = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > h6 {
    width: 277px;
    height: 16px;
    margin-top: 11px;
    font-size: 14px;
    font-weight: normal;
  }

  @media (max-width: 425px) {
    display: block;
  }
`;

export const ButtonCancel = styled.button`
  width: 138px;
  height: 38px;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #6C757D;
  background-color: #ffffff00;
  border: 1px solid #fff;
  border-radius: 4px;

  @media (max-width: 375px) {
    width: 120px;
  }
  @media (max-width: 320px) {
    width: 100px;
  }
`;

export const ButtonNext = styled(ButtonCancel)`
  margin-right: 0px;
  color: #fff;
  background-color: #578687;
  border-color: #578687;
  text-align: center;
  :disabled {
    opacity: 0.4;
  }

  @media (max-width: 425px) {
    margin-left: 0px;
  }
`;
