import {
  getFieldById,
  getFieldByFarmId,
  postField,
  putField,
  sendDeleteField,
  sendInactiveField,
  getAccumulatedPrecipitation,
  getDailyPrecipitationForecast,
  getMonthlyPrecipitationForecast,
  getMonthlyPrecipitation,
  getClimatePrecipitation,
  getTemperatureClimatology,
  getMonthlyTemperature,
  getDailyTemperatureForecast,
  getMonthlyTemperatureForecast,
  getHydricBalance,
  getNdvi,
  getPulverization,
  getGlmImages,
  getRrqpefImages,
  getFireRiskImages,
  getDiseases,
  getFocusFireImages,
} from '../../services/contexts/field';

export const ActionTypes = {
  GET_FIELDS: '@field/GET_FIELDS',
};

export const fetchFieldById = (id) => async () => {
  const result = await getFieldById(id);
  return result;
};

export const fetchFields = (farmId, isActive) => async () => {
  const result = await getFieldByFarmId(farmId, isActive);
  return result;
};

export const addField = (field) => async (dispatch) => {
  const result = await postField(field);
  fetchFields(field.farmId)(dispatch);
  return result;
};

export const updateField = (farmId, field) => async (dispatch) => {
  const result = await putField(farmId, field);
  fetchFields(farmId)(dispatch);
  return result;
};

export const deleteField = (fieldId) => async () => {
  const result = await sendDeleteField(fieldId);
  return result;
};

export const inactiveField = (fieldId) => async () => {
  const result = await sendInactiveField(fieldId);
  return result;
};

export const fetchClimatePrecipitation = (fieldId) => async () => {
  const result = await getClimatePrecipitation(fieldId);
  return result;
};

export const fetchMonthlyPrecipitation = (fieldId) => async () => {
  const result = await getMonthlyPrecipitation(fieldId);
  return result;
};

export const fetchClimateTemperature = (fieldId) => async () => {
  const result = await getTemperatureClimatology(fieldId);
  return result;
};

export const fetchMonthlyTemperature = (fieldId) => async () => {
  const result = await getMonthlyTemperature(fieldId);
  return result;
};

export const fetchAccumulatedPrecipitation = (fieldId) => async () => {
  const result = await getAccumulatedPrecipitation(fieldId);
  return result;
};

export const fetchDailyPrecipitationForecast = (fieldId) => async () => {
  const result = await getDailyPrecipitationForecast(fieldId);
  return result;
};

export const fetchMonthlyPrecipitationForecast = (fieldId) => async () => {
  const result = await getMonthlyPrecipitationForecast(fieldId);
  return result;
};

export const fetchDailyTemperatureForecast = (fieldId) => async () => {
  const result = await getDailyTemperatureForecast(fieldId);
  return result;
};

export const fetchMonthlyTemperatureForecast = (fieldId) => async () => {
  const result = await getMonthlyTemperatureForecast(fieldId);
  return result;
};

export const fetchHydricBalance = (fieldId) => async () => {
  const result = await getHydricBalance(fieldId);
  return result;
};

export const fetchNdvi = (fieldId) => async () => {
  const result = await getNdvi(fieldId);
  return result;
};

export const fetchPulverization = (fieldId) => async () => {
  const result = await getPulverization(fieldId);
  return result;
};

export const fetchGlmImages = (fieldId) => async () => {
  const result = await getGlmImages(fieldId);
  return result;
};

export const fetchRrqpefImages = (fieldId) => async () => {
  const result = await getRrqpefImages(fieldId);
  return result;
};

export const fetchFireRiskImages = (fieldId) => async () => {
  const result = await getFireRiskImages(fieldId);
  return result;
};

export const fetchDiseases = (fieldId) => async () => {
  const result = await getDiseases(fieldId);
  return result;
};

export const fetchFocusFireImages = (fieldId) => async () => {
  const result = await getFocusFireImages(fieldId);
  return result;
};
