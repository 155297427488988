import styled, { css } from 'styled-components';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export const DefaultButton = styled(Button)`
  width: 100%;
  height: 38px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
`;

export const ButtonGreen = styled(DefaultButton)`
  background-color: #578687;
  color: #fff;
  border: 1px solid #578687;
`;

export const LinkButton = styled(Link)`
    ${({ theme }) => css`
      font-size: 1.2rem;
      color: ${theme.colors.grey};
      font-weight: bold;
      text-align: center;
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        text-decoration: underline;
      }
    }
  `} 
`;
