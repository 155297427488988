import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ContainerFlex } from './styles';

import {
  fetchFieldById,
  fetchAccumulatedPrecipitation,
  fetchClimatePrecipitation,
  fetchMonthlyPrecipitation,
  fetchHydricBalance,
  fetchGlmImages,
  fetchRrqpefImages,
} from '../../../../store/actions/field';

// assets
import Ground from '../../../../assets/images/ground.svg';
/* import Clay from '../../../../assets/images/clay.svg';
import Evaporation from '../../../../assets/images/evaporation.svg';
import Sand from '../../../../assets/images/sand.svg';
import Silt from '../../../../assets/images/silt.svg';
import Terrain from '../../../../assets/images/terrain.svg'; */

// components
import CardSoil from '../../../../components/cardSoil';

// util
import {
  soilTypeList,
  // humidityFieldCapacityUnit,
  // limitHumidityContentUnit,
} from '../../../../util/units';

const SoilCharacteristics = (props) => {
  const {
    soilType,
    /* sandContent,
    clayContent,
    soilDensity,
    humidityFieldCapacity,
    humidityFieldCapacityUnitEnum,
    limitHumidityContent,
    limitHumidityContentUnitEnum,
    soilWaterCapacity, */
  } = props;

  const getUnitType = (listParam, enumParam) => {
    let unit = '';
    listParam.map((type) => {
      if (enumParam && enumParam.toString() === type.id) {
        unit = type.name;
        console.log(enumParam);
        return unit;
      }
      console.log(unit);
      return unit;
    });
    return unit;
  };

  const soilTypes = [
    {
      img: Ground,
      type: 'Tipo de Solo',
      typeValue: getUnitType(soilTypeList, soilType),
    },
    /*
    {
      img: Sand,
      type: 'Teor de Areia',
      typeValue: `${!sandContent ? ' - ' : parseInt(sandContent, 10)} %`,
    },
    {
      img: Silt,
      type: 'Teor de Silite',
      typeValue: `${!clayContent ? ' - ' : parseInt(clayContent, 10)} %`,
    },
    {
      img: Clay,
      type: 'Teor de Argila',
      typeValue: `${!clayContent ? ' - ' : parseInt(clayContent, 10)} %`,
    },
    {
      img: Ground,
      type: 'Densidade do Solo',
      typeValue: `${!soilDensity ? ' - ' : parseInt(soilDensity, 10)} Mg m3`,
    },
    {
      img: Ground,
      type: 'Um. em capac. de campo',
      typeValue: `${!humidityFieldCapacity ? ' - ' : parseInt(humidityFieldCapacity, 10)} ${getUnitType(humidityFieldCapacityUnit, humidityFieldCapacityUnitEnum)}`,
    },
    {
      img: Terrain,
      type: 'Um. em ponto de murcha Perm.',
      typeValue: `${!limitHumidityContent ? ' - ' : parseInt(limitHumidityContent, 10)} ${getUnitType(limitHumidityContentUnit, limitHumidityContentUnitEnum)}`,
    },
    {
      img: Evaporation,
      type: 'CAD',
      typeValue: `${!soilWaterCapacity ? ' - ' : parseInt(soilWaterCapacity, 10)} mm`,
    }, */
  ];

  return (
    <ContainerFlex>
      {soilTypes.map((item) => (
        <CardSoil
          key={item.type}
          iconImg={item.img}
          typeText={item.type}
          typeRes={item.typeValue}
        />
      ))}
    </ContainerFlex>
  );
};

SoilCharacteristics.propTypes = {
  soilType: PropTypes.number,
  /* sandContent: PropTypes.string,
  clayContent: PropTypes.string,
  soilDensity: PropTypes.string,
  humidityFieldCapacity: PropTypes.string,
  humidityFieldCapacityUnitEnum: PropTypes.number,
  limitHumidityContent: PropTypes.string,
  limitHumidityContentUnitEnum: PropTypes.number,
  soilWaterCapacity: PropTypes.string, */
};

SoilCharacteristics.defaultProps = {
  soilType: null,
  /* sandContent: null,
  clayContent: null,
  soilDensity: null,
  humidityFieldCapacity: null,
  humidityFieldCapacityUnitEnum: null,
  limitHumidityContent: null,
  limitHumidityContentUnitEnum: null,
  soilWaterCapacity: null, */
};

const mapDispatchToProps = {
  onFetchFieldById: fetchFieldById,
  onFetchAccumulatedPrecipitation: fetchAccumulatedPrecipitation,
  onFetchClimatePrecipitation: fetchClimatePrecipitation,
  onFetchMonthlyPrecipitation: fetchMonthlyPrecipitation,
  onFetchHydricBalance: fetchHydricBalance,
  onFetchGlmImages: fetchGlmImages,
  onFetchRrqpefImages: fetchRrqpefImages,
};

export default connect(null, mapDispatchToProps)(SoilCharacteristics);
