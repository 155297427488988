import axios from 'axios';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import {
  getAuthData,
  getRefreshToken,
  saveData,
  deleteData,
} from '../../storage';

let isRefreshing = false;
let failedRequestQueue = [];

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: process.env.REACT_APP_TIMEOUT,
});

const signOut = () => {
  deleteData();
};

const refreshToken = async () => {
  isRefreshing = true;
  const token = getRefreshToken();

  const refreshAxiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.REACT_APP_TIMEOUT,
  });

  return refreshAxiosClient.put('/auth/refresh', { token })
    .then(async (res) => res)
    .catch(() => {
      signOut();
    }).finally(() => {
      isRefreshing = false;
    });
};

api.interceptors.request.use(async (config) => {
  const configuration = config;

  let token = getRefreshToken();
  if (!token) {
    return configuration;
  }

  const { exp } = jwtDecode(token);
  const expirationDate = new Date(exp * 1000);

  if (expirationDate < new Date() && !isRefreshing) {
    const { data } = await refreshToken();
    const authData = getAuthData();
    saveData(
      {
        ...authData,
        token: data.token,
      },
    );
  }

  token = getRefreshToken();

  configuration.headers.Authorization = `Bearer ${token}`;
  configuration.headers['Content-Type'] = 'application/json';
  return configuration;
}, (err) => Promise.reject(err));

api.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (err.response.status === 401) {
      const refrehToken = getRefreshToken();
      const originalConfig = err.config;
      if (!isRefreshing) {
        isRefreshing = true;
        api
          .post('/auth/refresh', { refrehToken })
          .then((response) => {
            const { data } = response;
            const { token } = data;
            api.defaults.headers.Authorization = `Bearer ${token}`;
            failedRequestQueue.forEach((request) => request.onSuccess(token));
            failedRequestQueue = [];
          })
          .catch((error) => {
            failedRequestQueue.forEach((request) => request.onFailure(error));
            failedRequestQueue = [];
            signOut();
          })
          .finally(() => {
            isRefreshing = false;
          });
      }
      return new Promise((resolve, reject) => {
        failedRequestQueue.push({
          onSuccess: (token) => {
            originalConfig.headers.Authorization = `Bearer ${token}`;
            resolve(api(originalConfig));
          },
          onFailure: (error) => {
            reject(error);
          },
        });
      });
    }

    if (err.response && err.response.status !== 500) {
      if (typeof err.response.data.message === 'string') {
        return Promise.reject(err);
      }
      const errors = Object.values(err.response.data.message);
      if (errors.length > 0) {
        return errors.map((errorMsg) => console.log(Array.isArray(errorMsg) ? errorMsg[0] : errorMsg));
      }
      return toast.error('Falha na requisição');
    }

    return Promise.reject(err);
  },
);

export default api;
