import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 7.5rem;
  margin-bottom: 1rem;
`;

export const Title = styled.h2`
  margin-top: 1.5rem;
  text-align: left;
  font-weight: bold;
  font-size: 2.25rem;
  color: #303D31;
`;

export const ContainerGrid = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: 0.5rem;
    background-color: ${theme.colors.white};
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 0px;
  `}
`;

export const Bar = styled.div`
  ${({ color }) => css`
    background-color: ${color};
    width: 100%;
    height: 3rem;
  `}
`;

export const Label = styled.label`
  font-size: 1.4rem;
  text-align: center;
  margin-top: 0.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
`;

export const Legend = styled.div`
  display: flex;
`;

export const LegendGreen = styled.div`
  width: 10rem;
  height: 1.8rem;
  border-radius: 4px;
  margin-right: 1.25rem;
  background-image: linear-gradient(to right, #348312 20%, #169916, #17C118, #79DF00, #A9E53D, #C1FF66);
`;

export const LegendYellow = styled.div`
  width: 10rem;
  height: 1.8rem;
  border-radius: 4px;
  background-image: linear-gradient(to right, #FDED00 30%, #EFC600);
  margin-right: 1.25rem;
`;

export const LegendRed = styled.div`
  width: 10rem;
  height: 1.8rem;
  border-radius: 4px;
  margin-right: 1.25rem;
  background-image: linear-gradient(to right, #FFA726 10%, #FB8C00, #FF3800, #FF0000, #D80300, #B21613, #8C1C10);
`;

export const TextLengend = styled.span`
  margin-left: 1.25;
  font-size: 1.45rem;
  font-family: 'Roboto', sans-serif;
  color: #646464;
  font-weight: 400;
`;
