import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h2`
  margin-bottom: 1.5rem;
  text-align: left;
  font-weight: bold;
  font-size: 2.25rem;
  color: #303D31;
`;
