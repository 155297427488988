import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Form from './content';
import { updateField } from '../../../../store/actions/field';
import { defaultDateFormatOrDefaultDate } from '../../../../helpers/date-helper';

import {
  Title,
  FormCulture,
  ContainerCulture,
} from './styles';

const EditCulture = (props) => {
  const { onUpdateField } = props;

  const handleSaveField = async (values) => {
    const field = {
      category: values.category,
      subCategoryId: values.subCategoryId,
      sowingPlantingDate: defaultDateFormatOrDefaultDate(values.sowingPlantingDate),
      productionCycle: values.productionCycle,
      productionCycleUnit: values.productionCycleUnit,
    };
    const result = await onUpdateField(field);
    if (result) {
      toast.success('Talhão editado com sucesso');
      return;
    }
    toast.error('Falha ao editar talhão');
  };

  return (
    <FormCulture>
      <Title>Editar Talhão</Title>
      <ContainerCulture>
        <Form handleSaveField={handleSaveField} />
      </ContainerCulture>
    </FormCulture>
  );
};

EditCulture.propTypes = {
  onUpdateField: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onUpdateField: updateField,
};

export default connect(null, mapDispatchToProps)(EditCulture);
