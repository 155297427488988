import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const ChartLegend = ({ items }) => (
  <S.ContainerLegend>
    {items.map((item) => (
      <S.Legend key={item.label}>
        <S.ColorLegend color={item.color} />
        <S.TextLengend>{item.label}</S.TextLengend>
      </S.Legend>
    ))}
  </S.ContainerLegend>
);

ChartLegend.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ChartLegend;
