import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  flex: 1;
`;

export const InfosContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const LegendContainer = styled.div`
  flex-direction: column;
  flex: 1;
  padding-left: 2px;
  padding-right: 2px;
  justify-content: space-between;
`;

export const LegendColor = styled.div`
  background-color: ${(props) => props.color};
  height: 20px;
  border-radius: 5px;
  border: 1px solid #dee2e6;
`;

export const LegendTitle = styled.div`
  font-size: 1rem;
  text-align: center;
  margin-bottom: 5px;
  color: black;
  font-family: 'Roboto', sans-serif;
`;

export const Title = styled.div`
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1rem;
`;
