/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import BaseList from '../../../../../components/table';

import { Container } from './styles';
import { getLabelMonths } from '../../../../../helpers/getLabelMonths';

const TemperatureHistorytable = (props) => {
  const {
    standardDeviationTempMax,
    tempAverageMax,
    monthlyTempMax,
    standardDeviationTempMin,
    tempAverageMin,
    monthlyTempMin,
  } = props;

  const [data, setData] = useState();

  const getMonthColumn = (item) => {
    if (!item) return '';
    return item.charAt(0).toUpperCase() + item.slice(1);
  };

  useEffect(() => {
    const monthlyTempMaxData = monthlyTempMax.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y.toFixed(2);
      return obj;
    }, {});

    const tempAverageMaxData = tempAverageMax.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y.toFixed(2);
      return obj;
    }, {});

    const maxStandardDeviationTempMaxData = standardDeviationTempMax.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y.toFixed(2);
      return obj;
    }, {});

    const minStandardDeviationTempMaxData = standardDeviationTempMax.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y0.toFixed(2);
      return obj;
    }, {});

    const monthlyTempMinData = monthlyTempMin.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y.toFixed(2);
      return obj;
    }, {});

    const tempAverageMinData = tempAverageMin.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y.toFixed(2);
      return obj;
    }, {});

    const maxStandardDeviationTempMinData = standardDeviationTempMin.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y.toFixed(2);
      return obj;
    }, {});

    const minStandardDeviationTempMinData = standardDeviationTempMin.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y0.toFixed(2);
      return obj;
    }, {});

    monthlyTempMaxData.type = 'Temperatura máxima dos últimos 12 meses';
    tempAverageMaxData.type = 'Média mensal de temperatura máxima de 2001 a 2019';
    maxStandardDeviationTempMaxData.type = 'Desvio padrão máximo de temperatura máxima';
    minStandardDeviationTempMaxData.type = 'Desvio padrão mínimo de temperatura máxima';
    monthlyTempMinData.type = 'Temperatura mínima dos últimos 12 meses';
    tempAverageMinData.type = 'Média mensal de temperatura mínima de 2001 a 2019';
    maxStandardDeviationTempMinData.type = 'Desvio padrão máximo de temperatura mínima';
    minStandardDeviationTempMinData.type = 'Desvio padrão mínimo de temperatura mínima';

    setData([
      tempAverageMaxData,
      maxStandardDeviationTempMaxData,
      minStandardDeviationTempMaxData,
      monthlyTempMaxData,
      tempAverageMinData,
      maxStandardDeviationTempMinData,
      minStandardDeviationTempMinData,
      monthlyTempMinData,
    ]);
  }, [
    standardDeviationTempMax,
    tempAverageMax,
    monthlyTempMax,
    standardDeviationTempMin,
    tempAverageMin,
    monthlyTempMin,
  ]);

  const months = getLabelMonths();
  const columns = useMemo(() => {
    const header = [{ Header: 'Tipo', accessor: 'type' }];
    months.forEach((p) => header.push(({ Header: getMonthColumn(p), accessor: p.replace('.', '') })));
    return header;
  }, []);

  if (data == null) return null;

  return (
    <Container>
      <BaseList
        columns={columns}
        data={data}
        title="Clique aqui para ver os dados de temperatura"
      />
    </Container>
  );
};

TemperatureHistorytable.propTypes = {
  standardDeviationTempMax: PropTypes.array.isRequired,
  tempAverageMax: PropTypes.array.isRequired,
  monthlyTempMax: PropTypes.array.isRequired,
  standardDeviationTempMin: PropTypes.array.isRequired,
  tempAverageMin: PropTypes.array.isRequired,
  monthlyTempMin: PropTypes.array.isRequired,
};

export default TemperatureHistorytable;
