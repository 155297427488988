import styled from 'styled-components';
import { Dropdown } from 'antd';
import { Card, Row, Col } from 'react-bootstrap';

export const Container = styled(Col)`
  align-items: stretch;
  width: 100rem;
`;

export const CardContainer = styled(Card)`
  width: 100%;
  margin-bottom: 1rem;
  position: relative;

  &.card {
    border: 1px solid #ECEFEA;
  }
  .card-title { 
    font-size: 1.125rem;
    color: #314B4D;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  & > img {
    width: 100%;
    height: 116px;
    margin-top: 0px;
    margin-left: 0px;
    z-index: 1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    object-fit: cover;
    cursor: pointer;
  }

  & > h6 {
    margin-left: 25px;
  }

  &:hover {
    box-shadow: 3px 0px 20px #ECEFEA;
    text-decoration: none;
  }

`;

export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerDropdown = styled(Dropdown)`
  background-color: none;
  border-radius: 4px;
  margin-right: 0px;
  cursor: pointer;
`;

export const Image = styled.img`
  height: 30px;
  width: 30px;
`;

export const LegendName = styled.h6`
  margin-top: 10px;
  margin-left: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #578687;
  justify-content: center;
`;

export const OptionsField = styled.div`
  & > img {
    width: 38px;
    height: 38px;
    top: 10px;
    right: 2px;
    z-index: 2;
    position: absolute;
    cursor: pointer;
  }
`;

export const DeleteField = styled.div`
  display: flex;
  width: 120px;
  height: 32px;
  padding: 6px;
  top: 45px;
  right: 15px;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 4px;
  & > img {
    width: 13px;
    height: 16px;
    margin-top: 2px;
  }
  & > label {
    font-size: 14px;
    cursor: pointer;
  }
`;

export const InformationRow = styled(Row)`
  margin-bottom: 8px;
`;

export const InformationContainer = styled(Col)`

  display: flex;
  flex-direction: column;
  align-items: center;

  & > h6 {
    margin-top: 10px;
    margin-left: 0px;
    font-size: 14px;
    font-weight: bold;
    color: #6E7F6F;
  }

  & > img {
    width: 33px;
    height: 33px;
  }
`;

export const ForecastContainer = styled(InformationContainer)`
  & > img {
    width: 45px;
    height: 45px;
    padding: 5px;
    border-radius: 10px;
    background-color: ${({ type }) => (type === 'prec' ? '#EAF5FF' : '#F7F7F7')};
  }
`;

export const HarvestDate = styled.h6``;

export const MapWrapper = styled.div``;
