/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useState } from 'react';
import { GoogleMap, useJsApiLoader, DrawingManager } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import Html2Canvas from 'html2canvas';
import { getAreaOfPolygon } from 'geolib';
import { toast } from 'react-toastify';

import Close from '../../../../assets/images/close.svg';
import ConfirmDrawing from '../../../../assets/images/checked-success.svg';
import BackArrow from '../../../../components/backArrow';
import { getPolygonCenterPoint } from '../../../../helpers/calcs';
import { zoom, polygonDrawingOptions } from '../../../../helpers/map-options';

import {
  Container,
  GoBackBtn,
  ContainerFlex,
  ButtonCleanMap,
  ButtonConfirmDrawing,
} from './styles';

const containerStyle = {
  width: '100%',
  height: '87vh',
};

const drawOptions = {
  drawingMode: 'polygon',
  drawingControl: true,
  drawingControlOptions: {
    position: 7,
    drawingModes: ['polygon'],
  },
  polygonOptions: polygonDrawingOptions,
};

const MapContainer = (props) => {
  const {
    showMap,
    setShowMap,
    setFieldValue,
    values,
    farmLocation,
  } = props;

  const [map, setMap] = useState(null);
  const [latestPolygon, setLatestPolygon] = useState([]);
  const [isDrawing, setIsDrawing] = useState(true);
  const [coordinates, setCoordinates] = useState(values.coordinates);

  const handlerShowMap = () => {
    setShowMap(!showMap);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAIE3ui79sHuPTT7rdSTm7dJL2Slxp8bps',
    libraries: 'drawing',
  });

  const onLoad = useCallback((loadMap) => {
    setMap(loadMap);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onPolygonComplete = (polygon) => {
    setLatestPolygon(polygon);
    const coordinatesPolygon = polygon.getPath().getArray();
    setIsDrawing(false);
    setCoordinates(coordinatesPolygon.map((coordinate) => ({
      latitude: coordinate.lat(),
      longitude: coordinate.lng(),
    })));
  };

  useEffect(() => {
    if (coordinates.length > 0) {
      setFieldValue('coordinates', coordinates);
    }
  }, [coordinates]);

  const printMap = () => {
    const squareMeter = getAreaOfPolygon(coordinates);
    if ((squareMeter / 10000) > 100000) {
      toast.error('O Talhão não pode ser maior que 100 mil hectares');
      return;
    }
    const centerPoint = getPolygonCenterPoint(coordinates);
    if (map) {
      map.panTo({ lat: centerPoint.latitude, lng: centerPoint.longitude });
    }
    Html2Canvas(document.querySelector('#map'), {
      useCORS: true,
      width: 520,
      height: 520,
    }).then((canvas) => {
      const image = canvas.toDataURL('image/jpeg', 0.7);
      setFieldValue('mapImage', image);
      handlerShowMap();
    });
  };

  const cleanMap = () => {
    latestPolygon.setMap(null);
    setCoordinates([]);
  };

  return isLoaded ? (
    <Container>
      <GoBackBtn onClick={handlerShowMap} className="goback">
        <BackArrow />
      </GoBackBtn>
      <GoogleMap
        id="map"
        mapContainerStyle={containerStyle}
        center={farmLocation}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          mapTypeId: 'hybrid',
          zoomControlOptions: { position: 6 },
          fullscreenControl: false,
        }}
      >
        <DrawingManager
          name="coordinates"
          onPolygonComplete={onPolygonComplete}
          options={drawOptions}
        />
      </GoogleMap>
      <ButtonCleanMap type="button" onClick={cleanMap} className="clean-map">
        <img src={Close} alt="Limpar" />
      </ButtonCleanMap>
      <ContainerFlex data-html2canvas-ignore>
        {
          (coordinates.length > 0 && !isDrawing)
          && [
            <ButtonConfirmDrawing type="button" onClick={printMap}>
              <img src={ConfirmDrawing} alt="Confirmar" />
            </ButtonConfirmDrawing>,
          ]
        }
      </ContainerFlex>
    </Container>
  ) : null;
};

MapContainer.propTypes = {
  showMap: PropTypes.bool.isRequired,
  setShowMap: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  farmLocation: PropTypes.object.isRequired,
};

export default MapContainer;
