import styled, { css } from 'styled-components';
import { Col as BCol, Row as BRow } from 'react-bootstrap';
import { Layout } from 'antd';

export const Container = styled(Layout)`
  height: 100vh;
`;

export const Sidebar = styled(Layout.Sider)`
  && {
    ${({ theme }) => css`
      & .ant-layout-sider-children {
        background: ${theme.colors.white};
        border-right: 1px solid ${theme.colors.lightGrey};
        overflow-y: auto;
      }
      & .ant-layout-sider {
        background: ${theme.colors.white};
      }

      & .ant-layout-sider::after {
        color: ${theme.colors.mediumGrey};
      }

      & .ant-menu.ant-menu-dark .ant-menu-dark .ant-menu-sub,
      .ant-menu.ant-menu-dark .ant-menu-sub {
        background: ${theme.colors.white};
      }

      & .ant-menu-item {
        color: ${theme.colors.mediumGrey};
      }

      & .ant-menu-item svg,
      i {
        color: ${theme.colors.iconGrey};
      }

      /* & .ant-menu-item-selected {
        color: ${theme.colors.suzanoGreen};
        background-color: ${theme.colors.white};
      }

      & .ant-menu-item-active {
        color: ${theme.colors.suzanoGreen};
      }

      & .ant-menu-item-selected::after {
        color: ${theme.colors.suzanoGreen};
        background-color: ${theme.colors.white};
      }

      & .ant-menu-item-selected svg {
        color: ${theme.colors.suzanoGreen};
        background-color: ${theme.colors.white};
      }

      & .ant-menu-item-selected i {
        color: ${theme.colors.suzanoGreen};
        background-color: ${theme.colors.white};
      }

      & .ant-menu-inline .ant-menu-item::after {
        border-right: none;
        color: ${theme.colors.suzanoGreen};
      } */
    `}
  }
`;

export const Col = styled(BCol)`
  &.col-3 {
    padding: 0;
  }
`;

export const MainContainer = styled(Layout.Content)`
  background-color: #FAFAFA;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  & .ant-layout-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const CurrentPageContainer = styled.div`
  max-height: 100vh;
  margin-top: 5.2rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Row = styled(BRow)`
  &.row {
    height: 100vh;
  }
`;

export const Wrapper = styled(Layout)``;
