import React, { useEffect, useCallback, useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  GroundOverlay,
  Polygon,
  Marker,
} from '@react-google-maps/api';
import { FaRegStopCircle, FaRegPlayCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import _ from 'lodash';

import MapLegend from './map-legend';
import { formatDateFromISO, formatDateFromISODateImage } from '../../../../helpers/date-helper';
import { mapCenter, polygonOptions } from '../../../../helpers/map-options';

import {
  Container,
  Title,
  ImageDate,
  ContainerIcon,
  ContainerLegend,
} from './styles';
import Divider from '../../../../components/divider';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const StaticMap = (props) => {
  const {
    centerPoint,
    fieldId,
    title,
    onLoadMapImages,
    legendInfos,
    isOneImage,
    showTime,
  } = props;

  const [zoom] = useState(8);
  const [, setMap] = useState(null);
  const [center, setCenter] = useState({});
  const [image, setImage] = useState({});
  const [bounds, setBounds] = useState(null);
  const [images, setImages] = useState([]);
  const [polygon, setPolygon] = useState([]);
  const [position, setPosition] = useState();
  const [isStopped, setIsStopped] = useState(false);
  const [count, setCount] = useState(0);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAIE3ui79sHuPTT7rdSTm7dJL2Slxp8bps',
    libraries: 'drawing',
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setPosition({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      });
    });
  }, [centerPoint]);

  useEffect(() => {
    if (centerPoint && !_.isEmpty(centerPoint)) {
      setCenter({ lat: centerPoint.coordinates[1], lng: centerPoint.coordinates[0] });
    } else {
      setCenter(mapCenter);
    }
  }, [centerPoint]);

  const onLoad = useCallback((loadMap) => {
    setMap(loadMap);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const loadImage = async () => {
    const data = await onLoadMapImages(fieldId);
    const imagesLoop = [];
    console.log({ data });
    if (data && data.contents && data.contents.length > 0) {
      data.contents.forEach((content) => {
        const { path, date } = content;
        const imageDateFormatted = formatDateFromISODateImage(date);
        const img = `https://weatherservice-satellite-images.s3.sa-east-1.amazonaws.com/${path}`;
        imagesLoop.push({
          img,
          date: imageDateFormatted,
        });
      });
      setImages(imagesLoop);
    }
    if (data && data.contents && isOneImage) {
      const { path, date } = data.contents;
      const imageDateFormatted = showTime ? formatDateFromISODateImage(date) : formatDateFromISO(date);
      const img = `https://weatherservice-satellite-images.s3.sa-east-1.amazonaws.com/${path}`;
      setImages({
        img,
        date: imageDateFormatted,
      });
    }
    if (data && data.fieldBoundaries.coordinates.length > 0 && data.fieldBoundaries.coordinates[0].length > 2) {
      setBounds({
        north: data.fieldBoundaries.coordinates[0][1][0],
        south: data.fieldBoundaries.coordinates[0][3][0],
        east: data.fieldBoundaries.coordinates[0][3][1],
        west: data.fieldBoundaries.coordinates[0][1][1],
      });
      const coordinates = data.fieldBoundaries.coordinates[0];
      const coordinatesPolygon = [
        {
          lat: coordinates[0][0],
          lng: coordinates[0][1],
        },
        {
          lat: coordinates[1][0],
          lng: coordinates[1][1],
        },
        {
          lat: coordinates[2][0],
          lng: coordinates[2][1],
        },
        {
          lat: coordinates[3][0],
          lng: coordinates[3][1],
        },
      ];
      setPolygon(coordinatesPolygon);
    }
  };

  useEffect(() => {
    loadImage();
  }, [fieldId, onLoadMapImages]);

  useEffect(() => {
    if (!isOneImage) {
      const interval = setInterval(() => {
        if (count < images.length && !isStopped) {
          const newImage = images[count];
          setImage(newImage);
        }
        if (count < images.length - 1 && !isStopped) {
          setCount(count + 1);
        } else if (!isStopped) {
          setCount(0);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
    return setImage(images);
  }, [images, isStopped, count]);

  return isLoaded ? (
    <Container>
      <Title>{title}</Title>
      <Divider />
      {
        (!image || !images)
        && (<ImageDate>Sem informação de satélite</ImageDate>)
      }
      {
        image && image.date
        && (
          <>
            <ImageDate>{image.date}</ImageDate>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                gestureHandling: 'none',
                disableDefaultUI: true,
                mapTypeId: 'hybrid',
                zoomControl: true,
                zoomControlOptions: { position: 6 },
                maxZoom: 13,
                minZoom: 8,
              }}
            >
              <Marker
                position={position}
              />
              {bounds !== null && image && image.img
                && (
                  <GroundOverlay
                    key={image.date}
                    url={image.img}
                    bounds={bounds}
                    opacity={0.8}
                  />
                )}
              {
                polygon.length > 0 && (
                  <Polygon
                    paths={polygon}
                    options={polygonOptions}
                  />
                )
              }
            </GoogleMap>
            {!isOneImage && (
              <ContainerIcon onClick={() => setIsStopped(!isStopped)}>
                {!isStopped ? (<FaRegStopCircle size={28} color="#666666" />)
                  : (<FaRegPlayCircle size={28} color="#666666" />)}
              </ContainerIcon>
            )}
            <ContainerLegend>
              <MapLegend
                title={title}
                legendInfos={legendInfos}
              />
            </ContainerLegend>
          </>
        )
      }
    </Container>
  ) : null;
};

StaticMap.defaultProps = {
  centerPoint: null,
  isOneImage: false,
  showTime: false,
};

StaticMap.propTypes = {
  centerPoint: PropTypes.object,
  fieldId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onLoadMapImages: PropTypes.func.isRequired,
  legendInfos: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  })).isRequired,
  isOneImage: PropTypes.bool,
  showTime: PropTypes.bool,
};

export default StaticMap;
