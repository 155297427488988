import {
  authUser, me, logout, recoverPassword, validateCode, newPassword,
} from '../../services/contexts/user';

export const ActionTypes = {
  DO_LOGIN: '@user/DO_LOGIN',
  DO_LOGOUT: '@user/DO_LOGOUT',
  SET_USER_ID: '@user/SET_USER_ID',
};

export const updateUserInfo = () => async (dispatch) => {
  try {
    const response = await me();

    if (!response) {
      dispatch({ type: ActionTypes.DO_LOGOUT });
      return null;
    }

    const { data } = response;

    dispatch({
      type: ActionTypes.DO_LOGIN,
      payload: {
        user: data,
      },
    });
    return data;
  } catch (err) {
    dispatch({ type: ActionTypes.DO_LOGOUT });
    return null;
  }
};

export const doLogin = (auth) => async (dispatch) => {
  const result = await authUser(auth);

  if (!result) {
    dispatch({ type: ActionTypes.DO_LOGOUT });
    return null;
  }

  const { user } = result;

  dispatch({
    type: ActionTypes.DO_LOGIN,
    payload: {
      user,
    },
  });
  return result;
};

export const doLogout = () => async (dispatch) => {
  logout();
  dispatch({ type: ActionTypes.DO_LOGOUT });
};

export const resetPassword = (email) => async () => {
  const result = await recoverPassword(email);
  return result;
};

export const codeValidation = (code) => async (dispatch) => {
  const result = await validateCode(code);
  dispatch({
    type: ActionTypes.SET_USER_ID,
    payload: {
      id: result.data.userId,
    },
  });
  return result;
};

export const updatePassword = (userId, password) => async () => {
  const result = await newPassword(userId, password);
  return result;
};
