import styled from 'styled-components';

export const ContainerLegend = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 2rem;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ColorLegend = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 4px;
  border: 1px solid ${(props) => props.color};
  background-color: ${(props) => props.color};
  margin-right: 1.25rem;
`;

export const TextLengend = styled.span`
  margin-left: 1.25;
  font-size: 1.45rem;
  font-family: 'Roboto', sans-serif;
  color: #646464;
  font-weight: 400;
`;
