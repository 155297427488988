import styled, { css } from 'styled-components';

export const StepContainer = styled.div`
  ${({ theme, completed }) => css`
        display: flex;
        cursor: pointer;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 6px solid ${(completed
    ? theme.colors.green
    : theme.colors.inactiveGrey)};
        width: 30%;
  `}
`;

export const Label = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        span {
            color: ${theme.colors.green};
            font-size: 1.2rem;
        }
        svg {
            color: ${theme.colors.green};
            margin-left: 1.3rem;
        }
  `}
`;
