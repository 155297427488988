import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { updateUserInfo } from '../../store/actions/user';

const AuthGate = (props) => {
  const { isAuthenticated, onUpdateUserInfo, history } = props;

  useEffect(() => {
    async function UpdateUser() {
      await onUpdateUserInfo();
    }
    UpdateUser();
  }, []);

  if (!isAuthenticated) {
    history.push('/');
  }
  return (<></>);
};

AuthGate.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  onUpdateUserInfo: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  onUpdateUserInfo: updateUserInfo,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AuthGate);
