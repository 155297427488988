import styled from 'styled-components';

export const ButtonClose = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  margin-top: 8px;
  right: 50px;
  background-color: #ffffff00;
`;

export const ImageMap = styled.div`
  width: 520px;
  height: 520px;
  margin-top: 50px;
  margin-right: 0px;
  margin-left: 0px;
  z-index: 4;
  & > img {
    width: 100%;
    height: 90%;
    margin-top: 0px;
    object-fit: cover;
  }
`;
