import styled, { css } from 'styled-components';
import { Row } from 'react-bootstrap';

export const Container = styled.div`
  padding: 3rem 2rem 0 4.75rem;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > a {
    text-decoration: none;
  }
`;

export const ContainerInfo = styled.h6`
  color: #303D31;
  font-size: 12px;
  font-weight: bold;
  margin-top: 4rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h6`
  color: #303D31;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const ButtonAddField = styled.div`
  width: 202px;
  height: 38px;
  margin-right: 0px;
  padding: 6px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #578687;
  border: 1px solid #578687;
  border-radius: 4px;
  text-align: center;
  margin-left: auto;
  & > img {
    width: 16px;
    height: 16px;
    margin-top: -1px;
    margin-right: 10px;
  }
`;

export const ContainerList = styled(Row)``;

export const ZeroStateWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;

    h4 {
      font-size: 2.5rem;
      color: ${theme.colors.mediumGrey};
    }
  `} 
`;

export const ZeroStateImg = styled.img`
  width: 30%;
  margin-bottom: 2rem;
`;

export const ZeroStateMsg = styled.h4``;
