import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import {
  Form,
  FormGroup,
} from 'reactstrap';
// Components
import { ButtonGreen, LinkButton } from '../../../../components/styles/button';
import FormField from '../../../../components/forms/formField';
import AboutContainer from '../../../../components/aboutContainer';

import * as S from '../../styles';

const HaveCodeForm = (props) => {
  const {
    handleSubmit,
  } = props;

  return (
    <S.Layout>
      <S.Layout>
        <S.LeftContainer width={500}>
          <S.TitleRegister>Verifique seu e-mail</S.TitleRegister>
          <S.SubtitleRegister>Digite no campo abaixo o código que enviamos no seu e-mail</S.SubtitleRegister>
          <Form onSubmit={handleSubmit} noValidate>
            <FormGroup>
              <FormField
                id="code"
                name="code"
                label="Código"
                placeholder="Digite o código"
                isRequired
              />
            </FormGroup>
            <FormGroup>
              <S.ButtonsRegister>
                <ButtonGreen type="submit">Validar código</ButtonGreen>
                <LinkButton to="/reset-password" className="btn">VOLTAR</LinkButton>
              </S.ButtonsRegister>
            </FormGroup>
          </Form>
        </S.LeftContainer>
        <S.Content>
          <AboutContainer />
        </S.Content>
      </S.Layout>
    </S.Layout>
  );
};

HaveCodeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    code: '',
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handleCodeValidate(values),
})(HaveCodeForm);
