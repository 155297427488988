import React, { useState, useEffect } from 'react';

import { getFarmInfo } from '../../services/contexts/farm';

import {
  Container,
  Title,
  ContainerFlex,
  ContainerQuantity,
  InfoLabel,
  QuantityLabel,
} from './styles';

import { getUser } from '../../storage';

const Home = () => {
  const [userName, setUserName] = useState();
  const [totalFarmsState, setTotalFamsState] = useState(0);
  const [totalFieldsActiveState, setTotalFieldsActiveState] = useState(0);
  const [totalFieldsInactiveState, setTotalFieldsInactiveState] = useState(0);

  useEffect(() => {
    const fetchFarmInfo = async () => {
      const farmInfo = await getFarmInfo();
      const {
        totalFarms,
        totalFieldsActive,
        totalFieldsInactive,
      } = farmInfo || {};
      setTotalFamsState(totalFarms);
      setTotalFieldsActiveState(totalFieldsActive);
      setTotalFieldsInactiveState(totalFieldsInactive);
    };
    fetchFarmInfo();
    const user = getUser();
    setUserName(user && user.name);
  }, []);

  const cards = [
    {
      id: 1,
      itemState: totalFarmsState,
      label: 'Fazendas',
    },
    {
      id: 2,
      itemState: totalFieldsActiveState,
      label: 'Talhões Ativos',
    },
    {
      id: 3,
      itemState: totalFieldsInactiveState,
      label: 'Talhões Inativos',
    },
  ];

  return (
    <Container>
      <Title>
        {`Olá, ${userName} `}
      </Title>
      <ContainerFlex>
        {cards.map((item) => (
          <ContainerQuantity key={item.id}>
            <QuantityLabel>{item.itemState}</QuantityLabel>
            <InfoLabel>{item.label}</InfoLabel>
          </ContainerQuantity>
        ))}
      </ContainerFlex>
    </Container>
  );
};

export default Home;
