import api from '../config';
import { deleteData, saveData } from '../../storage';

export const saveAuthData = (values) => {
  const {
    user: {
      name,
      email,
    },
    token,
  } = values;

  saveData({
    name,
    email,
    token,
  });
};

export const register = async (user) => {
  try {
    const response = await api.post('/users', user);
    if (response.status === 201) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const me = async () => {
  try {
    const response = await api.get('/auth/me');
    if (response.status === 200) {
      return response;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const authUser = async (user) => {
  try {
    deleteData();
    const response = await api.post('/auth/login', user);
    if (response.status === 200) {
      const { data } = response.data;
      saveAuthData(data);
      return data;
    }
    deleteData();
    return null;
  } catch (err) {
    deleteData();
    return null;
  }
};

export const logout = async () => {
  deleteData();
};

export const recoverPassword = async (email) => {
  try {
    const response = await api.post('/users/recover-password', email);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const validateCode = async (code) => {
  try {
    const response = await api.post('/users/validate-code', code);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const newPassword = async (userId, password) => {
  try {
    const response = await api.post('/users/new-password', {
      userId,
      password,
    });
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};
