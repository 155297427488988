import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
} from 'reactstrap';

import {
  addDays,
  addMonths,
  addYears,
  minDate,
} from '../../../../helpers/date-helper';
import { CultureEnum } from '../../../../common/enum/cultureEnum';
import {
  TitleCulture,
  ContainerFlex,
  ContainerFlexFixed,
  PlantingDate,
  ProductionCycle,
  ContainerUnit,
  LegendDays,
  DateHarvest,
} from './styles';

import FormField from '../../../../components/forms/formField';

const OtherFieldsContent = (props) => {
  const {
    values,
    setFieldValue,
  } = props;

  const getHarvestDate = () => {
    if (!values.sowingPlantingDate || !values.productionCycle || !values.productionCycleUnit) {
      return '';
    }
    if (values.productionCycleUnit === 2) {
      return `Colheita: ${addMonths(values.sowingPlantingDate, values.productionCycle) || ''}`;
    }
    if (values.productionCycleUnit === 3) {
      return `Colheita: ${addYears(values.sowingPlantingDate, values.productionCycle) || ''}`;
    }
    return `Colheita: ${addDays(values.sowingPlantingDate, values.productionCycle) || ''}`;
  };

  return (
    <div>
      <TitleCulture>Preencha os campos abaixo</TitleCulture>
      <FormGroup>
        {
          [CultureEnum.GRAOS_FIBRAS, CultureEnum.HORTICOLAS]
            .includes(values.category) && (
            <>
              <ContainerFlex>
                <PlantingDate>
                  <FormField
                    id="sowingPlantingDate"
                    name="sowingPlantingDate"
                    label="Data de semeadura ou plantio"
                    placeholder="Selecionar data"
                    type="date"
                    min={minDate}
                    isRequired
                  />
                </PlantingDate>
                <ContainerFlexFixed>
                  <ProductionCycle onChange={(e) => {
                    setFieldValue('productionCycle', e.target.value);
                    setFieldValue('productionCycleUnit', 1);
                  }}
                  >
                    <FormField
                      id="productionCycle"
                      name="productionCycle"
                      label="Ciclo de Produção"
                      placeholder="Ex.: 20"
                      type="number"
                      min="1"
                      isRequired
                    />
                  </ProductionCycle>
                  <ContainerUnit>
                    <LegendDays>Dias</LegendDays>
                  </ContainerUnit>
                </ContainerFlexFixed>
              </ContainerFlex>
              <DateHarvest>{getHarvestDate()}</DateHarvest>
            </>
          )
        }

        {
          [
            CultureEnum.PERENES,
            CultureEnum.PASTO,
            CultureEnum.FLORESTA,
            CultureEnum.SEM_CULTURA,
          ].includes(values.category) && (
            <ContainerFlex>
              <ContainerFlexFixed>
                <ProductionCycle>
                  <FormField
                    id="productionCycle"
                    name="productionCycle"
                    label="Tempo de monitoramento"
                    placeholder="Ex.: 20"
                    onChange={(e) => {
                      setFieldValue('productionCycle', e.target.value);
                      setFieldValue('productionCycleUnit', 2);
                    }}
                    type="number"
                    min="1"
                    max="60"
                    isRequired
                  />
                </ProductionCycle>
                <ContainerUnit>
                  <LegendDays>Meses</LegendDays>
                </ContainerUnit>
              </ContainerFlexFixed>
            </ContainerFlex>
          )
        }
      </FormGroup>
    </div>
  );
};

OtherFieldsContent.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default OtherFieldsContent;
