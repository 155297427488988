import styled, { css } from 'styled-components';

import { Row, Col } from 'react-bootstrap';

export const ContainerCategories = styled(Row)`
  width: 108%;
`;

export const CategoryContainer = styled(Col)``;

export const CategoryButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${(props) => (props.active ? 1.0 : 0.3)};

  img { 
    height: 68px;
    width: 68px;
    background-color: #578687;
    border-radius: 8px;
    object-fit: contain;
    padding: 10px 8px;
  }

  h4 { 
    font-size: 14px;
    margin-top: 10px;
    color: #578687;
    font-weight: bold;
  }
`;

export const ContainerCulture = styled.div``;

export const Title = styled.h6`
  font-weight: bold;
  font-size: 34px;
  text-align: left;
  color: #303D31;
  margin-left: 0px;
  margin-top: 40px;
  margin-bottom: 50px;
`;

export const FormCulture = styled.div`
  width: 714px;
  height: auto;
  margin-top: 20px;
  font-size: 16px;
  background-color: #ffffff00;
`;

export const RegistrationSteps = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;
`;

export const StepOne = styled.div`
  width: 342px;
  & > hr {
    width: 100%;
    height: 10px;
    background-color: #A2C667;
    border-color: #A2C667;
    border-width: 0px;
    opacity: 1;
    margin-top: 10px;
  }
`;

export const NameStepOne = styled.div`
  display: flex;
  margin-bottom: 0px;
  & > h6 {
    width: 120px;
    font-size: 14px;
    font-weight: bold;
    color: #A2C667;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  & > img {
    width: 14px;
    height: 11px;
    margin-top: -14px;
    margin-left: 20px;
    margin-bottom: 0px;
  }
`;

export const StepTwo = styled.div`
  width: 342px;
  margin-left: 30px;
  margin-bottom: 0px;
  & > h6 {
    width: 120px;
    font-size: 14px;
    font-weight: bold;
    color: #A2C667;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  & > hr {
    width: 100%;
    height: 10px;
    background-color: #797B77;
    border-color: #797B77;
    border-width: 0px;
    opacity: 0.25;
    margin-top: 11px;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
`;

export const ContainerFlexFixed = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleCulture = styled.h6`
  ${({ theme }) => css`
    font-weight: bold;
    font-size: 1.5rem;
    text-align: left;
    color: ${theme.colors.primary};
    margin-top: 2rem;
    margin-bottom: 5rem;
    text-transform: uppercase;
  `} 
`;

export const PlantingDate = styled.div`
  width: 307px;
  margin-left: 0px;
  margin-right: 28px;
  & > h6 {
    font-size: 16px;
    font-weight: normal;
    color: #6C757D;
    margin-left: 0px;
    margin-top: 0px;
  }
`;

export const ProductionCycle = styled.div`
  width: 194px;
  margin-left: 0px;
  & > h6 {
    font-size: 16px;
    font-weight: normal;
    color: #6C757D;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const ContainerUnit = styled.div`
  width: 115px;
  margin-top: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const LegendDays = styled.h5`
  font-size: 24px;
  margin-top: 27px;
  margin-left: 15px;
  color: #578687;
  font-weight: bold;
`;

export const TitleSoil = styled.h6`
  color: #578687;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
`;

export const ButtonAdvancedOptions = styled.h6`
  width: 200px;
  height: 38px;
  margin-top: 40px;
  margin-left: 247px;
  font-size: 16px;
  font-weight: bold;
  background-color: #ffffff00;
  color: #314B4D;
`;

export const ArrowDown = styled.span`
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
    width: 0; 
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #314B4D;
  }
`;

export const ArrowUp = styled.span`
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
    width: 0; 
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #314B4D;
  }
`;

export const OptionsPercent = styled.div`
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 42rem;
`;

export const LegendLeft = styled.h5`
  font-size: 24px;
  margin-top: 35px;
  margin-left: 15px;
  margin-right: 32px;
  color: #578687;
  font-weight: bold;
`;

export const LegendRight = styled.h5`
  font-size: 24px;
  margin-top: 35px;
  margin-left: 15px;
  color: #578687;
  font-weight: bold;
`;

export const SoilDensity = styled.div`
  width: 196px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const ContentMoisture = styled.div`
  width: 100%;
  margin-left: 0px;
  margin-top: 28px;
  margin-left: 0px;
  display: flex;
`;

export const ContainerInput = styled.div`
  width: 525px;
  & > input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const ContainerDropdown = styled.div`
  width: 134px;
  margin-top: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const SelectType = styled.div`
  width: 119px;
  margin-left: -1px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const WaterStorageCapacity = styled.div`
  width: 590px;
  margin-left: 0px;
`;

export const LegendMillimeter = styled.h5`
  font-size: 24px;
  margin-top: 35px;
  margin-left: 15px;
  color: #578687;
  font-weight: bold;
`;

export const ButtonsField = styled.div`
  margin-top: 25px;
  display: flex;
  & > h6 {
    width: 242px;
    height: 16px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 11px;
    font-size: 14px;
    font-weight: normal;
  }
`;

export const ButtonReturn = styled.button`
  width: 138px;
  height: 38px;
  margin-left: 126px;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #6C757D;
  background-color: #ffffff00;
  border: 1px solid #fff;
  border-radius: 4px;
`;

export const ButtonNext = styled.button`
  width: 138px;
  height: 38px;
  margin-right: 0px;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #578687;
  border: 1px solid #578687;
  border-radius: 4px;
  text-align: center;
`;

export const DateHarvest = styled.h5`
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #707070;
  font-weight: normal;
`;
