import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DateTime } from 'luxon';

import Divider from '../../../../../components/divider';

import { getPrecInfo } from '../../../../../helpers/getPrecInfo';
import TemperatureIcon from '../../../../../assets/images/thermometer-high.svg';
import TemperatureSnowIcon from '../../../../../assets/images/thermometer-snow.svg';

import * as S from './styles';

const DailyForecast = (props) => {
  const {
    dailyData,
    name,
  } = props;

  const [data, setData] = useState([]);

  useEffect(() => {
    const items = [];

    dailyData.forEach((item) => {
      const { dateTime, value } = item;

      const date = DateTime.fromISO(dateTime).setZone('utc').toFormat('dd/MM');
      const weekday = DateTime.fromISO(dateTime, { locale: 'pt-br' }).setZone('utc').toLocaleString({ weekday: 'long' });

      if (name === 'Precipitação') {
        const { icon, label } = getPrecInfo(value);
        items.push({
          icon, weekday, date, label,
        });
      } else {
        const icon = item.risk ? TemperatureSnowIcon : TemperatureIcon;

        items.push({
          icon, risk: item.risk, weekday, date, labelMin: `${Math.round(item.min)} ºC`, labelMax: `${Math.round(item.max)} ºC`,
        });
      }

      return items;
    });

    setData(items);
  }, [dailyData]);

  return (
    <S.Container>
      <S.Title>Previsão para os próximos dias</S.Title>
      <S.Subtitle>{name}</S.Subtitle>
      <Divider />
      {data && data.map((item) => (
        <S.ContainerGrid key={item.weekday}>
          <S.Container>
            <S.ContainerIcon type={name}>
              <S.Icon src={item.icon} />
            </S.ContainerIcon>
            {item.risk && <S.InfoContainer>Risco de Geada</S.InfoContainer>}
          </S.Container>
          <S.ContainerLabel>
            <S.Subtitle>{item.weekday}</S.Subtitle>
            <S.LabelDate>{item.date}</S.LabelDate>
          </S.ContainerLabel>
          <S.ContainerLabel>
            {name === 'Temperatura'
              ? (
                <>
                  <S.Subtitle>Temperatura Prevista</S.Subtitle>
                  <S.ContainerFlex>
                    <S.Container>
                      <S.Label>Mínima</S.Label>
                      <S.LabelValue>{item.labelMin}</S.LabelValue>
                    </S.Container>
                    <S.Container>
                      <S.Label>Máxima</S.Label>
                      <S.LabelValue>{item.labelMax}</S.LabelValue>
                    </S.Container>
                  </S.ContainerFlex>
                </>
              ) : (
                <>
                  <S.Subtitle>Chuva Prevista</S.Subtitle>
                  <S.LabelDate>{item.label}</S.LabelDate>
                </>
              )}
          </S.ContainerLabel>
        </S.ContainerGrid>
      ))}
    </S.Container>
  );
};

DailyForecast.propTypes = {
  dailyData: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default DailyForecast;
