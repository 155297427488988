import styled from 'styled-components';
import { Col } from 'react-bootstrap';

const Container = styled(Col)`
  margin-top: 1.25rem;
`;

const CardGround = styled.div`
  width: 20.25rem;
  height: 7.75rem;
  text-align: left;
  border-radius: 10px;
  border: 1px solid #ECEFEA;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 0.875rem 1rem;
`;

const CardImage = styled.img`
    width: 2.188rem;
    height: 2.625rem;
    margin-right: 0.75rem;
    margin-top: 1rem;
`;

const Content = styled.div`
  margin-left: 1.2rem;
`;

const Type = styled.h6`
  margin-left: 0px;
  margin-top: 0.68vw;
  font-size: 0.975rem;
  font-weight: normal;
  color: #314B4D;
`;

const Name = styled.h6`
  margin-left: 0px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #314B4D;
`;

export {
  Container,
  CardGround,
  CardImage,
  Name,
  Type,
  Content,
};
