/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import {
  FormGroup,
  Collapse,
} from 'reactstrap';

import {
  TitleSoil,
  ContainerButton,
  ButtonAdvancedOptions,
  ContainerGrid,
  ContainerFlexFixed,
  ArrowDown,
  ArrowUp,
  OptionsPercent,
  LegendLeft,
  LegendRight,
  SoilDensity,
  ContentMoisture,
  WaterStorageCapacity,
  LegendMillimeter,
  ContainerInput,
  ContainerDropdown,
} from '../culture/styles';
// Common
import { soilTypeList, humidityFieldCapacityUnitList, limitHumidityContentUnitList } from '../../../../common/options/fieldOptions';

import Dropdown from '../../../../components/forms/dropdown';
import FormField from '../../../../components/forms/formField';

const SoilContent = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <TitleSoil>SOLO</TitleSoil>
      <FormGroup>
        <Dropdown
          name="soilType"
          label="Tipo de Solo"
          placeholder="Selecione um tipo"
          isRequired
          options={soilTypeList}
        />
      </FormGroup>
      {/* <ContainerButton>
      <ButtonAdvancedOptions
        onClick={() => setIsOpen(!isOpen)}
        style={{ marginBottom: '1rem' }}
      >
        Opções avançadas
        {isOpen === false
          && <ArrowDown />}
        {isOpen === true
          && <ArrowUp />}
      </ButtonAdvancedOptions>
      </ContainerButton> */}
      <Collapse isOpen={isOpen}>
        <FormGroup>
          <ContainerGrid>
            <ContainerFlexFixed>
              <OptionsPercent>
                <FormField
                  id="sandContent"
                  name="sandContent"
                  label="Teor de Areia"
                  type="text"
                />
              </OptionsPercent>
              <LegendLeft>%</LegendLeft>
            </ContainerFlexFixed>
            <ContainerFlexFixed>
              <OptionsPercent>
                <FormField
                  id="siltContent"
                  name="siltContent"
                  label="Teor de Silte"
                  type="text"
                />
              </OptionsPercent>
              <LegendRight>%</LegendRight>
            </ContainerFlexFixed>
          </ContainerGrid>
        </FormGroup>
        <FormGroup>
          <ContainerGrid>
            <ContainerFlexFixed>
              <OptionsPercent>
                <FormField
                  id="clayContent"
                  name="clayContent"
                  label="Teor de Argila"
                  type="text"
                />
              </OptionsPercent>
              <LegendLeft>%</LegendLeft>
            </ContainerFlexFixed>
            <ContainerFlexFixed>
              <SoilDensity>
                <FormField
                  id="soilDensity"
                  name="soilDensity"
                  label="Densidade do Solo"
                  type="text"
                />
              </SoilDensity>
              <LegendRight>Mg m-3</LegendRight>
            </ContainerFlexFixed>
          </ContainerGrid>
        </FormGroup>
        <FormGroup>
          <ContentMoisture>
            <ContainerInput>
              <FormField
                id="humidityFieldCapacity"
                name="humidityFieldCapacity"
                label="Umidade em Capacidade de Campo"
                type="text"
              />
            </ContainerInput>
            <ContainerDropdown>
              <Dropdown
                name="humidityFieldCapacityUnit"
                label=""
                placeholder="Selecione"
                options={humidityFieldCapacityUnitList}
              />
            </ContainerDropdown>
          </ContentMoisture>
        </FormGroup>
        <FormGroup>
          <ContentMoisture>
            <ContainerInput>
              <FormField
                id="limitHumidityContent"
                name="limitHumidityContent"
                label="Umidade em Ponto de Murcha Permanente"
                type="text"
              />
            </ContainerInput>
            <ContainerDropdown>
              <Dropdown
                name="limitHumidityContentUnit"
                label=""
                placeholder="Selecione"
                options={limitHumidityContentUnitList}
              />
            </ContainerDropdown>
          </ContentMoisture>
        </FormGroup>
        <FormGroup>
          <ContainerFlexFixed>
            <WaterStorageCapacity>
              <FormField
                id="soilWaterCapacity"
                name="soilWaterCapacity"
                label="Capacidade de Armazenamento de Água no Solo (CAD)"
                type="text"
              />
            </WaterStorageCapacity>
            <LegendMillimeter>Mm</LegendMillimeter>
          </ContainerFlexFixed>
        </FormGroup>
      </Collapse>
    </div>
  );
};

export default SoilContent;
