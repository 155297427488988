import React from 'react';
import PropTypes from 'prop-types';

import Divider from '../../../../components/divider';
import { pulverizationDate } from '../../../../helpers/date-helper';

import * as S from './styles';

const Pulverization = (props) => {
  const {
    pulverizationData,
  } = props;

  const dataColors = {
    0: '#348312',
    0.5: '#169916',
    1: '#17C118',
    1.5: '#79DF00',
    2: '#A9E53D',
    2.5: '#C1FF66',
    3: '#FDED00',
    3.5: '#EFC600',
    4: '#FFA726',
    4.5: '#FB8C00',
    5: '#FF3800',
    5.5: '#FF0000',
    6: '#D80300',
    6.5: '#B21613',
    7: '#8C1C10',
  };

  return (
    <S.Container>
      <S.Title>Favorabilidade de pulverização</S.Title>
      <Divider />
      <S.ContainerGrid>
        {(pulverizationData && pulverizationData.map((item) => (
          <S.Wrapper key={item.datetime}>
            <S.Bar color={dataColors[item.value]} />
            <S.Label>{pulverizationDate(item.datetime)}</S.Label>
          </S.Wrapper>
        )))}
      </S.ContainerGrid>
      <S.ContainerLegend>
        <S.Legend>
          <S.LegendGreen />
          <S.TextLengend>Favorável</S.TextLengend>
        </S.Legend>
        <S.Legend>
          <S.LegendYellow />
          <S.TextLengend>Atenção</S.TextLengend>
        </S.Legend>
        <S.Legend>
          <S.LegendRed />
          <S.TextLengend>Não favorável</S.TextLengend>
        </S.Legend>
      </S.ContainerLegend>
    </S.Container>
  );
};

Pulverization.propTypes = {
  pulverizationData: PropTypes.array.isRequired,
};

export default Pulverization;
