import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  LegendContainer,
  LegendColor,
  LegendTitle,
  Title,
  InfosContainer,
} from './styles';

const MapLegend = (props) => {
  const {
    title,
    legendInfos,
  } = props;

  const allLegend = useMemo(() => legendInfos.map((x) => {
    const { id, title: infoTitle, color: infoColor } = x;
    return (
      <LegendContainer key={id}>
        <LegendTitle>{infoTitle}</LegendTitle>
        <LegendColor color={infoColor} />
      </LegendContainer>
    );
  }), [legendInfos]);

  return (
    <Container>
      <InfosContainer>
        {allLegend}
      </InfosContainer>
      <Title>{title}</Title>
    </Container>
  );
};

MapLegend.propTypes = {
  title: PropTypes.string.isRequired,
  legendInfos: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  })).isRequired,
};

export default MapLegend;
