import styled from 'styled-components';

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

const Table = styled.table`
  border-spacing: 0;
  background: #fff;
  border-radius: 5px;
  width: 100%;
`;

const THead = styled.thead`
  text-align: left;
  border-radius: 5px;
`;

const TableBody = styled.tbody`
  font-size: 0.875rem;
`;

const Tr = styled.tr`
  display: table-row;
`;

const Td = styled.td`
  padding: 1rem 0.25rem;
  border-bottom: 1px solid #f0f0f0;
  margin: 0;
`;

const Th = styled.th`
  padding: 1rem 0.25rem;
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  :first-child {
    border-top-left-radius: 2px;
  }
`;

export {
  TableContainer,
  Table,
  THead,
  TableBody,
  Tr,
  Th,
  Td,
};
