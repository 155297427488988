import styled from 'styled-components';

const Label = styled.label`
  ${(props) => {
    switch (props.variant) {
      case 'danger':
        return `
          color: #ff0000;
        `;
      case 'primary':
      default:
        return `
          color: #000;
        `;
    }
  }}
`;

export default Label;
