import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginForm from './components/login';
import { doLogin } from '../../../store/actions/user';

const Login = (props) => {
  const { onLogin } = props;
  const history = useHistory();
  const handleAuthUser = async ({ username, password }) => {
    const user = {
      username,
      password,
    };
    const result = await onLogin(user);
    if (result) {
      history.push('/home');
      return;
    }
    toast.error('Usuário e/ou senha inválidos');
  };

  return (
    <LoginForm
      handleAuthUser={handleAuthUser}
    />
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onLogin: doLogin,
};

export default connect(null, mapDispatchToProps)(Login);
