import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
} from 'victory';
import {
  chartLegendList,
  FORECAST_CRITICAL_HUMIDITY,
  FORECAST_HUMIDITY_COLOR,
  IRRIGATION_CRITICAL_HUMIDITY,
  IRRIGATION_HUMIDITY_COLOR,
} from '../../../../../util/chartLabelsAndColors';
import { monthDayFormat } from '../../../../../helpers/date-helper';

import {
  Container,
  Title,
} from './styles';
import ChartLegend from '../../../../../components/chartLegend';
import Divider from '../../../../../components/divider';

const IrrigationChart = (props) => {
  const {
    arm = [],
  } = props;

  const [barData, setBarData] = useState([]);

  const getBarColor = (data) => {
    const { datum } = data;
    const { x, realValue } = datum;
    const currentDate = DateTime.fromISO(x).setZone('utc');
    const { values } = currentDate.diffNow('days');
    const { days } = values;

    if (days < -1.0 && days < 0.1) {
      return realValue > 50 ? IRRIGATION_HUMIDITY_COLOR : IRRIGATION_CRITICAL_HUMIDITY;
    }

    return realValue > 50 ? FORECAST_HUMIDITY_COLOR : FORECAST_CRITICAL_HUMIDITY;
  };

  useEffect(() => {
    const arrData = [];

    arm.forEach((data) => {
      arrData.push({
        x: data.x,
        y: data.y,
        realValue: data.y,
      });
    });

    setBarData(arrData);
  }, [arm]);

  return (
    <Container>
      <Title>Umidade do solo</Title>
      <Divider />
      <VictoryChart
        width={800}
        domainPadding={15}
        padding={{
          left: 70, right: 60, bottom: 50, top: 10,
        }}
        domain={{
          y: [0, 100],
        }}
      >
        <VictoryAxis
          style={{
            axis: { stroke: 'none' },
            grid: { stroke: 'none' },
            tickLabels: { fontSize: 10 },
            axisLabel: { fontSize: 12, padding: 30 },
          }}
          label="Data"
          offsetY={50}
          fixLabelOverlap
        />
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: 10 },
            grid: { stroke: 'gray' },
            axisLabel: { fontSize: 12, padding: 42 },
          }}
          crossAxis={false}
          label="Umidade do solo (%)"
        />
        <VictoryBar
          style={{
            data: {
              fill: (data) => getBarColor(data),
              strokeWidth: 1,
              width: 18,
            },
          }}
          data={barData}
          x={(data) => {
            const { x } = data;
            return monthDayFormat(x);
          }}
        />
      </VictoryChart>
      <ChartLegend items={chartLegendList.irrigation} />
    </Container>
  );
};

IrrigationChart.propTypes = {
  arm: PropTypes.arrayOf(PropTypes.shape({
    y: PropTypes.number,
    x: PropTypes.string,
  })).isRequired,
};

export default IrrigationChart;
