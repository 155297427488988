import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import * as S from './styles';

import Label from '../styles/label';
import FieldLabel from './fieldLabel';

const FormField = ({
  label,
  isRequired,
  type,
  validateOnSelect,
  placeholder,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const onFieldChange = (value) => {
    if (validateOnSelect && !validateOnSelect(value.target.value)) return;
    helpers.setValue(value.target.value);
  };

  return (
    <div>
      <FieldLabel
        htmlFor={field.name}
        labelText={label}
        required={isRequired}
      />
      <S.Input
        id={field.name}
        type={type}
        placeholder={placeholder}
        onChange={onChange || onFieldChange}
        value={field.value}
        {...props}
      />
      {(meta.error) && (
        <Label variant="danger">
          {meta.error}
        </Label>
      )}
    </div>
  );
};

FormField.defaultProps = {
  isRequired: false,
  type: null,
  validateOnSelect: null,
  placeholder: '',
  onChange: null,
  label: '',
};

FormField.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  validateOnSelect: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormField;
