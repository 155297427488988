import styled from 'styled-components';

const AvatarContainer = styled.div`
   display: flex;
   align-items: center;
   flex-direction: row-reverse;
`;

const Content = styled.div`
    width: 3.5rem;
    height: 3.5rem;
    border: solid 1px #CED4DA;
    background-color: #CED4DA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

`;

const Initials = styled.span`
    font-size: 1.5rem;
    color: #FFF;
    font-weight: bold;
`;

export {
  Initials,
  Content,
  AvatarContainer,
};
