import { ActionTypes } from '../actions/field';

const INITIAL_STATE = {
  fields: [],
};

const fieldReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.GET_FIELDS:
      return {
        ...payload,
      };
    default: return state;
  }
};

export default fieldReducer;
