import React from 'react';
import PropTypes from 'prop-types';
// components
import Navbar from './navbar';

import ListFarms from '../farms/index';

import * as S from './styles';

const Layout = (props) => (
  <S.Container>
    <Navbar />
    <S.Wrapper className="site-layout">
      <S.Sidebar width={350}>
        <ListFarms />
      </S.Sidebar>
      <S.Wrapper>
        <S.MainContainer>
          {props.children}
        </S.MainContainer>
      </S.Wrapper>
    </S.Wrapper>
  </S.Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
