import styled, { css } from 'styled-components';
import { Layout } from 'antd';

export const Nav = styled(Layout.Header)`
  && {
    ${({ theme }) => css`
      display: flex;
      width: 100%;
      height: 3.5rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2.5rem 3rem;
      border: 1px solid ${theme.border.colors.regular};
      border-right: none;
      background-color: ${theme.colors.white};
    `} 
  }
`;

export const MenuOptions = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  margin-top: 35px;

  label { 
    color: #578687;
    margin-left: 4px;
  }

  hr { 
    width: 100%;
    height: 5px;
    margin-top: -2px;
    margin-left: 2.5px;
    background-color: #578687;
    border-color: #578687;
    opacity: 1;
  }
`;

export const ContainerLogout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > h6 {
    margin-left: 10px;
    margin-top: 10px;
    color: #578687;
    font-size: 14px;
    font-weight: bold;
  }
  
  & > img {
    margin-left: 5px;
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const ContainerLogo = styled.div``;

export const ContainerUser = styled.div`
  margin-right: 2rem;
`;

export const LogoutButton = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: none;
    position: absolute;
    z-index: 5;
    background-color: ${theme.colors.white};
    box-shadow: -11px 15px 21px #CED4DA80;
    padding: ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius.large};
    flex-direction: column;
    top: 3.5rem;
    right: 7rem;
    width: 25rem;
  `}
`;

export const Dropdown = styled.div`
  ${({ theme }) => css`
    float: left;
    overflow: hidden;
    display: flex;
    margin-left: ${theme.spacings.large};
    margin-right: ${theme.spacings.large};
    
    &:hover .dropdwn {
        display: block;
    }
  `}
`;

export const DropdownLabel = styled.span`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${theme.colors.white};
    text-transform: uppercase;
    cursor: pointer;
    font-size: ${theme.fonts.sizes.large};
    font-weight: ${theme.fonts.weight.semiBold};
    margin-left: ${theme.spacings.xsmall};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    color: ${theme.colors.grey};
    font-size: ${theme.fonts.sizes.small};
    text-transform: uppercase;
    font-weight: ${theme.fonts.weight.bold};
    cursor: pointer;

    span {
      margin-left: ${theme.spacings.xsmall};
    }

    &:hover {
      background-color: ${theme.colors.selected};
      color: ${theme.colors.darkGrey};
    }
  `}
`;

export const Icon = styled.div`
  width: 30px;
  height: 20px;
`;
