import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HiPlus } from 'react-icons/hi';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchFarms, selectFarm, deleteFarm } from '../../store/actions/farm';

import SearchInput from '../search-input';

import FarmCard from './card';

import {
  Container,
  ContainerAddFarm,
  ContainerSearch,
  ContainerList,
  AddBtn,
} from './styles';

const ListFarms = (props) => {
  const {
    onFetchFarms,
    farms,
    farmSelected,
    onSelectFarm,
    onDeleteFarm,
  } = props;
  const history = useHistory();

  const [farmFilter, setFarmFilter] = useState('');

  useEffect(() => {
    async function LoadFarms() {
      await onFetchFarms(farmFilter);
    }
    LoadFarms();
  }, [farmFilter]);

  const requestDeleteFarm = async (farmId) => {
    await onDeleteFarm(farmId);
    toast.success('Fazenda deletada com sucesso');
    await onFetchFarms();
  };

  return (
    <Container>
      <ContainerSearch>
        <SearchInput
          value={farmFilter}
          onChange={setFarmFilter}
        />
      </ContainerSearch>

      <ContainerAddFarm>
        <h4>FAZENDAS</h4>
        <Link to="/farm/new">
          <AddBtn>
            <HiPlus size={20} color="#FFF" />
          </AddBtn>
        </Link>
      </ContainerAddFarm>
      <ContainerList>
        {
          farms && farms.map((farm) => (
            <FarmCard
              key={farm.id}
              farm={farm}
              isSelected={farmSelected === farm.id}
              onClick={() => {
                onSelectFarm({
                  id: farm.id,
                  farmLocation: { lat: farm.address.city.latitude, lng: farm.address.city.longitude },
                });
                history.push(`/fields/${farm.id}`);
              }}
              onDeleteFarm={requestDeleteFarm}
            />
          ))
        }
      </ContainerList>
    </Container>
  );
};

ListFarms.propTypes = {
  onFetchFarms: PropTypes.func.isRequired,
  farms: PropTypes.arrayOf(PropTypes.shape()),
  onSelectFarm: PropTypes.func.isRequired,
  farmSelected: PropTypes.number,
  onDeleteFarm: PropTypes.func.isRequired,
};

ListFarms.defaultProps = {
  farmSelected: null,
  farms: [],
};

const mapStateToProps = (props) => {
  const { farm: { farms, farmSelected } } = props;
  return { farms, farmSelected };
};

const mapDispatchToProps = {
  onFetchFarms: fetchFarms,
  onSelectFarm: selectFarm,
  onDeleteFarm: deleteFarm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListFarms);
