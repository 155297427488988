import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/global';
import Router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import theme from './styles/theme';

Sentry.init({
  dsn: 'https://5f124f6dba3446c7acb267acfa276e73@o982755.ingest.sentry.io/5992488',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router />
    </Provider>
  </ThemeProvider>,
);
