import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  /* Spinner, */
} from 'reactstrap';
import {
  TitleCulture,
  ContainerCategories,
  CategoryButton,
  CategoryContainer,
} from './styles';

import { getSubCategories } from '../../../../services/contexts/categories';
import Dropdown from '../../../../components/forms/dropdown';

import WITHOUT_CULTURE from '../../../../assets/images/ic_without_culture.svg';
import CEREALS from '../../../../assets/images/ic_cereals.svg';
import FLOREST from '../../../../assets/images/ic_florest.svg';
import VEGETABLE from '../../../../assets/images/ic_vegetable.svg';
import GRASS from '../../../../assets/images/ic_grass.svg';
import FRUITS from '../../../../assets/images/ic_fruits.svg';
import { CultureEnum } from '../../../../common/enum/cultureEnum';
import { defaultDate } from '../../../../helpers/date-helper';

const CultureContent = (props) => {
  const {
    values,
    setFieldValue,
  } = props;

  const handleSelectCategory = (e) => {
    setFieldValue('category', e);
  };

  const cultureList = [
    {
      id: 6,
      cultureName: 'Sem cultura',
      imgUri: WITHOUT_CULTURE,
      selected: values.category === 6,
    },
    {
      id: 1,
      cultureName: 'Grãos & Fibras',
      imgUri: CEREALS,
      selected: values.category === 1,
    },
    {
      id: 2,
      cultureName: 'Perenes',
      imgUri: FRUITS,
      selected: values.category === 2,
    },
    {
      id: 3,
      cultureName: 'Hortícolas',
      imgUri: VEGETABLE,
      selected: values.category === 3,
    },
    {
      id: 4,
      cultureName: 'Pasto',
      imgUri: GRASS,
      selected: values.category === 4,
    },
    {
      id: 5,
      cultureName: 'Floresta',
      imgUri: FLOREST,
      selected: values.category === 5,
    },
  ];

  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    async function LoadCategories() {
      if (values.category) {
        setFieldValue('subCategoryId', null);
        setFieldValue('sowingPlantingDate', null);
        setFieldValue('productionCycleUnit', null);

        if ([CultureEnum.PERENES, CultureEnum.PASTO, CultureEnum.FLORESTA, CultureEnum.SEM_CULTURA].includes(values.category)) {
          setFieldValue('sowingPlantingDate', defaultDate());
          setFieldValue('productionCycleUnit', 2);
        }

        const subCategoriesData = await getSubCategories(values.category);
        setSubCategories(subCategoriesData);

        if (values.category === CultureEnum.SEM_CULTURA) {
          setFieldValue('subCategory', subCategoriesData[0]);
          setFieldValue('subCategoryId', { value: 76, label: 'Nativo' });
        }
      }
    }
    LoadCategories();
  }, [values.category]);

  const setCategory = (value) => {
    if (subCategories && value && value.value) {
      const item = subCategories.find((p) => p.id === value.value);
      setFieldValue('subCategory', item);
    }
  };

  return (
    <div>
      <TitleCulture>Selecione o Grande Grupo</TitleCulture>
      <FormGroup>
        <ContainerCategories>
          {
            cultureList.map((item) => (
              <CategoryContainer key={item.id}>
                <CategoryButton
                  onClick={() => handleSelectCategory(item.id)}
                  active={item.selected}
                >
                  <img src={item.imgUri} alt={item.cultureName} />
                  <h4>{item.cultureName}</h4>
                </CategoryButton>
              </CategoryContainer>
            ))
          }
        </ContainerCategories>
      </FormGroup>
      {!values.category || values.category === CultureEnum.SEM_CULTURA ? <div /> : (
        <FormGroup>
          <Dropdown
            name="subCategoryId"
            label="Cultivar"
            placeholder="Selecione o tipo de cultivo"
            isRequired
            onChange={(e) => setCategory(e)}
            cacheUniq={values.category}
            options={subCategories && subCategories.map((p) => ({ value: p.id, label: p.name }))}
          />
        </FormGroup>
      )}
    </div>
  );
};

CultureContent.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CultureContent;
