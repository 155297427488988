import React from 'react';
import PropTypes from 'prop-types';
import { BiCheck } from 'react-icons/bi';
import { FaCircle } from 'react-icons/fa';
import * as S from './styles';

const StepComponent = ({
  activeIndex,
  steps,
}) => (
  <>
    {
      steps.map((step, index) => {
        const isSameIndex = index === activeIndex;

        return (
          <S.StepContainer key={step.id} active={isSameIndex} completed={index < activeIndex}>
            <S.Label>
              <span>{step.label}</span>
              {index < activeIndex ? <BiCheck size={25} /> : null}
              {isSameIndex ? <FaCircle size={10} color="#6C757D" /> : null}
            </S.Label>
          </S.StepContainer>
        );
      })
    }
  </>
);

StepComponent.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
};

export default StepComponent;
