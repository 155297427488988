import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { resetPassword } from '../../../store/actions/user';
import ResetPasswordForm from './components/form';

const ResetPassword = (props) => {
  const { onResetPassword } = props;
  const history = useHistory();

  const handleResetPassword = async (email) => {
    await onResetPassword(email);
    history.push('/have-code');
  };

  return (
    <ResetPasswordForm
      handleResetPassword={handleResetPassword}
    />
  );
};

ResetPassword.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onResetPassword: resetPassword,
};

export default connect(null, mapDispatchToProps)(ResetPassword);
