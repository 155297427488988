import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const ContainerAddFarm = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2.5rem 2.5rem;
    align-items: center;
    
    h4 { 
      color: ${theme.colors.darkGrey};
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0;
    }
  `}
`;

export const ContainerSearch = styled.div`
  padding: 1.5rem 1.5rem 0;
`;

export const ContainerList = styled.div``;

export const AddBtn = styled.div`
  ${({ theme }) => css`
    width: 3rem;
    height: 3rem;
    background-color: ${theme.colors.green};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4rem;

    &:hover {
      box-shadow: 4px 6px 19px #00000029;
    }
  `}
`;
