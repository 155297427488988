import React, { useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import {
  ContainerPolygon,
  AddPolygon,
  EditPolygon,
  ButtonAddMap,
} from '../selectArea/styles';

import ImageSelect from '../../../../assets/images/select-area.svg';
import ImageEdit from '../../../../assets/images/edit.svg';

import FormField from '../../../../components/forms/formField';

const StepSelectArea = (props) => {
  const {
    showMap, setShowMap, mapImage, setFieldValue,
  } = props;

  const handlerShowMap = () => {
    setShowMap(!showMap);
  };

  useEffect(() => {
    if (mapImage) {
      setFieldValue('image', mapImage);
    }
  }, [mapImage]);

  const renderMap = () => {
    if (mapImage) {
      return (
        <ContainerPolygon>
          <img src={mapImage} alt="" />
          <EditPolygon type="button" onClick={handlerShowMap}>
            <img src={ImageEdit} alt="" />
          </EditPolygon>
        </ContainerPolygon>

      );
    }
    return (
      <ContainerPolygon>
        <img src={ImageSelect} alt="" />
        <AddPolygon>
          <ButtonAddMap type="button" onClick={handlerShowMap}>ADICIONAR</ButtonAddMap>
        </AddPolygon>
      </ContainerPolygon>
    );
  };

  return (
    <div>
      <FormGroup>
        <h4>Selecionar Área</h4>
        <FormField
          id="name"
          name="name"
          label="Nome"
          placeholder="Nome"
          isRequired
        />
      </FormGroup>
      <FormGroup>
        {renderMap()}
      </FormGroup>
    </div>
  );
};

StepSelectArea.propTypes = {
  showMap: PropTypes.bool.isRequired,
  setShowMap: PropTypes.func.isRequired,
  mapImage: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default StepSelectArea;
