import React from 'react';
import AgroLogo from '../../assets/images/agro-logo.svg';

import * as S from './styles';

const AboutContainer = () => (
  <S.LogoWrapper>
    <S.LogoImg src={AgroLogo} />
    <h1>
      Referência em tecnologias e soluções ambientais com
      <b> ênfase em TEMPO e CLIMA.</b>
    </h1>
  </S.LogoWrapper>

);

export default AboutContainer;
