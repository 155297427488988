import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-left: 0px;
  h6 {
    color: #303D31;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: left;
  }
`;

export const ContainerRegister = styled.div`
  width: 75%;
  position: relative;
  margin-top: 20px;
  padding: 30px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: #CED4DA;
  background-color: #fff;
  z-index: 2;
`;

export const ContainerFlex = styled.div`
  display: flex;
  margin-top: 4rem;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ButtonWhite = styled.button`
  width: 219px;
  height: 38px;
  margin-left: 0px;
  margin-right: 28px;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #578687;
  background-color: #ffffff00;
  border: 1px solid #578687;
  border-radius: 4px;
  text-align: center;
`;

export const ButtonGreen = styled.button`
  width: 219px;
  height: 38px;
  margin-right: 0px;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #578687;
  border: 1px solid #578687;
  border-radius: 4px;
  text-align: center;
`;
