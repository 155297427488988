import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { IoMdPin } from 'react-icons/io';
import { Breadcrumb } from 'antd';
// assets
import ImageArea from '../../../assets/images/img-details.svg';
import Cultivate from '../../../assets/images/cultivate.svg';
import CultivationDateImg from '../../../assets/images/date.svg';
import CycleImg from '../../../assets/images/cycle.svg';
import ProductionImg from '../../../assets/images/prodution.svg';
import { formatDateFromISO, getHarvestDate } from '../../../helpers/date-helper';
import { getLabelMonths } from '../../../helpers/getLabelMonths';
import { CultureEnum } from '../../../common/enum/cultureEnum';

import {
  fetchFieldById,
  fetchAccumulatedPrecipitation,
  fetchDailyPrecipitationForecast,
  fetchMonthlyPrecipitationForecast,
  fetchClimatePrecipitation,
  fetchMonthlyPrecipitation,
  fetchClimateTemperature,
  fetchMonthlyTemperature,
  fetchDailyTemperatureForecast,
  fetchMonthlyTemperatureForecast,
  fetchHydricBalance,
  fetchNdvi,
  fetchPulverization,
  fetchGlmImages,
  fetchRrqpefImages,
  fetchFireRiskImages,
  fetchDiseases,
  fetchFocusFireImages,
} from '../../../store/actions/field';

// components
import FullImage from '../components/fullImage';
import CardCulture from '../../../components/cardCulture';
import SoilCharacteristics from './soilCharacteristics';
import ClimateSection from './climateSection';

// util
import {
  productionCycleUnit,
} from '../../../util/units';

import {
  ContainerFlex,
  ContainerDetails,
  Content,
  ImageField,
  Details,
  ContainerName,
  Title,
  ContainerLocal,
  LocalField,
  ContainerGrains,
} from './styles';

const DetailsField = (props) => {
  const {
    farmSelected,
    onFetchFieldById,
    onFetchAccumulatedPrecipitation,
    onFetchDailyPrecipitationForecast,
    onFetchMonthlyPrecipitationForecast,
    onFetchClimatePrecipitation,
    onFetchMonthlyPrecipitation,
    onFetchClimateTemperature,
    onFetchMonthlyTemperature,
    onFetchDailyTemperatureForecast,
    onFetchMonthlyTemperatureForecast,
    onFetchHydricBalance,
    onFetchNdvi,
    onFetchPulverization,
    onFetchGlmImages,
    onFetchRrqpefImages,
    onFetchFireRiskImages,
    onFetchDiseases,
    onFetchFocusFireImages,
    farms,
  } = props;

  const [field, setField] = useState({});

  const [monthlyPrec, setMonthlyPrec] = useState([]);
  const [standardDeviation, setStandardDeviation] = useState([]);
  const [PrecAverage, setPrecAverage] = useState([]);

  const [monthlyTempMax, setMonthlyTempMax] = useState([]);
  const [standardDeviationTempMax, setStandardDeviationTempMax] = useState([]);
  const [tempAverageMax, setTempAverageMax] = useState([]);
  const [monthlyTempMin, setMonthlyTempMin] = useState([]);
  const [standardDeviationTempMin, setStandardDeviationTempMin] = useState([]);
  const [tempAverageMin, setTempAverageMin] = useState([]);

  const [precipitationData, setPrecipitationData] = useState([]);
  const [dailyPrecipitationForecastData, setDailyPrecipitationForecastData] = useState([]);
  const [monthlyTemperatureForecastData, setMonthlyTemperatureForecastData] = useState([]);
  const [dailyTemperatureForecastData, setDailyTemperatureForecastData] = useState([]);
  const [monthlyPrecipitationForecastData, setMonthlyPrecipitationForecastData] = useState([]);
  const [lastYearNdviData, setLastYearNdviData] = useState([]);
  const [currentYearNdviData, setCurrentYearNdviData] = useState([]);
  const [theoreticalCurveNdviData, setTheoreticalCurveNdviData] = useState([]);
  const [pulverizationData, setPulverizationData] = useState([]);
  const [diseasesData, setDiseasesData] = useState([]);

  const [armData, setArmData] = useState([]);

  const { id: fieldId } = useParams();

  const loadFarm = async () => {
    const fieldData = await onFetchFieldById(fieldId);
    if (fieldData) {
      setField(fieldData);
    }
  };

  const loadClimateChartData = async () => {
    if (fieldId) {
      try {
        const climatePrecData = await onFetchClimatePrecipitation(fieldId);
        const monthlyPrecData = await onFetchMonthlyPrecipitation(fieldId);

        const climatePrecMap = climatePrecData.map((climate) => {
          const { standardDeviation: deviation, monthAverage, month } = climate;
          return {
            x: DateTime.local(DateTime.now().year, month, { locale: 'pt-br' }).monthShort,
            y0: monthAverage - deviation,
            y: monthAverage + deviation,
          };
        });

        const monthlyPrecMap = monthlyPrecData.map((prec) => {
          const { monthAverage, dateTime } = prec;
          return {
            x: DateTime.fromISO(dateTime, { locale: 'pt-br' }).plus({ day: 1 }).monthShort,
            y: monthAverage,
          };
        });

        const precAverageMap = climatePrecData.map((average) => {
          const { monthAverage, month } = average;
          return {
            x: DateTime.local(DateTime.now().year, month, { locale: 'pt-br' }).monthShort,
            y: monthAverage,
          };
        });

        const climatePrecMapOrdered = getLabelMonths().map((object) => climatePrecMap.find((i) => i.x === object));
        const precAverageMapOrdered = getLabelMonths().map((object) => precAverageMap.find((i) => i.x === object));
        const monthlyPrecMapOrdered = getLabelMonths().map((object) => monthlyPrecMap.find((i) => i.x === object));

        setStandardDeviation(climatePrecMapOrdered);
        setPrecAverage(precAverageMapOrdered);
        setMonthlyPrec(monthlyPrecMapOrdered);
      } catch (err) {
        const error = err;
        console.log(error);
      }
    }
  };

  const loadTemperatureChartData = async () => {
    if (fieldId) {
      try {
        const climateTempData = await onFetchClimateTemperature(fieldId);
        const monthlyTempData = await onFetchMonthlyTemperature(fieldId);

        const climateTempMaxMap = climateTempData.map((climate) => {
          const { standardMax: deviation, climatologyMax: climatology, month } = climate;
          return {
            x: DateTime.local(DateTime.now().year, month, { locale: 'pt-br' }).monthShort,
            y0: climatology - deviation,
            y: climatology + deviation,
          };
        });

        const monthlyTempMaxMap = monthlyTempData.map((prec) => {
          const { tmax, dateTime } = prec;
          return {
            x: DateTime.fromISO(dateTime, { locale: 'pt-br' }).plus({ day: 1 }).monthShort,
            y: tmax,
          };
        });

        const tempMaxAverageMap = climateTempData.map((average) => {
          const { climatologyMax: climatology, month } = average;
          return {
            x: DateTime.local(DateTime.now().year, month, { locale: 'pt-br' }).monthShort,
            y: climatology,
          };
        });

        const climateTempMinMap = climateTempData.map((climate) => {
          const { standardMin: deviation, climatologyMin: climatology, month } = climate;
          return {
            x: DateTime.local(DateTime.now().year, month, { locale: 'pt-br' }).monthShort,
            y0: climatology - deviation,
            y: climatology + deviation,
          };
        });

        const monthlyTempMinMap = monthlyTempData.map((prec) => {
          const { tmin, dateTime } = prec;
          return {
            x: DateTime.fromISO(dateTime, { locale: 'pt-br' }).plus({ day: 1 }).monthShort,
            y: tmin,
          };
        });

        const tempMinAverageMap = climateTempData.map((average) => {
          const { climatologyMin: climatology, month } = average;
          return {
            x: DateTime.local(DateTime.now().year, month, { locale: 'pt-br' }).monthShort,
            y: climatology,
          };
        });

        const climateTempMaxMapOrdered = getLabelMonths().map((object) => climateTempMaxMap.find((i) => i.x === object));
        const tempMaxAverageMapOrdered = getLabelMonths().map((object) => tempMaxAverageMap.find((i) => i.x === object));
        const monthlyTempMaxMapOrdered = getLabelMonths().map((object) => monthlyTempMaxMap.find((i) => i.x === object));
        const climateTempMinMapOrdered = getLabelMonths().map((object) => climateTempMinMap.find((i) => i.x === object));
        const tempMinAverageMapOrdered = getLabelMonths().map((object) => tempMinAverageMap.find((i) => i.x === object));
        const monthlyTempMinMapOrdered = getLabelMonths().map((object) => monthlyTempMinMap.find((i) => i.x === object));

        setStandardDeviationTempMax(climateTempMaxMapOrdered);
        setTempAverageMax(tempMaxAverageMapOrdered);
        setMonthlyTempMax(monthlyTempMaxMapOrdered);
        setStandardDeviationTempMin(climateTempMinMapOrdered);
        setTempAverageMin(tempMinAverageMapOrdered);
        setMonthlyTempMin(monthlyTempMinMapOrdered);
      } catch (err) {
        const error = err;
        console.log(error);
      }
    }
  };

  const loadPrecipitationChartData = async () => {
    const accumulatedPrecipiation = await onFetchAccumulatedPrecipitation(fieldId);
    const accumulatedMap = accumulatedPrecipiation.map((prec) => {
      const { dateTime, value } = prec;
      return {
        x: dateTime,
        y: value,
      };
    });

    setPrecipitationData(accumulatedMap);
  };

  const loadDailyPrecipitationForecastData = async () => {
    const dailyPrecipitationForecast = await onFetchDailyPrecipitationForecast(fieldId);
    const accumulatedMap = dailyPrecipitationForecast.map((prec) => {
      const { dateTime, value } = prec;
      return {
        dateTime,
        value,
      };
    });

    setDailyPrecipitationForecastData(accumulatedMap);
  };

  const loadMonthlyPrecipitationForecastData = async () => {
    const precipitationForecast = await onFetchMonthlyPrecipitationForecast(fieldId);
    const precipitationMap = precipitationForecast.map((prec) => {
      const {
        dateTime, mean, module,
      } = prec;

      return {
        dateTime,
        mean,
        module,
      };
    });

    const precipitation = precipitationMap.sort((a, b) => {
      const aFrom = DateTime.fromISO(a.dateTime);
      const bFrom = DateTime.fromISO(b.dateTime);
      return aFrom - bFrom;
    });

    setMonthlyPrecipitationForecastData(precipitation);
  };

  const loadIrrigationChartData = async () => {
    const hydricBalance = await onFetchHydricBalance(fieldId);

    const armDataMap = hydricBalance.map((hydric) => {
      const { date, armPercentage } = hydric;
      return {
        x: date,
        y: armPercentage || 0,
      };
    });

    setArmData(armDataMap);
  };

  const loadDailyTemperatureForecastData = async () => {
    const dailyTemperatureForecast = await onFetchDailyTemperatureForecast(fieldId);
    const accumulatedMap = dailyTemperatureForecast.map((temp) => {
      const {
        date, tmin, tmax, risk,
      } = temp;
      return {
        dateTime: date,
        min: tmin,
        max: tmax,
        risk,
      };
    });

    const temperature = accumulatedMap.sort((a, b) => {
      const aFrom = DateTime.fromISO(a.dateTime);
      const bFrom = DateTime.fromISO(b.dateTime);
      return aFrom - bFrom;
    });

    setDailyTemperatureForecastData(temperature);
  };

  const loadMonthlyTemperatureForecastData = async () => {
    const temperatureForecast = await onFetchMonthlyTemperatureForecast(fieldId);
    const temperatureMap = temperatureForecast.map((temp) => {
      const {
        dateTime, mean, module,
      } = temp;

      return {
        dateTime,
        mean,
        module,
      };
    });

    const temperature = temperatureMap.sort((a, b) => {
      const aFrom = DateTime.fromISO(a.dateTime);
      const bFrom = DateTime.fromISO(b.dateTime);
      return aFrom - bFrom;
    });

    setMonthlyTemperatureForecastData(temperature);
  };

  const loadNdviData = async () => {
    const ndviData = await onFetchNdvi(fieldId);

    const lastYearNdviMap = ndviData.lastYear.sort((a, b) => {
      const aFrom = DateTime.fromISO(a.dateTime);
      const bFrom = DateTime.fromISO(b.dateTime);
      return aFrom - bFrom;
    });

    const lastYearNdvi = lastYearNdviMap.map((item) => {
      const { dateTime, value } = item;

      return {
        x: DateTime.fromISO(dateTime).setZone('utc').toFormat('dd/MM'),
        y: value,
      };
    });

    const currentYearNdviMap = ndviData.currentYear.sort((a, b) => {
      const aFrom = DateTime.fromISO(a.dateTime);
      const bFrom = DateTime.fromISO(b.dateTime);
      return aFrom - bFrom;
    });

    const currentYearNdvi = currentYearNdviMap.map((item) => {
      const { dateTime, value } = item;

      return {
        x: DateTime.fromISO(dateTime).setZone('utc').toFormat('dd/MM'),
        y: value,
      };
    });

    const theoreticalCurveNdviMap = ndviData.theoretical.sort((a, b) => {
      let aFrom;
      let bFrom;

      if (a.month === b.month) {
        aFrom = a.day;
        bFrom = b.day;
      } else {
        aFrom = a.month;
        bFrom = b.month;
      }

      return aFrom - bFrom;
    });

    const theoreticalCurveNdvi = theoreticalCurveNdviMap.map((item) => {
      const { day, month, value } = item;

      return {
        x: `${day > 10 ? day : `0${day}`}/${month < 10 ? `0${month}` : month}`,
        y: value,
      };
    });

    setLastYearNdviData(lastYearNdvi);
    setCurrentYearNdviData(currentYearNdvi);
    setTheoreticalCurveNdviData(theoreticalCurveNdvi);
  };

  const loadPulverizationData = async () => {
    const data = await onFetchPulverization(fieldId);

    const dataOrdered = data.sort((a, b) => {
      const aFrom = DateTime.fromISO(a.datetime);
      const bFrom = DateTime.fromISO(b.datetime);
      return aFrom - bFrom;
    });

    const pulverization = dataOrdered.map((item) => {
      const { datetime, value } = item;

      return {
        datetime, value,
      };
    });
    setPulverizationData(pulverization);
  };

  const loadDiseasesData = async () => {
    const data = await onFetchDiseases(fieldId);

    setDiseasesData(data);
  };

  useEffect(() => {
    loadFarm();
    loadClimateChartData();
    loadTemperatureChartData();
    loadPrecipitationChartData();
    loadDailyPrecipitationForecastData();
    loadMonthlyPrecipitationForecastData();
    loadIrrigationChartData();
    loadDailyTemperatureForecastData();
    loadMonthlyTemperatureForecastData();
    loadNdviData();
    loadPulverizationData();
    loadDiseasesData();
  }, [fieldId]);

  const {
    name,
    subCategory,
    sowingPlantingDate,
    productionCycle,
    productionCycleUnitEnum,
    soilType,
    sandContent,
    siltContent,
    clayContent,
    soilDensity,
    humidityFieldCapacity,
    humidityFieldCapacityUnitEnum,
    limitHumidityContent,
    limitHumidityContentUnitEnum,
    soilWaterCapacity,
    image,
    farmId,
    centerPoint,
    isActive,
  } = field || {};

  const { name: subCategoryName, category } = subCategory || {};

  const [fullImage, setFullImage] = useState(false);
  const toggle = () => setFullImage(!fullImage);

  const getCycleDateType = () => {
    let unit = '';
    productionCycleUnit.map((type) => {
      if (productionCycleUnitEnum === type.id) {
        unit = type.name;
        return unit;
      }
      return unit;
    });
    return unit;
  };

  const cultureTypes = [
    {
      img: Cultivate,
      type: 'Tipo de Cultivo',
      typeValue: category && category.id === CultureEnum.SEM_CULTURA ? category.name : subCategoryName,
    },
    {
      img: CultivationDateImg,
      type: category && (category.id === CultureEnum.GRAOS_FIBRAS || category.id === CultureEnum.HORTICOLAS)
        ? 'Data de Semeadura' : 'Início do monitoramento',
      typeValue: formatDateFromISO(sowingPlantingDate),
    },
    {
      img: ProductionImg,
      type: category && (category.id === CultureEnum.GRAOS_FIBRAS || category.id === CultureEnum.HORTICOLAS)
        ? 'Ciclo de Produção' : 'Tempo de monitoramento',
      typeValue: `${parseInt(productionCycle, 10)} ${getCycleDateType()}`,
    },
    {
      img: CycleImg,
      type: category && (category.id === CultureEnum.GRAOS_FIBRAS || category.id === CultureEnum.HORTICOLAS)
        ? 'Colheita' : 'Fim do monitoramento',
      typeValue: getHarvestDate(productionCycleUnitEnum, sowingPlantingDate, productionCycle),
    },
  ];

  return (
    <ContainerDetails>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/fields/${farmSelected}`}>
            Fazendas
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Detalhes</Breadcrumb.Item>
      </Breadcrumb>
      <Content>
        <ImageField>
          {image && <img src={image} alt="" onClick={toggle} />}
          {!image && <img src={ImageArea} alt="" />}
        </ImageField>
        <Details>
          <ContainerName>
            <Title>{name}</Title>
            <ContainerLocal>
              <ContainerFlex>
                <LocalField>
                  <IoMdPin size={30} />
                  {
                    farms && farms.map((farm) => {
                      if (farmId === farm.addressId) {
                        return `${farm.address.city.name}, ${farm.address.city.state.name}`;
                      }
                      return '';
                    })
                  }
                </LocalField>
              </ContainerFlex>
            </ContainerLocal>
          </ContainerName>
          <ContainerGrains>
            <ContainerFlex>
              <Title>{category && category.name}</Title>
            </ContainerFlex>
            <ContainerFlex>
              {cultureTypes.map((item) => (
                <CardCulture
                  key={item.type}
                  cardImg={item.img}
                  cultureType={item.type}
                  cultureValue={item.typeValue}
                />
              ))}
            </ContainerFlex>
          </ContainerGrains>

          <ContainerGrains>
            <ContainerFlex>
              <Title>Características do Solo</Title>
            </ContainerFlex>
            <SoilCharacteristics
              soilType={soilType}
              sandContent={sandContent}
              siltContent={siltContent}
              clayContent={clayContent}
              soilDensity={soilDensity}
              humidityFieldCapacity={humidityFieldCapacity}
              humidityFieldCapacityUnitEnum={humidityFieldCapacityUnitEnum}
              limitHumidityContent={limitHumidityContent}
              limitHumidityContentUnitEnum={limitHumidityContentUnitEnum}
              soilWaterCapacity={soilWaterCapacity}
            />
          </ContainerGrains>

          <ContainerGrains>
            <ClimateSection
              standardDeviation={standardDeviation}
              PrecAverage={PrecAverage}
              monthlyPrec={monthlyPrec}
              precipitationData={precipitationData}
              dailyPrecipitationForecastData={dailyPrecipitationForecastData}
              monthlyPrecipitationForecastData={monthlyPrecipitationForecastData}
              standardDeviationTempMax={standardDeviationTempMax}
              tempAverageMax={tempAverageMax}
              monthlyTempMax={monthlyTempMax}
              standardDeviationTempMin={standardDeviationTempMin}
              tempAverageMin={tempAverageMin}
              monthlyTempMin={monthlyTempMin}
              dailyTemperatureForecastData={dailyTemperatureForecastData}
              monthlyTemperatureForecastData={monthlyTemperatureForecastData}
              lastYearNdvi={lastYearNdviData}
              currentYearNdvi={currentYearNdviData}
              theoreticalNdvi={theoreticalCurveNdviData}
              pulverizationData={pulverizationData}
              arm={armData}
              hydricDataSize={armData.length}
              centerPoint={centerPoint}
              fieldId={fieldId}
              isActive={isActive}
              onLoadGlmImages={onFetchGlmImages}
              onLoadRrqpefImages={onFetchRrqpefImages}
              onLoadFireRiskImages={onFetchFireRiskImages}
              diseasesData={diseasesData}
              onLoadFocusFireImages={onFetchFocusFireImages}
            />
          </ContainerGrains>
        </Details>
      </Content>
      {fullImage && <FullImage toggle={toggle} fullImage={fullImage} image={image} />}
    </ContainerDetails>
  );
};

DetailsField.defaultProps = {
  farmSelected: null,
};

DetailsField.propTypes = {
  farmSelected: PropTypes.number,
  onFetchFieldById: PropTypes.func.isRequired,
  onFetchAccumulatedPrecipitation: PropTypes.func.isRequired,
  onFetchDailyPrecipitationForecast: PropTypes.func.isRequired,
  onFetchMonthlyPrecipitationForecast: PropTypes.func.isRequired,
  onFetchClimatePrecipitation: PropTypes.func.isRequired,
  onFetchMonthlyPrecipitation: PropTypes.func.isRequired,
  onFetchClimateTemperature: PropTypes.func.isRequired,
  onFetchMonthlyTemperature: PropTypes.func.isRequired,
  onFetchDailyTemperatureForecast: PropTypes.func.isRequired,
  onFetchMonthlyTemperatureForecast: PropTypes.func.isRequired,
  onFetchHydricBalance: PropTypes.func.isRequired,
  onFetchNdvi: PropTypes.func.isRequired,
  onFetchPulverization: PropTypes.func.isRequired,
  onFetchGlmImages: PropTypes.func.isRequired,
  onFetchRrqpefImages: PropTypes.func.isRequired,
  onFetchFireRiskImages: PropTypes.func.isRequired,
  onFetchDiseases: PropTypes.func.isRequired,
  onFetchFocusFireImages: PropTypes.func.isRequired,
  farms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (props) => {
  const { farm: { farms, farmSelected } } = props;
  return { farms, farmSelected };
};

const mapDispatchToProps = {
  onFetchFieldById: fetchFieldById,
  onFetchAccumulatedPrecipitation: fetchAccumulatedPrecipitation,
  onFetchDailyPrecipitationForecast: fetchDailyPrecipitationForecast,
  onFetchMonthlyPrecipitationForecast: fetchMonthlyPrecipitationForecast,
  onFetchClimatePrecipitation: fetchClimatePrecipitation,
  onFetchMonthlyPrecipitation: fetchMonthlyPrecipitation,
  onFetchClimateTemperature: fetchClimateTemperature,
  onFetchMonthlyTemperature: fetchMonthlyTemperature,
  onFetchDailyTemperatureForecast: fetchDailyTemperatureForecast,
  onFetchMonthlyTemperatureForecast: fetchMonthlyTemperatureForecast,
  onFetchHydricBalance: fetchHydricBalance,
  onFetchNdvi: fetchNdvi,
  onFetchPulverization: fetchPulverization,
  onFetchGlmImages: fetchGlmImages,
  onFetchRrqpefImages: fetchRrqpefImages,
  onFetchFireRiskImages: fetchFireRiskImages,
  onFetchDiseases: fetchDiseases,
  onFetchFocusFireImages: fetchFocusFireImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsField);
