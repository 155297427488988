import {
  getFarms, postFarm, putFarm, sendDeleteFarm,
} from '../../services/contexts/farm';

export const ActionTypes = {
  GET_FARMS: '@farm/GET_FARMS',
  SELECT_FARM: '@farm/SELECT_FARM',
};

export const fetchFarms = (farmFilter) => async (dispatch) => {
  const result = await getFarms(farmFilter);

  dispatch({
    type: ActionTypes.GET_FARMS,
    payload: {
      farms: result,
    },
  });
};

export const saveFarm = (farm) => async (dispatch) => {
  const result = await postFarm(farm);
  fetchFarms()(dispatch);
  return result;
};

export const updateFarm = (farmId, farm) => async (dispatch) => {
  const result = await putFarm(farmId, farm);
  fetchFarms()(dispatch);
  return result;
};

export const selectFarm = (farm) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SELECT_FARM,
    payload: {
      farmSelected: farm.id,
      farmLocation: farm.farmLocation,
    },
  });
};

export const deleteFarm = (farmId) => async () => {
  const result = await sendDeleteFarm(farmId);
  return result;
};
