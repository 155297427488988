const theme = {
  sizes: {
    container: '91.2rem',
    breakpoints: '96.0rem',
  },
  spacings: {
    xsmall: '0.8rem',
    small: '1.6rem',
    medium: '2.4rem',
    large: '3.2rem',
    xlarge: '4.0rem',
    xxlarge: '4.8rem',
    huge: '5.6rem',
    xhuge: '6.4rem',
  },
  colors: {
    primary: '#578687',
    background: '#FAFAFA',
    disabled: '#DFDFDF',
    white: '#FFFFFF',
    black: '#000000',
    darkGrey: '#303D31',
    grey: '#6C757D',
    lightGrey: '#DFDFDF',
    mediumGrey: '#ABADAD',
    darkBlue: '#0050B3',
    blue: '#0D6EFD',
    lightBlue: '#E6F7FF',
    disabledBlue: '#A3D3FF',
    hoverBlue: '#40A9FF',
    orange: '#FA541C',
    green: '#A2C667',
    selected: '#F4F4F4',
    danger: '#D4380D',
    hoverDanger: '#FF7875',
    notification: '#F5222D',
    warning: '#FAAD14',
    success: '#52C41A',
    error: '#D4380D',
    teal: '#20C997',
    inactiveGrey: '#D9D9D8',
    card: {
      selected: '#EEF3F3',
    },
    blueShades: {
      blue1: '#8AD7F8',
      blue2: '#6BCAF5',
      blue3: '#48BDF2',
      blue4: '#25AFEF',
      blue5: '#02A2ED',
      blue6: '#009BE7',
      blue7: '#0094E1',
    },
  },
  border: {
    colors: {
      regular: '#CED4DA',
      light: '#ECEFEA',
    },
    radius: {
      small: '0.2rem',
      default: '0.4rem',
      large: '0.8rem',
      huge: '3.7rem',
    },
  },
  transitions: {
    default: '0.3s ease-in-out',
    quick: '0.1s ease-in-out',
  },
  fonts: {
    family: {
      default: "'Open Sans', sans-serif",
    },
    weight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
    sizes: {
      xsmall: '0.875rem',
      small: '1.25rem',
      medium: '1.5rem',
      large: '2.0rem',
      xlarge: '2.4rem',
      xxlarge: '3.0rem',
      huge: '6.8rem',
    },
    lineHeight: {
      xsmall: '2rem',
      small: '2.2rem',
      medium: '2.4rem',
      large: '2.8rem',
      xlarge: '3.2rem',
      xxlarge: '3.8rem',
    },
    spacings: {
      xsmall: '0.8rem',
      small: '1.6rem',
      medium: '2.4rem',
      large: '3.2rem',
      xlarge: '4.0rem',
      xxlarge: '4.8rem',
      huge: '5.6rem',
      xhuge: '6.4rem',
    },
  },
};

export default theme;
