import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { updatePassword } from '../../../store/actions/user';
import NewPasswordForm from './components/form';

const NewPassword = (props) => {
  const { userId, onPassword } = props;
  const history = useHistory();

  const handlePasswordRecovery = async (password) => {
    const { newPassword } = password;
    const result = await onPassword(userId, newPassword);

    if (result) {
      history.push('/');
    } else {
      toast.error('Falha ao alterar a senha');
    }
  };

  return (
    <NewPasswordForm
      handlePasswordRecovery={handlePasswordRecovery}
    />
  );
};

NewPassword.propTypes = {
  userId: PropTypes.number.isRequired,
  onPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onPassword: updatePassword,
};

const mapStateToProps = (state) => ({
  userId: state.user.id,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NewPassword);
