import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  CardGround,
  CardImage,
  Name,
  Type,
  Content,
} from './styles';

const CardSoil = ({
  iconImg,
  typeText,
  typeRes,
}) => (
  <Container>
    <CardGround>
      <CardImage src={iconImg} alt="" />
      <Content>
        <Type>{typeText}</Type>
        <Name>{typeRes}</Name>
      </Content>
    </CardGround>
  </Container>
);

CardSoil.propTypes = {
  iconImg: PropTypes.string.isRequired,
  typeText: PropTypes.string.isRequired,
  typeRes: PropTypes.string.isRequired,
};

export default CardSoil;
