import React from 'react';
import { useField, useFormikContext } from 'formik';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import Label from '../../styles/label';
import FieldLabel from '../fieldLabel';

import {
  Container,
} from './styles';

const Dropdown = ({
  label,
  placeholder,
  onChange,
  validateOnSelect,
  isRequired,
  childrenFieldsToReset,
  options,
  onInputChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const formikContext = useFormikContext();

  const onFieldChange = (value) => {
    if (value && validateOnSelect && !validateOnSelect(value)) return;
    helpers.setValue(value);
    childrenFieldsToReset.map((fieldName) => formikContext.setFieldValue(fieldName, null));
  };

  const onValueChange = (value) => {
    onFieldChange(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Container>
      <FieldLabel
        htmlFor={field.name}
        labelText={label}
        required={isRequired}
      />
      <ReactSelect
        onChange={onValueChange}
        onFieldChange={onFieldChange}
        placeholder={placeholder}
        options={options}
        value={field.value}
        noOptionsMessage={() => 'Não há resultados...'}
        id={field.name}
        classNamePrefix="react-select"
        styles={{ container: (base) => ({ ...base }) }}
        {...props}
      />
      {(meta.error) && (
        <Label variant="danger">
          {meta.error}
        </Label>
      )}
    </Container>
  );
};

Dropdown.defaultProps = {
  onChange: null,
  validateOnSelect: null,
  isRequired: false,
  childrenFieldsToReset: [],
  placeholder: '',
  options: [],
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.oneOfType(PropTypes.func, PropTypes.object),
  placeholder: PropTypes.string,
  childrenFieldsToReset: PropTypes.arrayOf(PropTypes.string),
  validateOnSelect: PropTypes.func,
  isRequired: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
};

export default Dropdown;
