import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 7.5rem;
`;

export const Title = styled.h2`
  margin-top: 1.5rem;
  text-align: left;
  font-weight: bold;
  font-size: 2.25rem;
  color: #303D31;
`;

export const Subtitle = styled.h6`
  font-size: 1.4rem;
  font-weight: bold;
  color: #C7C7C7;
  margin-bottom: 1.6rem;
`;

export const ContainerGrid = styled.div`
  width: 100%;
  padding: 2.5rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  column-gap: 2rem;
  border: solid 1px #CED4DA;
  border-radius: 1rem;
`;

export const ContainerLabel = styled.div`
  text-align: left;
  margin-left: 1rem;
`;

export const Month = styled.div`
  font-weight: bold;
  font-size: 2rem;
  color: #8F908F;
  ::first-letter {
    text-transform: uppercase;
  }
`;

export const LabelPercentage = styled.h6`
  margin: 0.8rem 0.8rem 0.5rem 0rem;
  font-weight: bold;
  font-size: 2rem;
  color: #344E50;
`;

export const Label = styled.h6`
  font-weight: bold;
  font-size: 1.4rem;
  color: #344E50;
`;

export const ContainerIcon = styled.div`
  ${({ type }) => css`
    width: 9.5rem;
    height: 9rem;
    margin-left: 1rem;
    background-color: ${type === 'Precipitação' ? '#EAF5FF' : '#F7F7F7'};
    border-radius: 1rem;
    padding: ${type === 'Precipitação' ? '0.5rem' : '0rem'};
  `}
`;

export const Icon = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
`;

export const ContainerModule = styled.div`
  margin-top: 0.5rem;
`;

export const ContainerBar = styled.div`
${({ theme }) => css`
    width: 100%;
    height: 3rem;
    margin-bottom: 0.5rem;
    border-radius: 0.8rem;
    background-color: ${theme.colors.lightGrey};
  `}
`;

export const Bar = styled.div`
  ${({ theme, module }) => css`
    width: ${module > 0 ? module * 100 : 2}%;
    height: 100%;
    border-radius: 0.8rem;
    background-color: ${theme.colors.green};
  `}
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
