/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { Form, FormGroup } from 'reactstrap';

import {
  RegisterContent,
  TitleSelectArea,
  Container,
  ButtonsField,
  ButtonCancel,
  ButtonNext,
} from './styles';

import StepSelectArea from '../form/stepSelectArea';
import MapContainer from '../map';

const FormSelectArea = (props) => {
  const {
    handleSubmit,
    values,
    setFieldValue,
  } = props;

  const [showMap, setShowMap] = useState(false);
  const [mapImage, setMapImage] = useState('');

  if (showMap) {
    return (
      <Container>
        <MapContainer
          setShowMap={setShowMap}
          showMap={showMap}
          setFieldValue={setFieldValue}
          values={values}
          setMapImage={setMapImage}
        />
      </Container>
    );
  }

  return (
    <RegisterContent>
      <TitleSelectArea>SELECIONAR ÁREA</TitleSelectArea>
      <Form onSubmit={handleSubmit}>
        <StepSelectArea setShowMap={setShowMap} showMap={showMap} />
        <FormGroup>
          <ButtonsField>
            <h6>* Campo de preenchimento obrigatório</h6>
            <div>
              <Link to="/fields">
                <ButtonCancel>Cancelar</ButtonCancel>
              </Link>
              <Link to="/fields">
                <ButtonNext>Salvar</ButtonNext>
              </Link>
            </div>
          </ButtonsField>
        </FormGroup>
      </Form>
    </RegisterContent>
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('O campo nome é obrigatório'),
  coordinates: Yup.array().required('O campo adicionar polígono é obrigatório'),
});

FormSelectArea.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validationSchema,
  mapPropsToValues: () => ({
    name: '',
    cordinates: [],
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handleSaveField(values),
})(FormSelectArea);
