import api from '../config';

export const getFarms = async (farmFilter) => {
  try {
    const response = await api.get('/farms', {
      params: {
        search: farmFilter,
      },
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getFarmInfo = async () => {
  try {
    const response = await api.get('/farms/infos');
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const postFarm = async (farm) => {
  try {
    const response = await api.post('/farms', farm);
    if (response.status === 201) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const putFarm = async (farmId, farm) => {
  try {
    const response = await api.put(`/farms/${farmId}`, farm);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const sendDeleteFarm = async (farmId) => {
  try {
    const response = await api.delete(`/farms/${farmId}`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};
