import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Roboto Light'), local('Roboto-Light'),
        url('/fonts/Roboto/Roboto-Light.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto Regular'), local('Roboto-Regular'),
        url('/fonts/Roboto/Roboto-Regular.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Roboto';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('/fonts/Roboto/Roboto-Bold.ttf') format('ttf');
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      &::before,
      &::after {
        box-sizing: inherit;
      }
  }
  *,
  button,
  h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat';
  font-weight: bold;
}
  input {
    border: 0;
    outline: 0;
    font-family: 'Roboto', sans-serif;
  }
  html,
  body {
    font-size:72.5%;
    font-family: 'Roboto', sans-serif;
    ul {
      list-style-type: none;
    }
    a {
      text-decoration: none;
      color: inherit;
      :hover {
        color: inherit;
      }
    }
  }
`;

export default GlobalStyles;
