import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h2`
  margin-top: 6.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
  font-weight: bold;
  font-size: 2.25rem;
  color: #303D31;
`;

export const ContainerGrid = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  padding-right: 2rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  column-gap: 1rem;
  border: solid 1px #CED4DA;
  border-radius: 1rem;
`;

export const ContainerIcon = styled.div`
  width: 7rem;
  height: 7rem;
  margin-left: 1rem;
  margin-top: 0.4rem;
  padding: 1rem;
  align-items: center;
  background-color: ${({ type }) => (type === 'Precipitação' ? '#EAF5FF' : '#F7F7F7')};
  border-radius: 1rem;
`;

export const Icon = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
`;

export const InfoContainer = styled.div`
  color: #0D6EFD;
  margin-left: 1rem;
`;

export const ContainerLabel = styled.div`
  text-align: left;
`;

export const Subtitle = styled.h6`
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.4rem;
  color: #B7B7B7;
  ::first-letter {
    text-transform: uppercase;
  }
`;

export const LabelDate = styled.h6`
  margin-top: 1rem;
  font-weight: bold;
  font-size: 2.2rem;
  color: #202220;
`;

export const Label = styled.div`
  font-weight: bold;
  font-size: 1rem;
  color: #202220;
  ::first-letter {
    text-transform: uppercase;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LabelValue = styled(LabelDate)`
  margin-top: 0rem;
  font-size: 2rem;
`;
