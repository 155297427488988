import React from 'react';
import PropTypes from 'prop-types';

import Divider from '../../../../components/divider';

import * as S from './styles';

const Diseases = (props) => {
  const {
    diseasesData,
  } = props;

  if (diseasesData && diseasesData.diseases) {
    return (
      <S.Container>
        <S.Title>Favorabilidade climática à doenças</S.Title>
        <Divider />
        {(diseasesData.diseases.map((item) => (
          <S.Wrapper key={item.datetime}>
            <S.ContainerDisease>
              <S.DiseaseName>{item.name}</S.DiseaseName>
              <S.DiseaseValue value={item.value} />
            </S.ContainerDisease>
            <S.ContainerScale>
              <S.TextLengend>Escala de favorabilidade</S.TextLengend>
              <S.ContainerLegend>
                <S.ContainerFlex>
                  <S.Legend color="#00FF00" />
                  <S.TextLengend>Baixa</S.TextLengend>
                </S.ContainerFlex>
                <S.ContainerFlex>
                  <S.Legend color="#FFE200" />
                  <S.TextLengend>Média</S.TextLengend>
                </S.ContainerFlex>
                <S.ContainerFlex>
                  <S.Legend color="#FF0000" />
                  <S.TextLengend>Alta</S.TextLengend>
                </S.ContainerFlex>
              </S.ContainerLegend>
            </S.ContainerScale>
          </S.Wrapper>
        )))}
      </S.Container>
    );
  }
  return null;
};

Diseases.propTypes = {
  diseasesData: PropTypes.array.isRequired,
};

export default Diseases;
