import styled, { css } from 'styled-components';
import { Label } from 'reactstrap';

export const ContainerLabel = styled(Label)`
    ${({ theme }) => css`
      font-size: 1rem;
      color: ${theme.colors.darkGrey};
    & > span {
    color: ${theme.colors.green};
    }
  `} 
`;
