import { ActionTypes } from '../actions/farm';

const INITIAL_STATE = {
  farms: [],
  farmSelected: null,
  farmLocation: null,
};

const farmReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.GET_FARMS:
      return {
        ...state,
        farms: payload.farms,
      };
    case ActionTypes.SELECT_FARM:
      return {
        ...state,
        farmSelected: payload.farmSelected,
        farmLocation: payload.farmLocation,
      };
    default: return state;
  }
};

export default farmReducer;
