/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'reactstrap';

import Close from '../../../../assets/images/close.svg';

import {
  ButtonClose,
  ImageMap,
} from './styles';

const FullImage = (props) => {
  const {
    toggle,
    fullImage,
    image,
  } = props;

  return (
    <ImageMap>
      <Modal
        external={(
          <ButtonClose type="button" onClick={toggle}>
            <img src={Close} alt="Fechar" />
          </ButtonClose>
        )}
        isOpen={fullImage}
        toggle={toggle}
      >
        <img src={image} alt="" />
      </Modal>
    </ImageMap>
  );
};

export default FullImage;
