import styled from 'styled-components';

export const Title = styled.h6`
  font-weight: bold;
  font-size: 34px;
  text-align: left;
  color: #303D31;
  margin-left: 0px;
  margin-top: 40px;
  margin-bottom: 50px;

  @media (max-width: 375px) {
    font-size: 26px;
  }
`;

export const RegisterForm = styled.div`
  min-height: 480px;
  height: auto;
  margin-top: 20px;
  margin-left: 0px;
  font-size: 16px;
  background-color: #ffffff00;
  z-index: 1;
`;

export const RegistrationSteps = styled.div`
  display: flex;
  margin-bottom: 0px;
  justify-content: space-between;
  align-items: center;
`;

export const NameStepOne = styled.div`
  display: flex;
  margin-bottom: 0px;
  & > h6 {
    font-size: 14px;
    font-weight: bold;
    color: #A2C667;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  & > img {
    width: 14px;
    height: 11px;
    margin-top: 0px;
    margin-left: 20px;
    margin-bottom: 0px;
  }
`;

export const StepLine = styled.div`
  width: 30%;
  margin-bottom: 0px;
  & > h6 {
    font-size: 14px;
    font-weight: bold;
    color: #A2C667;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  & > hr {
    width: 100%;
    height: 10px;
    background-color: #A2C667;
    border-color: #A2C667;
    border-width: 0px;
    opacity: 0.25;
    margin-top: 10.5px;
  }
`;

export const StepOneCompleted = styled(StepLine)`
  & > hr {
    opacity: 1;
  }
`;

export const StepTwo = styled(StepLine)`
  & > h6 {
    width: 100px;
    color: #797B77;
  }
  & > hr {
    background-color: #797B77;
    border-color: #797B77;
  }
`;

export const StepTwoCurrent = styled(StepLine)``;

export const RegisterContent = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  margin-left: 0;
  padding: 2rem 3rem;
  border: 1px solid #CED4DA;
  border-radius: 0.875rem;
  background-color: #fff;
  z-index: 2;

  h4 {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #578687;
    font-weight: bold;
    margin-bottom: 2rem;
  }
`;

export const TitleSelectArea = styled.h6`
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
  color: #578687;
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const ContainerPolygon = styled.div`
  height: 177px;
  margin-top: 3px;
  margin-bottom: -8px;
  position: relative;
  z-index: 3;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const AddPolygon = styled.div`
  width: 262px;
  height: 48px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 4;

  @media (max-width: 1024px) {
    margin-left: 129px;
  }
  @media (max-width: 425px) {
    width: 150px;
    margin-left: 83px;
  }
  @media (max-width: 375px) {
    margin-left: 58px;
  }
  @media (max-width: 320px) {
    margin-left: 30px;
  }
`;

export const EditPolygon = styled.div`
  width: 38px;
  height: 38px;
  margin-top: -150px;
  margin-left: 91%;
  margin-bottom: 140px;
  position: relative;
  z-index: 4;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const ButtonAddMap = styled.button`
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #578687;
  background-color: #ffffff00;
  border: 1px solid #578687;
  border-radius: 4px;
`;

export const ButtonsFormField = styled.div`
  margin-top: 40px;
  & > h6 {
    width: 245px;
    height: 16px;
    margin-left: 0px;
    font-size: 14px;
    font-weight: normal;
  }
`;

export const ButtonsField = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > h6 {
    width: 277px;
    height: 16px;
    margin-top: 11px;
    font-size: 14px;
    font-weight: normal;
  }

  @media (max-width: 425px) {
    display: block;
  }
`;

export const ButtonCancel = styled.button`
  width: 138px;
  height: 38px;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #6C757D;
  background-color: #ffffff00;
  border: 1px solid #fff;
  border-radius: 4px;

  @media (max-width: 425px) {
    margin-top: 20px;
    margin-left: 37px;
  }
  @media (max-width: 375px) {
    width: 120px;
    margin-left: 25px;
  }
  @media (max-width: 320px) {
    width: 100px;
    margin-left: 10px;
  }
`;

export const ButtonNext = styled(ButtonCancel)`
  margin-right: 0px;
  color: #fff;
  background-color: #578687;
  border-color: #578687;
  text-align: center;
  :disabled {
    opacity: 0.4;
  }

  @media (max-width: 425px) {
    margin-left: 0px;
  }
  @media (max-width: 375px) {
    width: 120px;
  }
  @media (max-width: 320px) {
    width: 100px;
  }
`;

export const Container = styled.div`
  padding: 3rem 2rem 0 4.75rem;
`;
