import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DateTime } from 'luxon';
import _ from 'lodash';

import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
} from 'victory';

import { monthDayFormat } from '../../../../../helpers/date-helper';

import * as S from './styles';
import Divider from '../../../../../components/divider';
import ChartLegend from '../../../../../components/chartLegend';
import {
  chartLegendList,
  FORESCAT_DARK_GREEN,
  FORESCAT_GREEN,
  FORESCAT_LIGHT_GREEN,
  PRECIPITATION_COLOR,
} from '../../../../../util/chartLabelsAndColors';

const PrecipitationChart = (props) => {
  const {
    precipitationData,
  } = props;

  const [barData, setBarData] = useState([]);

  const getChartMinMaxY = () => {
    if (_.isEmpty(precipitationData)) {
      return [0, 20];
    }
    return [
      0,
      Math.round(_.maxBy(precipitationData, ((dataMin) => dataMin.y)).y + 20),
    ];
  };

  const getBarColor = ({ datum }) => {
    const { x, y } = datum;
    const currentDate = DateTime.fromISO(x).setZone('utc');
    const { values } = currentDate.diffNow('days');
    const { days } = values;

    if (days < -1.0 && days < 0.1) return PRECIPITATION_COLOR;
    if (y > 15) return FORESCAT_DARK_GREEN;
    if (y < 15 && y > 5) return FORESCAT_GREEN;
    if (y < 5) return FORESCAT_LIGHT_GREEN;

    return PRECIPITATION_COLOR;
  };

  const getBarValue = (data) => {
    const { x, y } = data;
    const currentDate = DateTime.fromISO(x).setZone('utc');
    const { values } = currentDate.diffNow('days');
    const { days } = values;

    if (days < -1.0 && days < 0.1) return y;

    if (y > 40) return 40;

    return y;
  };

  const getBarLabel = (data) => {
    const { datum } = data;
    const { x, y } = datum;
    const currentDate = DateTime.fromISO(x).setZone('utc');
    const { values } = currentDate.diffNow('days');
    const { days } = values;

    if (days < -1.0 && days < 0.1) return `${Math.round(y)}`;
    if (y > 15) return '> 15';
    if (y < 15 && y > 5) return '5-15';
    if (y < 5) return '<= 5';

    return `${Math.round(y)}`;
  };

  useEffect(() => {
    const arrData = [];

    precipitationData.forEach((data) => {
      arrData.push({
        x: data.x,
        y: getBarValue(data),
        realValue: data.y,
      });
    });

    setBarData(arrData);
  }, [precipitationData]);

  return (
    <S.Container>
      <S.Title>Precipitação</S.Title>
      <Divider />
      <VictoryChart
        width={800}
        domainPadding={15}
        padding={{
          left: 70, right: 60, bottom: 50, top: 10,
        }}
        domain={{
          y: getChartMinMaxY(),
        }}
      >
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: 10 },
            grid: { stroke: 'gray' },
            axisLabel: { fontSize: 12, padding: 42 },
          }}
          crossAxis={false}
          label="Precipitação diária (mm/dia)"
        />
        <VictoryAxis
          style={{
            axis: { stroke: 'none' },
            grid: { stroke: 'none' },
            tickLabels: { fontSize: 10 },
            axisLabel: { fontSize: 12, padding: 30 },
          }}
          label="Data"
          offsetY={50}
        />
        <VictoryBar
          style={{
            data: {
              fill: getBarColor,
              stroke: getBarColor,
              strokeWidth: 1,
              width: 24,
            },
          }}
          x={(data) => {
            const { x } = data;
            return monthDayFormat(x);
          }}
          y={({ y }) => y}
          data={barData}
          labels={(data) => getBarLabel(data)}
        />
      </VictoryChart>
      <ChartLegend items={chartLegendList.precipitation} />
    </S.Container>
  );
};

PrecipitationChart.propTypes = {
  precipitationData: PropTypes.array.isRequired,
};

export default PrecipitationChart;
