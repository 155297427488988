import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Layout as ALayout } from 'antd';
import BackgroundHeader from '../../assets/images/background-header.webp';

export const Mobile = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 4;
  @media (max-width: 440px) {
    display: block;
  }
`;

export const ContainerMobile = styled.div`
  width: 82%;
  height: 400px;
  padding: 30px;
  padding-top: 50px;
  margin-top: 15vw;
  margin-left: 9vw;
  border-radius: 3%;
  background-color: #fff;
  text-align: center;
  font-size: 18px;
  & > h3 {
    margin-top: 55px;
    margin-bottom: 50px;
    font-size: 26px;
    font-weight: bold;
  }
  & > h4 {
    font-size: 20px;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > a {
    text-decoration: none;
  }
`;

export const ButtonAndroid = styled.div`
  display: flex;
  width: 120px;
  height: 54px;
  padding: 5px;
  padding-left: 0px;
  padding-right: 8px;
  margin-top: 20px;
  font-weight: bold;
  color: #A2C667;
  background-color: #fff;
  border: 2px solid #A2C667;
  border-radius: 4px;
  text-align: center;
  line-height: 20px;
  flex-direction: row;
  justify-content: space-between;
  & > h6 {
    font-size: 14px;
    font-weight: bold;
  }
  & > img {
    width: 24px;
    height: 24px;
    margin-top: 7px;
    margin-left: 0px;
  }
`;

export const ButtonIos = styled(ButtonAndroid)`
  color: #000;
  border-color: #000;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Container = styled(ALayout)`
  display: block;
  max-height: 100vh;
  @media (max-width: 440px) {
    display: none;
  }
`;

export const LeftContainer = styled(ALayout.Sider)`
  && {
    ${({ theme }) => css`
      padding: 6rem 7rem;
      padding-bottom: 87px;
      border-radius: 20px;
      background-color: ${theme.colors.white};
      font-size: 16px;
      text-align: left;
    `}
    @media (max-width: 440px) {
      display: none;
    }
  }
`;

export const Title = styled.div`
  font-size: 2.65rem;
  font-weight: bold;
  text-align: center;
  color: #212529;
  margin-bottom: 5rem;
`;

export const TitleRegister = styled.div`
  ${({ theme }) => css`
    font-size: 2.1rem;
    color: ${theme.colors.black};
    font-weight: 500;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
  `} 
`;

export const SubtitleRegister = styled.div`
  ${({ theme }) => css`
    font-size: 1.4rem;
    color: ${theme.colors.black};
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
  `} 
`;

export const LinksContainer = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

export const ButtonsRegister = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const Label = styled.span`
  ${({ theme }) => css`
    font-size: 1.2rem;
    color: ${theme.colors.grey};
    font-weight: 500;
  `} 
`;

export const LinkLabel = styled(Link)`
  ${({ theme }) => css`
    font-size: 1.2rem;
    color: ${theme.colors.primary};
  `} 
`;

export const ButtonWrapper = styled.div`
  margin-top: 6rem;
`;

export const Layout = styled(ALayout)`
  height: 100vh;
`;

export const Content = styled(ALayout.Content)`
  && {
    background-image: url(${BackgroundHeader});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    padding: 10rem 6rem;
    max-width: 100vw;
    overflow-x: hidden;
    @media (max-width: 440px) {
      display: none;
    }
  }
`;
