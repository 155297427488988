import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import {
  Form,
  FormGroup,
} from 'reactstrap';
// Components
import { ButtonGreen } from '../../../../components/styles/button';
import FormField from '../../../../components/forms/formField';
import AboutContainer from '../../../../components/aboutContainer';

import Mobile from './mobile';

import * as S from '../../styles';

const LoginForm = (props) => {
  const {
    handleSubmit,
  } = props;

  return (
    <S.Layout>
      <Mobile />
      <S.Layout>
        <S.LeftContainer width={500}>
          <S.Title>Bem-vindo ao Weather Service</S.Title>
          <Form onSubmit={handleSubmit}>
            <S.Label>Entre com seu e-mail para começar</S.Label>
            <FormGroup>
              <FormField
                id="username"
                name="username"
                type="email"
                placeholder="Email"
              />
            </FormGroup>
            <FormGroup>
              <FormField
                id="password"
                name="password"
                type="password"
                placeholder="Senha"
              />
            </FormGroup>
            <S.ButtonWrapper>
              <FormGroup>
                <ButtonGreen type="submit">ENTRAR</ButtonGreen>
              </FormGroup>
            </S.ButtonWrapper>
            <FormGroup>
              <S.LinksContainer>
                <S.Label>
                  Não possui uma conta?
                  {' '}
                  <S.LinkLabel to="/register">
                    CADASTRE-SE.
                  </S.LinkLabel>
                </S.Label>
              </S.LinksContainer>
            </FormGroup>
            <FormGroup>
              <S.LinksContainer>
                <S.Label>
                  <S.LinkLabel to="/reset-password">
                    Esqueceu a senha?
                  </S.LinkLabel>
                </S.Label>
              </S.LinksContainer>
            </FormGroup>
          </Form>
        </S.LeftContainer>
        <S.Content>
          <AboutContainer />
        </S.Content>
      </S.Layout>
    </S.Layout>
  );
};

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .email('Email inválido')
    .required('O campo email é obrigatório'),
  password: Yup.string()
    .min(3, 'Senha muito curta')
    .required('O campo senha é obrigatório'),
});

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema,
  mapPropsToValues: () => ({
    username: '',
    password: '',
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handleAuthUser(values),
})(LoginForm);
