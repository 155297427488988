import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import { FormGroup } from 'reactstrap';

import {
  ButtonsField,
  ButtonCancel,
  ButtonNext,
} from './styles';

import SoilContent from '../../components/soil';

const Content = (props) => {
  const {
    handleSubmit,
    values,
    setFieldValue,
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <SoilContent values={values} setFieldValue={setFieldValue} />
      <FormGroup>
        <ButtonsField>
          <h6>* Campo de preenchimento obrigatório</h6>
          <div>
            <Link to="/fields">
              <ButtonCancel>Cancelar</ButtonCancel>
            </Link>
            <Link to="/fields">
              <ButtonNext>Salvar</ButtonNext>
            </Link>
          </div>
        </ButtonsField>
      </FormGroup>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  soilType: Yup.number().required('O campo tipo de solo é obrigatório')
    .typeError('The field should be a number')
    .positive()
    .integer()
    .nullable(),
});

Content.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validationSchema,
  mapPropsToValues: () => ({
    soilType: null,
    sandContent: null,
    siltContent: null,
    clayContent: null,
    soilDensity: null,
    humidityFieldCapacity: null,
    humidityFieldCapacityUnit: null,
    limitHumidityContent: null,
    limitHumidityContentUnit: null,
    soilWaterCapacity: null,
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handleSaveField(values),
})(Content);
