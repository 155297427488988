import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryAxis,
  VictoryArea,
  VictoryLine,
} from 'victory';

import { Container, Title } from './styles';

import Divider from '../../../../../components/divider';
import ChartLegend from '../../../../../components/chartLegend';
import {
  chartLegendList,
  CLIMATE_COLOR_STANDARD_DEVIATION_MAX_TEMPERATURE,
  CLIMATE_COLOR_MONTHLY_AVERAGE_MAX_TEMPERATURE,
  COLOR_LAST_MAX_TEMPERATURE,
  CLIMATE_COLOR_MONTHLY_AVERAGE_MIN_TEMPERATURE,
  CLIMATE_COLOR_STANDARD_DEVIATION_MIN_TEMPERATURE,
  COLOR_LAST_MIN_TEMPERATURE,
} from '../../../../../util/chartLabelsAndColors';

const TemperatureChart = (props) => {
  const {
    standardDeviationTempMax,
    tempAverageMax,
    monthlyTempMax,
    standardDeviationTempMin,
    tempAverageMin,
    monthlyTempMin,
  } = props;

  const getChartMinMaxY = useCallback(() => {
    let maxStandardDeviation = 10;
    let maxTempAverage = 10;
    let maxmonthlyTemp = 10;
    let minStandardDeviation = 0;
    let minTempAverage = 0;
    let minmonthlyTemp = 0;

    if (!_.isEmpty(standardDeviationTempMax)) {
      maxStandardDeviation = Math.round(_.maxBy(standardDeviationTempMax, ((dataMin) => dataMin.y)).y + 3);
    }

    if (!_.isEmpty(tempAverageMax)) {
      maxTempAverage = Math.round(_.maxBy(tempAverageMax, ((dataMin) => dataMin.y)).y + 3);
    }

    if (!_.isEmpty(monthlyTempMax)) {
      maxmonthlyTemp = Math.round(_.maxBy(monthlyTempMax, ((dataMin) => dataMin.y)).y + 3);
    }

    if (!_.isEmpty(standardDeviationTempMin)) {
      minStandardDeviation = Math.round(_.minBy(standardDeviationTempMin, ((dataMin) => dataMin.y)).y - 3);
    }

    if (!_.isEmpty(tempAverageMin)) {
      minTempAverage = Math.round(_.minBy(tempAverageMin, ((dataMin) => dataMin.y)).y - 3);
    }

    if (!_.isEmpty(monthlyTempMin)) {
      minmonthlyTemp = Math.round(_.minBy(monthlyTempMin, ((dataMin) => dataMin.y)).y - 3);
    }

    const lowerValue = _.minBy([minStandardDeviation, minTempAverage, minmonthlyTemp]);
    const higherValue = _.maxBy([maxStandardDeviation, maxTempAverage, maxmonthlyTemp]);
    return [
      lowerValue,
      higherValue,
    ];
  }, [standardDeviationTempMax, tempAverageMax, monthlyTempMax, standardDeviationTempMin, tempAverageMin, monthlyTempMin]);

  const labelVisible = (datum) => {
    if (
      datum.y && (datum.childName === 'chart-line-4'
      || datum.childName === 'chart-line-7')
    ) return `${datum.y.toFixed(2)} ºC`;

    if (
      datum.y && (datum.childName === 'chart-line-3'
      || datum.childName === 'chart-line-6'
      || datum.childName === 'chart-area-2'
      || datum.childName === 'chart-area-5')
    ) return `${datum.y.toFixed(2)} ºC/mês`;

    return null;
  };

  return (
    <Container>
      <Title>Temperatura</Title>
      <Divider />
      <VictoryChart
        width={800}
        padding={{
          left: 70, right: 60, bottom: 50, top: 10,
        }}
        domain={{
          y: getChartMinMaxY(),
        }}
        containerComponent={(
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => labelVisible(datum)}
            labelComponent={(
              <VictoryTooltip
                cornerRadius={2}
                flyoutWidth={80}
                flyoutStyle={{ fill: 'black', fillOpacity: 0.8 }}
              />
            )}
          />
        )}
      >
        <VictoryAxis
          style={{
            axis: { stroke: 'none' },
            grid: { stroke: 'gray' },
            tickLabels: { fontSize: 10 },
            axisLabel: { fontSize: 12, padding: 30 },
          }}
          label="Meses"
          offsetY={50}
        />
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: 10 },
            grid: { stroke: 'gray' },
            axisLabel: { fontSize: 12, padding: 42 },
          }}
          crossAxis={false}
          label="Temperatura (ºC/mês)"
        />
        <VictoryArea
          interpolation="linear"
          data={standardDeviationTempMax}
          style={{
            data: { fill: CLIMATE_COLOR_STANDARD_DEVIATION_MAX_TEMPERATURE, fillOpacity: 0.8 },
            labels: { fill: CLIMATE_COLOR_STANDARD_DEVIATION_MAX_TEMPERATURE, fontSize: 10 },
          }}
        />
        <VictoryLine
          data={tempAverageMax}
          style={{
            data: { stroke: CLIMATE_COLOR_MONTHLY_AVERAGE_MAX_TEMPERATURE },
            labels: { fill: CLIMATE_COLOR_MONTHLY_AVERAGE_MAX_TEMPERATURE, fontSize: 10 },
          }}
        />
        <VictoryLine
          data={monthlyTempMax}
          style={{
            data: { stroke: COLOR_LAST_MAX_TEMPERATURE },
            labels: { fill: COLOR_LAST_MAX_TEMPERATURE, fontSize: 10 },
          }}
        />
        <VictoryArea
          interpolation="linear"
          data={standardDeviationTempMin}
          style={{
            data: { fill: CLIMATE_COLOR_STANDARD_DEVIATION_MIN_TEMPERATURE, fillOpacity: 0.8 },
            labels: { fill: CLIMATE_COLOR_STANDARD_DEVIATION_MIN_TEMPERATURE, fontSize: 10 },
          }}
        />
        <VictoryLine
          data={tempAverageMin}
          style={{
            data: { stroke: CLIMATE_COLOR_MONTHLY_AVERAGE_MIN_TEMPERATURE },
            labels: { fill: CLIMATE_COLOR_MONTHLY_AVERAGE_MIN_TEMPERATURE, fontSize: 10 },
          }}
        />
        <VictoryLine
          data={monthlyTempMin}
          style={{
            data: { stroke: COLOR_LAST_MIN_TEMPERATURE },
            labels: { fill: COLOR_LAST_MIN_TEMPERATURE, fontSize: 10 },
          }}
        />
      </VictoryChart>
      <ChartLegend items={chartLegendList.temperature} />
    </Container>
  );
};

TemperatureChart.propTypes = {
  standardDeviationTempMax: PropTypes.array.isRequired,
  tempAverageMax: PropTypes.array.isRequired,
  monthlyTempMax: PropTypes.array.isRequired,
  standardDeviationTempMin: PropTypes.array.isRequired,
  tempAverageMin: PropTypes.array.isRequired,
  monthlyTempMin: PropTypes.array.isRequired,
};

export default TemperatureChart;
