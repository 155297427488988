import React from 'react';
import { useTable, useSortBy } from 'react-table';
import PropTypes from 'prop-types';
import CollapsingAccordion from '../collapsingAccordion';

import {
  TableContainer,
  Table,
  THead,
  TableBody,
  Tr,
  Th,
  Td,
} from './styles';

const BaseList = (props) => {
  const {
    columns,
    data,
    title,
    rowProps,
  } = props;

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  return (
    <TableContainer>
      <CollapsingAccordion title={title}>
        <Table {...getTableProps}>
          <THead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()} key={column.id}>
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>

          <TableBody>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps(rowProps)}
                  key={row.index}
                >
                  {row.cells.map((cell) => (
                    <Td>
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </TableBody>
        </Table>
      </CollapsingAccordion>
    </TableContainer>
  );
};

BaseList.defaultProps = {
  rowProps: () => ({}),
};

BaseList.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  rowProps: PropTypes.func,
};

export default BaseList;
