import React from 'react';
import PropTypes from 'prop-types';

// components
import ClimateHistoryChart from '../../components/charts/climate-history-chart';
import PrecipitationChart from '../../components/charts/precipitation-chart';
import IrrigationChart from '../../components/charts/irrigation-chart';
import TemperatureChart from '../../components/charts/temperature-chart';
import NdviChart from '../../components/charts/ndvi-chart';
import Pulverization from '../../components/pulverization';
import Diseases from '../../components/disease';

import Map from '../../components/staticMaps';
import ClimateHistorytable from '../../components/table/climate-history';
import TemperatureHistorytable from '../../components/table/temperature-history';
import DailyForecast from '../../components/forecast/daily';
import MonthlyForecast from '../../components/forecast/monthly';

import * as S from './styles';

const ClimateSection = (props) => {
  const {
    standardDeviation,
    PrecAverage,
    monthlyPrec,
    standardDeviationTempMax,
    tempAverageMax,
    monthlyTempMax,
    standardDeviationTempMin,
    tempAverageMin,
    monthlyTempMin,
    precipitationData,
    dailyPrecipitationForecastData,
    monthlyPrecipitationForecastData,
    dailyTemperatureForecastData,
    monthlyTemperatureForecastData,
    lastYearNdvi,
    currentYearNdvi,
    theoreticalNdvi,
    pulverizationData,
    arm,
    hydricDataSize,
    centerPoint,
    fieldId,
    onLoadGlmImages,
    onLoadRrqpefImages,
    onLoadFireRiskImages,
    diseasesData,
    onLoadFocusFireImages,
    isActive,
  } = props;

  return (
    <>
      {isActive && (
        <>
          <Map
            centerPoint={centerPoint}
            fieldId={fieldId}
            onLoadMapImages={onLoadRrqpefImages}
            title="Precipitação (mm/h)"
            legendInfos={[
              { id: 1, title: 'Sem chuva', color: '#FFFFFF' },
              { id: 2, title: 'Chuva fraca', color: '#01ff56' },
              { id: 3, title: 'Chuva moderada', color: '#ffff01' },
              { id: 4, title: 'Chuva forte', color: '#fe5701' },
              { id: 5, title: 'Tempestade', color: '#5e0404' },
            ]}
          />
          <Map
            centerPoint={centerPoint}
            fieldId={fieldId}
            onLoadMapImages={onLoadGlmImages}
            title="Descargas Atmosféricas (DA)"
            legendInfos={[
              { id: 1, title: 'Sem', color: '#FFFFFF' },
              { id: 2, title: 'Fraco', color: '#3333FF' },
              { id: 3, title: 'Moderado', color: '#33FF52' },
              { id: 4, title: 'Forte', color: '#FCFF33' },
              { id: 5, title: 'Intenso', color: '#FF3333' },
            ]}
          />
        </>
      )}
      <ClimateHistoryChart
        standardDeviation={standardDeviation}
        PrecAverage={PrecAverage}
        monthlyPrec={monthlyPrec}
      />
      <ClimateHistorytable
        standardDeviation={standardDeviation}
        precAverage={PrecAverage}
        monthlyPrec={monthlyPrec}
      />
      <PrecipitationChart precipitationData={precipitationData} />
      <DailyForecast dailyData={dailyPrecipitationForecastData} name="Precipitação" />
      <MonthlyForecast monthlyData={monthlyPrecipitationForecastData} name="Precipitação" />
      {isActive && (
        <IrrigationChart
          arm={arm}
          hydricDataSize={hydricDataSize}
        />
      )}
      <Pulverization pulverizationData={pulverizationData} />
      <TemperatureChart
        standardDeviationTempMax={standardDeviationTempMax}
        tempAverageMax={tempAverageMax}
        monthlyTempMax={monthlyTempMax}
        standardDeviationTempMin={standardDeviationTempMin}
        tempAverageMin={tempAverageMin}
        monthlyTempMin={monthlyTempMin}
      />
      <TemperatureHistorytable
        standardDeviationTempMax={standardDeviationTempMax}
        tempAverageMax={tempAverageMax}
        monthlyTempMax={monthlyTempMax}
        standardDeviationTempMin={standardDeviationTempMin}
        tempAverageMin={tempAverageMin}
        monthlyTempMin={monthlyTempMin}
      />
      <DailyForecast dailyData={dailyTemperatureForecastData} name="Temperatura" />
      <MonthlyForecast monthlyData={monthlyTemperatureForecastData} name="Temperatura" />
      <NdviChart
        lastYearNdvi={lastYearNdvi}
        currentYearNdvi={currentYearNdvi}
        theoreticalNdvi={theoreticalNdvi}
      />
      {isActive && (
        <>
          <Map
            centerPoint={centerPoint}
            fieldId={fieldId}
            onLoadMapImages={onLoadFireRiskImages}
            title="Risco de fogo"
            legendInfos={[
              { id: 1, title: 'Baixo', color: '#FFFFFF' },
              { id: 2, title: 'Médio Baixo', color: '#FFFF00' },
              { id: 3, title: 'Médio Alto', color: '#FFA500' },
              { id: 4, title: 'Risco Alto', color: '#FF0000' },
              { id: 5, title: 'Alto Risco', color: '#5C4033' },
            ]}
            isOneImage
          />
          <Map
            centerPoint={centerPoint}
            fieldId={fieldId}
            onLoadMapImages={onLoadFocusFireImages}
            title="Focos de incêndio nas últimas 3 horas"
            legendInfos={[
              { id: 1, title: '1', color: '#487DE7' },
              { id: 2, title: '5', color: '#0000FF' },
              { id: 3, title: '9', color: '#70369D' },
              { id: 4, title: '16', color: '#EE82EE' },
              { id: 5, title: '29', color: '#4B369D' },
              { id: 5, title: '52', color: '#4B0082' },
              { id: 5, title: '94', color: '#008000' },
              { id: 5, title: '141', color: '#FFFF00' },
              { id: 5, title: '170', color: '#FF0000' },
            ]}
            isOneImage
            showTime
          />
        </>
      )}
      <Diseases diseasesData={diseasesData} />
      {!isActive && (
        <>
          <S.InactiveMessage>
            Os produtos Umidade do Solo, Descargas Elétricas E Now Casting estão
            indisponíveis para talhões inativos.
          </S.InactiveMessage>
        </>
      )}
    </>
  );
};

ClimateSection.propTypes = {
  standardDeviation: PropTypes.array.isRequired,
  PrecAverage: PropTypes.array.isRequired,
  monthlyPrec: PropTypes.array.isRequired,
  standardDeviationTempMax: PropTypes.array.isRequired,
  tempAverageMax: PropTypes.array.isRequired,
  monthlyTempMax: PropTypes.array.isRequired,
  standardDeviationTempMin: PropTypes.array.isRequired,
  tempAverageMin: PropTypes.array.isRequired,
  monthlyTempMin: PropTypes.array.isRequired,
  precipitationData: PropTypes.array.isRequired,
  dailyPrecipitationForecastData: PropTypes.array.isRequired,
  monthlyPrecipitationForecastData: PropTypes.array.isRequired,
  dailyTemperatureForecastData: PropTypes.array.isRequired,
  monthlyTemperatureForecastData: PropTypes.array.isRequired,
  lastYearNdvi: PropTypes.array.isRequired,
  currentYearNdvi: PropTypes.array.isRequired,
  theoreticalNdvi: PropTypes.array.isRequired,
  pulverizationData: PropTypes.array.isRequired,
  arm: PropTypes.array.isRequired,
  hydricDataSize: PropTypes.number.isRequired,
  centerPoint: PropTypes.object.isRequired,
  fieldId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onLoadGlmImages: PropTypes.func.isRequired,
  onLoadRrqpefImages: PropTypes.func.isRequired,
  onLoadFireRiskImages: PropTypes.func.isRequired,
  diseasesData: PropTypes.func.isRequired,
  onLoadFocusFireImages: PropTypes.func.isRequired,
};

export default ClimateSection;
