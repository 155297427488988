import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import ManageButton from '../../manageButton';

import {
  Container,
  ContainerFlex,
  FieldsCount,
} from './styles';

const FarmCard = (props) => {
  const {
    farm,
    onClick,
    isSelected,
    onDeleteFarm,
  } = props;

  const {
    id,
    name,
    fieldsActive,
    fieldsInactive,
    address: {
      city: {
        name: cityName,
        state: {
          name: stateName,
        },
      },
    },
  } = farm;

  const { confirm } = Modal;

  const showDeleteFarm = () => {
    confirm({
      title: 'Deletar Fazenda?',
      icon: <ExclamationCircleOutlined />,
      content: 'Você tem certeza que deseja deletar essa fazenda?',
      okText: 'Deletar',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        await onDeleteFarm(id);
      },
      onCancel() { },
    });
  };

  return (
    <Container
      onClick={onClick}
      selected={isSelected}
    >
      <ContainerFlex>
        <h3>{name}</h3>
        <ManageButton
          onDelete={showDeleteFarm}
          name="Fazenda"
        />
      </ContainerFlex>
      <h5>{`${cityName}, ${stateName}`}</h5>
      <FieldsCount>
        <h4>
          {(fieldsActive === 0 || fieldsActive > 1)
            && `${fieldsActive} talhões ativos`}
          {fieldsActive === 1
            && `${fieldsActive} talhão ativo`}
        </h4>
        <h4>
          {(fieldsInactive === 0 || fieldsInactive > 1)
            && `${fieldsInactive} talhões inativos`}
          {fieldsInactive === 1
            && `${fieldsInactive} talhão inativo`}
        </h4>
      </FieldsCount>
    </Container>
  );
};

FarmCard.propTypes = {
  farm: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onDeleteFarm: PropTypes.func.isRequired,
};

export default FarmCard;
