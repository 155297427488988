import React from 'react';
import PropTypes from 'prop-types';
import CultureContent from '../culture';

const StepCulture = ({
  values, setFieldValue,
}) => (
  <div>
    <CultureContent values={values} setFieldValue={setFieldValue} />
  </div>
);

StepCulture.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default StepCulture;
