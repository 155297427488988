import React from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Container } from './styles';

const BackArrow = () => (
  <Container>
    <BsArrowLeft size={30} color="black" />
    <span>Voltar</span>
  </Container>
);

export default BackArrow;
