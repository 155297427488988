/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import BaseList from '../../../../../components/table';

import { Container } from './styles';
import { getLabelMonths } from '../../../../../helpers/getLabelMonths';

const ClimateHistorytable = (props) => {
  const {
    standardDeviation,
    precAverage,
    monthlyPrec,
  } = props;

  const [data, setData] = useState();

  const getMonthColumn = (item) => {
    if (!item) return '';
    return item.charAt(0).toUpperCase() + item.slice(1);
  };

  useEffect(() => {
    const precData = precAverage.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y.toFixed(2);
      return obj;
    }, {});

    const monthlyPrecData = monthlyPrec.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y.toFixed(2);
      return obj;
    }, {});

    const standardDeviationMinData = standardDeviation.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y0.toFixed(2);
      return obj;
    }, {});

    const standardDeviationMaxData = standardDeviation.reduce((obj, itm) => {
      const value = itm;
      obj[value.x.replace('.', '')] = value.y.toFixed(2);
      return obj;
    }, {});

    precData.type = 'Média mensal de 2001 a 2019';
    monthlyPrecData.type = 'Precipitação dos últimos 12 meses';
    standardDeviationMinData.type = 'Desvio padrão mínimo';
    standardDeviationMaxData.type = 'Desvio padrão máximo';

    setData([monthlyPrecData, standardDeviationMaxData, standardDeviationMinData, precData]);
  }, [precAverage, monthlyPrec, standardDeviation]);

  const months = getLabelMonths();
  const columns = useMemo(() => {
    const header = [{ Header: 'Tipo', accessor: 'type' }];
    months.forEach((p) => header.push(({ Header: getMonthColumn(p), accessor: p.replace('.', '') })));
    return header;
  }, []);

  if (data == null) return null;

  return (
    <Container>
      <BaseList
        columns={columns}
        data={data}
        title="Clique aqui para ver os dados de precipitação"
      />
    </Container>
  );
};

ClimateHistorytable.propTypes = {
  standardDeviation: PropTypes.array.isRequired,
  precAverage: PropTypes.array.isRequired,
  monthlyPrec: PropTypes.array.isRequired,
};

export default ClimateHistorytable;
