const productionCycleUnit = [
  { name: 'dias', id: 1 },
  { name: 'meses', id: 2 },
  { name: 'anos', id: 3 },
];

const soilTypeList = [
  { name: 'Arenoso', id: '1' },
  { name: 'Textura Média', id: '2' },
  { name: 'Argiloso', id: '3' },
];

const humidityFieldCapacityUnit = [
  { name: 'cm3 cm-3', id: '5' },
  { name: 'g g-1', id: '6' },
];

const limitHumidityContentUnit = [
  { name: 'cm3 cm-3', id: '5' },
  { name: 'g g-1', id: '6' },
];

export {
  productionCycleUnit,
  soilTypeList,
  humidityFieldCapacityUnit,
  limitHumidityContentUnit,
};
