import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import {
  Form,
  FormGroup,
} from 'reactstrap';

import { getStates, getCities } from '../../../../services/contexts/location';
import FormField from '../../../../components/forms/formField';
import Dropdown from '../../../../components/forms/dropdown';

import {
  Container,
  Title,
  ContainerRegister,
  ContainerFlex,
  ButtonWhite,
  ButtonGreen,
} from './styles';

const FarmForm = (props) => {
  const {
    handleSubmit,
  } = props;

  const history = useHistory();

  const [states, setStates] = useState([]);
  const [stateId, setState] = useState();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    async function LoadStates() {
      setStates(await getStates());
    }
    LoadStates();
  }, []);

  const handleStateChange = (e) => {
    setState(e.value);
  };

  useEffect(() => {
    async function LoadCities() {
      if (stateId) {
        setCities(await getCities(stateId));
      }
    }
    LoadCities();
  }, [stateId]);

  return (
    <Container>
      <Title>
        <h6>Cadastrar Fazenda</h6>
      </Title>
      <ContainerRegister>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <FormField
              id="name"
              name="name"
              label="Nome"
              placeholder="Nome"
              isRequired
            />
          </FormGroup>
          <FormGroup>
            <Dropdown
              name="state"
              label="Estado"
              placeholder="Selecione"
              isRequired
              childrenFieldsToReset={['cityId']}
              onChange={handleStateChange}
              options={states.map((p) => ({ value: p.id, label: p.name }))}
            />
          </FormGroup>
          <FormGroup>
            <Dropdown
              name="cityId"
              label="Município"
              placeholder="Município"
              isRequired
              cacheUniq={stateId}
              options={cities && cities.map((p) => ({ value: p.id, label: p.name }))}
            />
          </FormGroup>
          <FormGroup>
            <ContainerFlex>
              <ButtonWhite type="button" onClick={() => history.goBack()}>Cancelar</ButtonWhite>
              <ButtonGreen type="submit">Cadastrar</ButtonGreen>
            </ContainerFlex>
          </FormGroup>
        </Form>
      </ContainerRegister>
    </Container>
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('O campo nome é obrigatório'),
  state: Yup.object().required('O campo estado é obrigatório').nullable(),
  cityId: Yup.object().required('O campo cidade é obrigatório').nullable(),
});

FarmForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validationSchema,
  mapPropsToValues: () => ({
    name: '',
    state: null,
    cityId: null,
    neighborhood: ' ',
    street: ' ',
    number: ' ',
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handleSaveFarm(values),
})(FarmForm);
