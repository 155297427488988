/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Content,
  AvatarContainer,
  Initials,
} from './styles';

const Avatar = ({
  fullName,
}) => {
  const getInitials = () => {
    const userName = fullName.split(' ');
    if (userName !== undefined) {
      const firstName = userName.shift();
      const lastName = userName.pop();
      let initialFirstName = '';
      let initialLastName = '';
      if (firstName !== undefined) {
        initialFirstName = firstName.charAt(0);
      }
      if (lastName !== undefined) {
        initialLastName = lastName.charAt(0);
      }
      const initials = initialFirstName + initialLastName;

      return initials.toUpperCase();
    }
  };

  return (
    <AvatarContainer>
      <Content>
        <Initials>{getInitials(fullName)}</Initials>
      </Content>
    </AvatarContainer>
  );
};

Avatar.propTypes = {
  fullName: PropTypes.string.isRequired,
};

export default Avatar;
