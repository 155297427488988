import { DateTime } from 'luxon';
import _ from 'lodash';

const DEFAULT_FORMAT = 'dd/MM/yyyy';
const MONTH_DAY_FORMAT = 'dd/M';
const FORMAT_SUBMIT = 'dd-MM-yyyy';
const FORMAT_EDIT = 'yyyy-MM-dd';
const DEFAULT_FORMAT_TIME = 'dd/MM/yyyy HH:mm:ss';

export const formatDateFromISO = (dateISO) => DateTime.fromISO(dateISO).setZone('utc').toFormat(DEFAULT_FORMAT);
export const monthDayFormat = (stringDate) => (_.isEmpty(stringDate) ? '' : DateTime.fromISO(stringDate).setZone('utc').toFormat(MONTH_DAY_FORMAT));

export const formatToSubmit = (stringDate) => DateTime.fromISO(stringDate).toFormat(FORMAT_SUBMIT);
export const formatDateFromISOEdit = (dateISO) => DateTime.fromISO(dateISO).setZone('utc').toFormat(FORMAT_EDIT);

export const formatDateFromISODateImage = (dateISO) => DateTime.fromISO(dateISO).toFormat(DEFAULT_FORMAT_TIME);

export const defaultDate = () => DateTime.now().toISO();

export const defaultDateFormatOrDefaultDate = (stringDate) => {
  if (_.isEmpty(stringDate)) return DateTime.now().toFormat(DEFAULT_FORMAT);

  const date = DateTime.fromISO(stringDate, { zone: 'utc' });
  return date.toFormat(DEFAULT_FORMAT);
};

export const monthName = (dateTime) => DateTime.fromISO(dateTime, { locale: 'pt-br' }).setZone('utc').toLocaleString({ month: 'long' });

export const addDays = (date, days) => DateTime
  .fromISO(date)
  .plus({ days })
  .toFormat(DEFAULT_FORMAT);

export const addMonths = (date, months) => DateTime
  .fromISO(date)
  .plus({ months })
  .toFormat(DEFAULT_FORMAT);

export const addYears = (date, years) => DateTime
  .fromISO(date)
  .plus({ years })
  .setZone('utc')
  .toFormat(DEFAULT_FORMAT);

export const getHarvestDate = (productionCycleUnitEnum, sowingPlantingDate, productionCycle) => {
  if (productionCycleUnitEnum === 1 || productionCycleUnitEnum === '1') {
    return addDays(sowingPlantingDate, parseInt(productionCycle, 10));
  }
  if (productionCycleUnitEnum === 2 || productionCycleUnitEnum === '2') {
    return addMonths(sowingPlantingDate, parseInt(productionCycle, 10));
  }
  if (productionCycleUnitEnum === 3 || productionCycleUnitEnum === '3') {
    return addYears(sowingPlantingDate, parseInt(productionCycle, 10));
  }
  return '';
};

export const getDateFromImage = (path) => {
  const pathSlices = path.split('/');

  if (pathSlices.length > 0) {
    const imageName = pathSlices[pathSlices.length - 1];
    const imageNameSlices = imageName.split('.');

    if (imageNameSlices.length > 0) {
      const imageDate = imageNameSlices[0];
      const date = DateTime.fromFormat(imageDate, 'yyyyMMdd_HHmmss');
      return date;
    }
  }

  return DateTime.now();
};

export const minDate = DateTime.now().plus({ days: -60 }).toFormat('yyyy-MM-dd');

export const pulverizationDate = (date) => {
  const dateTime = DateTime.fromISO(date).setZone('utc').toFormat('dd/MM H');
  return `${dateTime}h`;
};
