import React from 'react';
import PropTypes from 'prop-types';
import { AiFillSetting, AiOutlineMore } from 'react-icons/ai';
import { TbFileOff } from 'react-icons/tb';
import { MdDeleteOutline } from 'react-icons/md';
import { Menu } from 'antd';
import * as S from './styles';

const ManageButton = ({
  hasDisable, onDisable, onDelete, name,
}) => {
  const disableMenu = {
    label: <a onClick={onDisable}>Desativar Talhão</a>,
    key: '0',
    icon: <TbFileOff size={15} color="#6C757D" />,
  };

  const divider = {
    type: 'divider',
  };

  const deleteLabel = (
    <a onClick={onDelete}>
      Deletar
      {' '}
      {name}
    </a>
  );

  const deleteMenu = {
    label: deleteLabel,
    key: '1',
    icon: <MdDeleteOutline size={20} color="#DC3545" />,
  };

  const menuItemsWithDisable = [disableMenu, divider, deleteMenu];
  const menuItemsWithoutDisable = [deleteMenu];

  const menu = (
    <Menu
      items={hasDisable ? menuItemsWithDisable : menuItemsWithoutDisable}
    />
  );
  return name === 'Talhão' ? (
    <S.Wrapper overlay={menu}>
      <a onClick={(e) => e.preventDefault()}>
        <AiFillSetting size={30} color="#F4F4F4" />
      </a>
    </S.Wrapper>
  ) : (
    <S.WrapperFarm overlay={menu}>
      <a onClick={(e) => e.preventDefault()}>
        <AiOutlineMore size={20} color="#314B4D" />
      </a>
    </S.WrapperFarm>
  );
};

ManageButton.defaultProps = {
  onDisable: null,
};

ManageButton.propTypes = {
  hasDisable: PropTypes.bool.isRequired,
  onDisable: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default ManageButton;
