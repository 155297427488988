import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Menu } from 'antd';
import { FaInfoCircle } from 'react-icons/fa';
import ImageSelectArea from '../../../../../assets/images/select-area2.svg';
import Cultivate from '../../../../../assets/images/cultivate.svg';
import DateCultivate from '../../../../../assets/images/date.svg';
import Cycle from '../../../../../assets/images/cycle.svg';
import RainyDays from '../../../../../assets/images/rainy-days.svg';
import AccumulatedRain from '../../../../../assets/images/accumulated-rain.svg';
import CurrentSoilMoisture from '../../../../../assets/images/current-soil-moisture.svg';
import DailyPrec5mm from '../../../../../assets/images/one_water_drop.png';
import MaxTemperatureIcon from '../../../../../assets/images/maximum-temperature.svg';
import MinTemperatureIcon from '../../../../../assets/images/minimum-temperature.svg';
import { formatDateFromISO, getHarvestDate } from '../../../../../helpers/date-helper';
import { getPrecInfo } from '../../../../../helpers/getPrecInfo';

import {
  Container,
  CardContainer,
  ContainerFlex,
  Image,
  LegendName,
  InformationRow,
  InformationContainer,
  HarvestDate,
  ForecastContainer,
  ContainerDropdown,
} from './styles';
import ManageButton from '../../../../../components/manageButton';
import { CultureEnum } from '../../../../../common/enum/cultureEnum';

const FieldCard = (props) => {
  const history = useHistory();

  const { confirm } = Modal;
  const {
    field,
    onDeleteField,
    onInactiveField,
  } = props;

  const {
    id,
    name,
    subCategory,
    sowingPlantingDate,
    productionCycle,
    productionCycleUnitEnum,
    precDays,
    acumPrec,
    currentArm,
    acumPrecCurrent,
    currentTemperature,
    image,
    isActive,
  } = field;

  const { name: subCategoryName, category } = subCategory;
  const { icon: iconPrecCurrent, label: labelPrecCurrent } = getPrecInfo(acumPrecCurrent ? acumPrecCurrent.value : null);

  const showDisableField = () => {
    confirm({
      title: 'Desativar esse talhão?',
      icon: <ExclamationCircleOutlined />,
      content: 'Você tem certeza que deseja desativar esse talhão?',
      okText: 'Desativar',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        await onInactiveField(id);
      },
      onCancel() { },
    });
  };

  const showDeleteField = () => {
    confirm({
      title: 'Deletar Talhão?',
      icon: <ExclamationCircleOutlined />,
      content: 'Você tem certeza que deseja deletar esse talhão?',
      okText: 'Deletar',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        await onDeleteField(id);
      },
      onCancel() { },
    });
  };

  const menuItems = [
    {
      label: <LegendName>Cultura</LegendName>,
      key: '0',
      icon: <Image src={Cultivate} alt="" title="Cultura" />,
    },
    {
      label: <LegendName>Início do monitoramento / Semeadura</LegendName>,
      key: '1',
      icon: <Image src={DateCultivate} alt="" title="CulInício do monitoramento / Semeaduratura" />,
    },
    {
      label: <LegendName>Fim do monitoramento / Previsão colheita</LegendName>,
      key: '2',
      icon: <Image src={Cycle} alt="" title="Fim do monitoramento / Previsão colheita" />,
    },
    {
      label: <LegendName>Dias chuvosos</LegendName>,
      key: '3',
      icon: <Image src={RainyDays} alt="" title="Dias chuvosos" />,
    },
    {
      label: <LegendName>Chuva acumulada</LegendName>,
      key: '4',
      icon: <Image src={AccumulatedRain} alt="" title="Chuva acumulada" />,
    },
    {
      label: <LegendName>Umidade atual do solo</LegendName>,
      key: '5',
      icon: <Image src={CurrentSoilMoisture} alt="" title="Umidade atual do solo" />,
    },
    {
      label: <LegendName>Chuva</LegendName>,
      key: '6',
      icon: <Image src={DailyPrec5mm} alt="" title="Chuva" />,
    },
    {
      label: <LegendName>Temperatura mínima</LegendName>,
      key: '7',
      icon: <Image src={MinTemperatureIcon} alt="" title="Temperatura mínima" />,
    },
    {
      label: <LegendName>Temperatura máxima</LegendName>,
      key: '8',
      icon: <Image src={MaxTemperatureIcon} alt="" title="Temperatura máxima" />,
    },
  ];

  return (
    <Container md={6}>
      <CardContainer>
        <ManageButton
          hasDisable={isActive}
          onDisable={showDisableField}
          onDelete={showDeleteField}
          name="Talhão"
        />
        <img src={image || ImageSelectArea} alt="" onClick={() => history.push(`/field/details/${id}`)} />
        <CardContainer.Body>
          <Link to={`/field/details/${id}`}>
            <ContainerFlex>
              <CardContainer.Title>{name}</CardContainer.Title>
              <ContainerDropdown overlay={<Menu items={menuItems} />}>
                <FaInfoCircle size={18} color="#808080" />
              </ContainerDropdown>
            </ContainerFlex>
            <InformationRow>
              <InformationContainer>
                <img src={Cultivate} alt="" title="Cultura" />
                <h6>{category.id !== CultureEnum.SEM_CULTURA ? subCategoryName : category.name}</h6>
              </InformationContainer>
              <InformationContainer>
                <img
                  src={DateCultivate}
                  alt=""
                  title={
                    (category.id === CultureEnum.GRAOS_FIBRAS || category.id === CultureEnum.HORTICOLAS)
                      ? 'Data de Semeadura' : 'Início do monitoramento'
                  }
                />
                <h6>{formatDateFromISO(sowingPlantingDate)}</h6>
              </InformationContainer>
              <InformationContainer>
                <img
                  src={Cycle}
                  alt=""
                  title={
                    (category.id === CultureEnum.GRAOS_FIBRAS || category.id === CultureEnum.HORTICOLAS)
                      ? 'Previsão colheita' : 'Fim do monitoramento'
                  }
                />
                <HarvestDate>{getHarvestDate(productionCycleUnitEnum, sowingPlantingDate, productionCycle)}</HarvestDate>
              </InformationContainer>
            </InformationRow>
            <InformationRow>
              <InformationContainer>
                <img src={RainyDays} alt="" title="Dias chuvosos" />
                {precDays && <h6>{precDays.count}</h6>}
              </InformationContainer>
              <InformationContainer>
                <img src={AccumulatedRain} alt="" title="Chuva acumulada" style={{ width: '35px', height: '35px' }} />
                {acumPrec && (<h6>{`${acumPrec} mm`}</h6>)}
              </InformationContainer>
              <InformationContainer>
                <img src={CurrentSoilMoisture} title="Umidade atual do solo" alt="" />
                {currentArm && <h6>{`${currentArm.armPercentage} %`}</h6>}
              </InformationContainer>
            </InformationRow>
            <CardContainer.Title>Previsão do tempo hoje</CardContainer.Title>
            <InformationRow>
              {acumPrecCurrent && (
                <ForecastContainer type="prec">
                  <img src={iconPrecCurrent} alt="" title="Chuva" />
                  <h6>{labelPrecCurrent}</h6>
                </ForecastContainer>
              )}
              <ForecastContainer type="temp">
                <img src={MinTemperatureIcon} alt="" title="Temperatura mínima" />
                {currentTemperature && <h6>{`${currentTemperature.tmin} ºC`}</h6>}
              </ForecastContainer>
              <ForecastContainer type="temp">
                <img src={MaxTemperatureIcon} alt="" title="Temperatura máxima" />
                {currentTemperature && <h6>{`${currentTemperature.tmax} ºC`}</h6>}
              </ForecastContainer>
            </InformationRow>
          </Link>
        </CardContainer.Body>
      </CardContainer>
    </Container>
  );
};

FieldCard.propTypes = {
  field: PropTypes.shape().isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onInactiveField: PropTypes.func.isRequired,
};

export default FieldCard;
