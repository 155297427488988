import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.25rem;
`;

export const Title = styled.h2`
  text-align: left;
  font-weight: bold;
  font-size: 2.25rem;
  color: #303D31;
  margin-bottom: 1.5rem;
  margin-top: 6.5rem;
`;

export const ContainerLegend = styled.div`
  margin-bottom: 3.125rem;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-right: 2rem;
`;

export const Legend = styled.div`
  display: flex;
`;

export const ColorLegend = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 4px;
  border: 1px solid ${(props) => props.color};
  background-color: ${(props) => props.color};
`;

export const TextLengend = styled.div`
  margin-left: 1.25rem;
  font-size: 1rem;
`;
