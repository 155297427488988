import React from 'react';
import PropTypes from 'prop-types';
import {
  CardGrain,
  TypeName,
  Category,
  Content,
  CultureImg,
} from './styles';

const CardCulture = ({
  cardImg,
  cultureType,
  cultureValue,
}) => (
  <CardGrain>
    <Content>
      <CultureImg src={cardImg} alt="" />
      <TypeName>{cultureType}</TypeName>
      <Category>{cultureValue}</Category>
    </Content>
  </CardGrain>
);

CardCulture.propTypes = {
  cardImg: PropTypes.string.isRequired,
  cultureType: PropTypes.string.isRequired,
  cultureValue: PropTypes.string.isRequired,
};

export default CardCulture;
