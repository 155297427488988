export const zoom = 10;

export const mapCenter = { lat: -23.5816183, lng: -46.637221 };

export const polygonDrawingOptions = {
  fillColor: '#20C997',
  fillOpacity: 0.5,
  strokeColor: '#20C997',
  strokeOpacity: 0.8,
  strokeWeight: 5,
  clickable: false,
  draggable: true,
  editable: true,
  geodesic: false,
  zIndex: 1,
};

export const polygonOptions = {
  fillColor: 'rgba(150,196,199,0.2)',
  fillOpacity: 1,
  strokeColor: '#fff',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
