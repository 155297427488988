import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryAxis,
  VictoryArea,
  VictoryLine,
} from 'victory';

import { Container, Title } from './styles';

import Divider from '../../../../../components/divider';
import ChartLegend from '../../../../../components/chartLegend';
import {
  chartLegendList,
  CLIMATE_COLOR_MONTHLY_AVERAGE,
  CLIMATE_COLOR_STANDARD_DEVIATION,
  COLOR_LAST_PRECIPITATION,
} from '../../../../../util/chartLabelsAndColors';

const ClimateHistoryChart = (props) => {
  const {
    standardDeviation,
    PrecAverage,
    monthlyPrec,
  } = props;

  const getChartMinMaxY = useCallback(() => {
    let maxStandardDeviation = 20;
    let maxPrecAverage = 20;
    let maxMonthlyPrec = 20;

    if (!_.isEmpty(standardDeviation)) {
      maxStandardDeviation = Math.round(_.maxBy(standardDeviation, ((dataMin) => dataMin.y)).y + 20);
    }

    if (!_.isEmpty(PrecAverage)) {
      maxPrecAverage = Math.round(_.maxBy(PrecAverage, ((dataMin) => dataMin.y)).y + 20);
    }

    if (!_.isEmpty(monthlyPrec)) {
      maxMonthlyPrec = Math.round(_.maxBy(monthlyPrec, ((dataMin) => dataMin.y)).y + 20);
    }

    const higherValue = _.maxBy([maxStandardDeviation, maxPrecAverage, maxMonthlyPrec]);
    return [
      0,
      higherValue,
    ];
  }, [standardDeviation, PrecAverage, monthlyPrec]);

  const labelVisible = (datum) => {
    if (datum.y && datum.childName === 'chart-line-4') return `${datum.y.toFixed(2)} mm`;
    if (datum.y && (datum.childName === 'chart-line-3' || datum.childName === 'chart-area-2')) return `${datum.y.toFixed(2)} mm/mês`;
    return null;
  };

  return (
    <Container>
      <Title>Condições de Tempo e Clima</Title>
      <Divider />
      <VictoryChart
        width={800}
        padding={{
          left: 70, right: 60, bottom: 50, top: 10,
        }}
        domain={{
          y: getChartMinMaxY(),
        }}
        containerComponent={(
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => labelVisible(datum)}
            labelComponent={(
              <VictoryTooltip
                cornerRadius={2}
                flyoutWidth={95}
                flyoutStyle={{ fill: 'black', fillOpacity: 0.8 }}
              />
            )}
          />
        )}
      >
        <VictoryAxis
          style={{
            axis: { stroke: 'none' },
            grid: { stroke: 'gray' },
            tickLabels: { fontSize: 10 },
            axisLabel: { fontSize: 12, padding: 30 },
          }}
          label="Meses"
          offsetY={50}
        />
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: 10 },
            grid: { stroke: 'gray' },
            axisLabel: { fontSize: 12, padding: 42 },
          }}
          crossAxis={false}
          label="Precipitação mensal (mm/mês)"
        />
        <VictoryArea
          interpolation="linear"
          data={standardDeviation}
          style={{
            data: { fill: CLIMATE_COLOR_STANDARD_DEVIATION, fillOpacity: 0.8 },
            labels: { fill: CLIMATE_COLOR_STANDARD_DEVIATION, fontSize: 10 },
          }}
        />
        <VictoryLine
          data={PrecAverage}
          style={{
            data: { stroke: CLIMATE_COLOR_MONTHLY_AVERAGE },
            labels: { fill: CLIMATE_COLOR_MONTHLY_AVERAGE, fontSize: 10 },
          }}
        />
        <VictoryLine
          data={monthlyPrec}
          style={{
            data: { stroke: COLOR_LAST_PRECIPITATION },
            labels: { fill: COLOR_LAST_PRECIPITATION, fontSize: 10 },
          }}
        />
      </VictoryChart>
      <ChartLegend items={chartLegendList.climate} />
    </Container>
  );
};

ClimateHistoryChart.propTypes = {
  standardDeviation: PropTypes.array.isRequired,
  PrecAverage: PropTypes.array.isRequired,
  monthlyPrec: PropTypes.array.isRequired,
};

export default ClimateHistoryChart;
