import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { monthName } from '../../../../../helpers/date-helper';
import Divider from '../../../../../components/divider';

import RainyArrowUp from '../../../../../assets/images/rainy_arrow_up.svg';
import RainyArrowDown from '../../../../../assets/images/rainy_arrow_down.svg';
import RainyEqual from '../../../../../assets/images/rainy_equal.svg';
import ThermometerArrowUp from '../../../../../assets/images/temp_high.png';
import ThermometerArrowDown from '../../../../../assets/images/temp_low.png';
import ThermometerEqual from '../../../../../assets/images/temp_same.png';

import * as S from './styles';

const MonthlyForecast = (props) => {
  const {
    monthlyData,
    name,
  } = props;

  const [data, setData] = useState([]);

  const AboveAverage = name === 'Precipitação' ? RainyArrowUp : ThermometerArrowUp;
  const BelowAverage = name === 'Precipitação' ? RainyArrowDown : ThermometerArrowDown;
  const EqualToAverage = name === 'Precipitação' ? RainyEqual : ThermometerEqual;

  useEffect(() => {
    const items = [];

    monthlyData.forEach((item) => {
      const {
        dateTime, mean, module,
      } = item;

      if (mean > 0) {
        items.push({
          month: monthName(dateTime),
          mean,
          module,
          label: 'Acima do esperado',
          icon: AboveAverage,
        });
      }
      if (mean < 0) {
        items.push({
          month: monthName(dateTime),
          mean,
          module,
          label: 'Abaixo do esperado',
          icon: BelowAverage,
        });
      }
      if (mean === 0) {
        items.push({
          month: monthName(dateTime),
          mean,
          module,
          label: 'Igual ao esperado',
          icon: EqualToAverage,
        });
      }

      return items;
    });

    setData(items);
  }, [monthlyData]);

  return (
    <S.Container>
      <S.Title>Tendência para os próximos meses</S.Title>
      <S.Subtitle>{name}</S.Subtitle>
      <Divider />
      {data && data.map((item) => (
        <S.ContainerGrid key={item.month}>
          <S.ContainerLabel>
            <S.Month>{item.month}</S.Month>
            <S.LabelPercentage>
              {item.mean > 0 && '+'}
              {item.mean}
              %
            </S.LabelPercentage>
            <S.Label>{item.label}</S.Label>
          </S.ContainerLabel>
          <S.ContainerIcon type={name}>
            <S.Icon src={item.icon} />
          </S.ContainerIcon>
          <S.ContainerModule>
            <S.Label style={{ textAlign: 'center' }}>Índice de confiança</S.Label>
            <S.ContainerBar>
              <S.Bar module={item.module <= 1 ? item.module : 1} />
            </S.ContainerBar>
            <S.ContainerFlex>
              <S.Label>Pouco confiável</S.Label>
              <S.Label>Muito confiável</S.Label>
            </S.ContainerFlex>
          </S.ContainerModule>
        </S.ContainerGrid>
      ))}
    </S.Container>
  );
};

MonthlyForecast.propTypes = {
  monthlyData: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default MonthlyForecast;
