import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Form from './content';
import { updateField } from '../../../../store/actions/field';

import {
  Title,
  FormCulture,
  ContainerCulture,
} from './styles';

const EditSoil = (props) => {
  const { onUpdateField } = props;

  const handleSaveField = async (values) => {
    const field = {
      soilType: values.soilType,
      sandContent: values.sandContent,
      siltContent: values.siltContent,
      clayContent: values.clayContent,
      soilDensity: values.soilDensity,
      humidityFieldCapacity: values.humidityFieldCapacity,
      humidityFieldCapacityUnit: values.humidityFieldCapacityUnit,
      limitHumidityContent: values.limitHumidityContent,
      limitHumidityContentUnit: values.limitHumidityContentUnit,
      soilWaterCapacity: values.soilWaterCapacity,
    };
    const result = await onUpdateField(field);
    if (result) {
      toast.success('Talhão editado com sucesso');
      return;
    }
    toast.error('Falha ao editar talhão');
  };

  return (
    <FormCulture>
      <Title>Editar Talhão</Title>
      <ContainerCulture>
        <Form handleSaveField={handleSaveField} />
      </ContainerCulture>
    </FormCulture>
  );
};

EditSoil.propTypes = {
  onUpdateField: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onUpdateField: updateField,
};

export default connect(null, mapDispatchToProps)(EditSoil);
