import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Checkbox,
  LabelCheckbox,
  LabelLink,
} from './styles';

const CheckBox = (props) => {
  const {
    label,
    labelLink,
    handleClick,
    openLink,
  } = props;
  return (
    <Container>
      <Checkbox
        onClick={handleClick}
        type="checkbox"
      />
      <LabelCheckbox>
        {label}
      </LabelCheckbox>
      {labelLink !== '' && <LabelLink onClick={openLink}>{labelLink}</LabelLink>}
    </Container>
  );
};

CheckBox.defaultProps = {
  handleClick: null,
  openLink: null,
  labelLink: '',
};

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  openLink: PropTypes.func,
  labelLink: PropTypes.string,
};

export default CheckBox;
