/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import * as S from './styles';
// Actions
import { fetchFields, deleteField, inactiveField } from '../../../store/actions/field';
import { fetchFarms } from '../../../store/actions/farm';
// Assets
import IconPlus from '../../../assets/images/plus.svg';
import noFieldIMG from '../../../assets/images/noField.svg';

// Components
import FieldCard from './components/card';
import SwitchField from '../../../components/forms/switch';

const ListFields = (props) => {
  const {
    onFetchFields,
    onFetchFarms,
    onDeleteField,
    onInactiveField,
  } = props;

  const { id: farmId } = useParams();

  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inactiveFields, setInactiveFields] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const fieldData = await onFetchFields(farmId, !inactiveFields);
    setLoading(false);
    setFields(fieldData);
  };

  const requestDeleteField = async (fieldId) => {
    await onDeleteField(fieldId);
    toast.success('Talhão deletado com sucesso');
    await onFetchFarms();
    await fetchData();
  };

  const requestInactiveField = async (fieldId) => {
    await onInactiveField(fieldId);
    toast.success('Talhão inativado com sucesso');
    await onFetchFarms();
    await fetchData();
  };

  useEffect(() => {
    async function LoadFields() {
      if (!farmId) return;
      await fetchData();
    }
    LoadFields();
  }, [farmId, inactiveFields]);

  const renderZeroStateView = () => {
    if (loading) {
      return (
        <S.ZeroStateWrapper>
          <Spinner animation="border" variant="success" />
          <h4>Carregando talhões...</h4>
        </S.ZeroStateWrapper>
      );
    }
    if (!fields && !loading) {
      return (
        <>
          <S.ZeroStateImg src={noFieldIMG} alt="nofield-img" />
          <h4>Não há talhões cadastrados</h4>
        </>
      );
    }
  };

  return (
    <S.Container>
      <S.ContainerTitle>
        <S.TitleWrapper>
          <S.Title>Talhões</S.Title>
          <SwitchField
            id="inactiveFields"
            label={inactiveFields ? 'Talhões Inativos' : 'Mostrar Talhões Inativos'}
            value={inactiveFields}
            onChange={() => setInactiveFields(!inactiveFields)}
          />
        </S.TitleWrapper>
        <Link to="/field/new">
          <S.ButtonAddField>
            <img src={IconPlus} alt="" />
            Cadastrar Talhão
          </S.ButtonAddField>
        </Link>
      </S.ContainerTitle>
      {fields && <S.ContainerInfo>Clique em um cartão abaixo para visualizar mais informações do talhão</S.ContainerInfo>}
      <S.ContainerList>
        <S.ZeroStateWrapper>
          {renderZeroStateView()}
        </S.ZeroStateWrapper>
        {(fields && fields.map((field) => (
          <FieldCard
            key={field.id}
            field={field}
            onDeleteField={requestDeleteField}
            onInactiveField={requestInactiveField}
          />
        )))}
      </S.ContainerList>
    </S.Container>
  );
};

ListFields.propTypes = {
  onFetchFields: PropTypes.func.isRequired,
  onFetchFarms: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onInactiveField: PropTypes.func.isRequired,
};

const mapStateToProps = (props) => {
  const { farm: { farmSelected } } = props;
  return { farmSelected };
};

const mapDispatchToProps = {
  onFetchFields: fetchFields,
  onFetchFarms: fetchFarms,
  onDeleteField: deleteField,
  onInactiveField: inactiveField,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListFields);
