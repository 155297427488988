import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withFormik, Form } from 'formik';
import { FormGroup } from 'reactstrap';

import CurrentStep from '../../../../common/enum/currentStepEnum';
import StepSelectArea from './stepSelectArea';
import StepCulture from './stepCulture';
import validationSchema from './validationSchema';

import MapContainer from '../map';

import * as S from '../selectArea/styles';

import StepOtherFields from './stepOtherfields';
import StepComponent from '../stepper';

const FormContainer = (props) => {
  const {
    handleSubmit,
    values,
    setFieldValue,
    farmId,
    farmLocation,
  } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [showMap, setShowMap] = useState(false);

  const getCurrentStep = () => {
    switch (currentStep) {
      case CurrentStep.selectArea:
        return (
          <StepSelectArea
            setShowMap={setShowMap}
            showMap={showMap}
            mapImage={values.mapImage}
            setFieldValue={setFieldValue}
          />
        );
      case CurrentStep.cultureSoil:
        return <StepCulture setFieldValue={setFieldValue} values={values} />;
      case CurrentStep.otherFields:
        return <StepOtherFields setFieldValue={setFieldValue} values={values} />;
      default:
        return null;
    }
  };

  const handleNextButton = async () => {
    if (currentStep === CurrentStep.otherFields) {
      return;
    }

    setCurrentStep(currentStep + 1);
  };

  const handlePrevButton = async () => {
    setCurrentStep(currentStep - 1);
  };

  const isCultureStepAllowed = values.name !== ''
    && values.name !== ' '
    && values.coordinates.length >= 3;

  const isOtherFieldsStepAllowed = values.subCategoryId !== null
    && values.subCategory !== null;

  const isFinishAllowed = values.sowingPlantingDate !== '' && values.productionCycle !== '' && values.soilType !== null;

  if (showMap) {
    return (
      <S.Container>
        <MapContainer
          setShowMap={setShowMap}
          showMap={showMap}
          setFieldValue={setFieldValue}
          values={values}
          farmLocation={farmLocation}
        />
      </S.Container>
    );
  }

  const allSteps = {
    selectArea: () => (
      <div>
        <Link to={`/fields/${farmId}`}>
          <S.ButtonCancel type="button">Cancelar</S.ButtonCancel>
        </Link>
        <S.ButtonNext type="button" disabled={!isCultureStepAllowed} onClick={handleNextButton}>Próximo</S.ButtonNext>
      </div>
    ),
    culture: () => (
      <div>
        <S.ButtonCancel type="button" onClick={handlePrevButton}>Voltar</S.ButtonCancel>
        <S.ButtonNext type="button" disabled={!isOtherFieldsStepAllowed} onClick={handleNextButton}>Próximo</S.ButtonNext>
      </div>
    ),
    otherFields: () => (
      <div>
        <S.ButtonCancel type="button" onClick={handlePrevButton}>Voltar</S.ButtonCancel>
        <S.ButtonNext type="submit" disabled={!isFinishAllowed}>Finalizar</S.ButtonNext>
      </div>
    ),
  };

  const stepList = [
    {
      id: 1,
      label: '1. Selecionar Área',
    },
    {
      id: 2,
      label: '2. Cultivo',
    },
    {
      id: 3,
      label: '3. Opções Avançadas',
    },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <S.Container>
        <S.RegisterForm>
          <S.Title>Cadastro de Talhão</S.Title>
          <S.RegistrationSteps>
            <StepComponent
              steps={stepList}
              activeIndex={currentStep}
            />
          </S.RegistrationSteps>
          <S.RegisterContent>
            {getCurrentStep()}
            <FormGroup>
              <S.ButtonsField>
                <h6>* Campo de preenchimento obrigatório</h6>
                {
                  currentStep === CurrentStep.selectArea
                    ? allSteps.selectArea() : null
                }
                {
                  currentStep === CurrentStep.cultureSoil
                    ? allSteps.culture() : null
                }
                {
                  currentStep === CurrentStep.otherFields
                    ? allSteps.otherFields() : null
                }
              </S.ButtonsField>
            </FormGroup>
          </S.RegisterContent>
        </S.RegisterForm>
      </S.Container>
    </Form>
  );
};

FormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  farmId: PropTypes.number.isRequired,
  farmLocation: PropTypes.object.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validationSchema,
  mapPropsToValues: () => ({
    name: '',
    coordinates: [],
    category: null,
    subCategoryId: null,
    subCategory: null,
    sowingPlantingDate: '',
    productionCycle: null,
    productionCycleUnit: null,
    soilType: null,
    sandContent: null,
    siltContent: null,
    clayContent: null,
    soilDensity: null,
    humidityFieldCapacity: null,
    humidityFieldCapacityUnit: {},
    limitHumidityContent: null,
    limitHumidityContentUnit: {},
    soilWaterCapacity: null,
    mapImage: '',
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handleSaveField(values),
})(FormContainer);
