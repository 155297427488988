import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Form from './components/form';
import { register } from '../../../services/contexts/user';
import { apiResultCode } from '../../../constants/apiResultCode';

const Register = () => {
  const history = useHistory();
  const handleRegisterUser = async ({
    name, email, password,
  }) => {
    const user = {
      name,
      email,
      password,
    };
    const result = await register(user);
    if (result && result.statusCode === apiResultCode.Success) {
      toast.success('Usuário registrado com sucesso');
      history.push('/');
      return;
    }
    toast.error('Falha ao registrar usuário');
  };

  return (
    <Form
      handleRegisterUser={handleRegisterUser}
    />
  );
};

export default Register;
