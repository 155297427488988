import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 7.5rem;
  margin-bottom: 1rem;
`;

export const Title = styled.h2`
  margin-top: 1.5rem;
  text-align: left;
  font-weight: bold;
  font-size: 2.25rem;
  color: #303D31;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1.5rem;
`;

export const ContainerDisease = styled.div`
  width: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DiseaseName = styled.label`
  font-size: 1.6rem;
  text-align: center;
`;

const values = {
  Baixa: '#00FF00',
  Média: '#FFE200',
  Alta: '#FF0000',
};

export const ContainerValue = styled.div`
`;

export const DiseaseValue = styled.div`
  ${({ value }) => css`
    width: 7rem;
    height: 7rem;
    border-radius: 100%;
    background-color: ${value && values[value]};
  `}
`;

export const ContainerScale = styled.div`
`;

export const TextLengend = styled.span`
  margin-left: 1.25;
  font-size: 1.45rem;
  font-family: 'Roboto', sans-serif;
  color: #646464;
  font-weight: 400;
`;

export const ContainerLegend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const ContainerFlex = styled.div`
  display: flex;
`;

export const Legend = styled.div`
  ${({ color }) => css`
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    margin-right: 1.25rem;
    margin-top: 0.4rem;
    background-color: ${color};
  `}
`;
