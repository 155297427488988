export const getPolygonCenterPoint = (listCoordinates) => {
  const PI = 22 / 7;
  let X = 0;
  let Y = 0;
  let Z = 0;
  listCoordinates.forEach((coordinate) => {
    let lat1 = coordinate.latitude;
    let lon1 = coordinate.longitude;
    lat1 = (lat1 * PI) / 180;
    lon1 = (lon1 * PI) / 180;
    X += Math.cos(lat1) * Math.cos(lon1);
    Y += Math.cos(lat1) * Math.sin(lon1);
    Z += Math.sin(lat1);
  });
  let longitude = Math.atan2(Y, X);
  const Hyp = Math.sqrt(X * X + Y * Y);
  let latitude = Math.atan2(Z, Hyp);
  latitude = (latitude * 180) / PI;
  longitude = (longitude * 180) / PI;
  return {
    latitude,
    longitude,
  };
};
