import { combineReducers, createStore, applyMiddleware } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';

import UserReducer from './reducers/user';
import FarmReducer from './reducers/farm';
import FieldReducer from './reducers/field';

const store = combineReducers({
  user: UserReducer,
  farm: FarmReducer,
  field: FieldReducer,
});

export default createStore(store, {}, applyMiddleware(thunk, apiMiddleware));
