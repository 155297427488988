export const soilTypeList = [
  { label: 'Arenoso', value: 1 },
  { label: 'Textura Média', value: 2 },
  { label: 'Argiloso', value: 3 },
];

export const humidityFieldCapacityUnitList = [
  { label: 'cm3 cm-3', value: 5 },
  { label: 'g g-1', value: 6 },
];

export const limitHumidityContentUnitList = [
  { label: 'cm3 cm-3', value: 5 },
  { label: 'g g-1', value: 6 },
];
