import styled, { css } from 'styled-components';
import { Label } from 'reactstrap';

export const Container = styled.div`
  flex-direction: row;
`;

export const Checkbox = styled.input`
  width: 1.2rem;
  height: 1.2rem;
`;

export const LabelCheckbox = styled(Label)`
  font-size: 1rem;
  margin-left: 0.5em;
`;

export const LabelLink = styled(Label)`
  ${({ theme }) => css`
    font-size: 1rem;
    margin-left: 0.5em;
    color: ${theme.colors.blue};
    text-decoration: underline;
    cursor: pointer;
  `} 
`;
