import React from 'react';
import PropTypes from 'prop-types';

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
} from 'victory';

import { Container, Title, Subitle } from './styles';

import Divider from '../../../../../components/divider';
import ChartLegend from '../../../../../components/chartLegend';
import {
  chartLegendList,
  COLOR_NDVI_LAST_YEAR,
  COLOR_NDVI_CURRENT_YEAR,
  COLOR_NDVI_THEORETICAL_CURVE,
} from '../../../../../util/chartLabelsAndColors';

const NdviChart = (props) => {
  const {
    lastYearNdvi,
    currentYearNdvi,
    theoreticalNdvi,
  } = props;

  return (
    <Container>
      <Title>NDVI</Title>
      <Divider />
      <VictoryChart
        width={800}
        padding={{
          left: 70, right: 60, bottom: 50, top: 10,
        }}
        domain={{
          y: [0, 1],
        }}
      >
        <VictoryAxis
          style={{
            axis: { stroke: 'none' },
            grid: { stroke: 'gray' },
            tickLabels: { fontSize: 10 },
            axisLabel: { fontSize: 12, padding: 30 },
          }}
          label="Data"
          offsetY={50}
        />
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: 10 },
            grid: { stroke: 'gray' },
            axisLabel: { fontSize: 12, padding: 42 },
          }}
          label="Saúde e Vigor das Plantas (NDVI)"
          crossAxis={false}
        />
        <VictoryLine
          data={lastYearNdvi}
          style={{
            data: { stroke: COLOR_NDVI_LAST_YEAR },
          }}
        />
        <VictoryLine
          data={currentYearNdvi}
          style={{
            data: { stroke: COLOR_NDVI_CURRENT_YEAR },
          }}
        />
        <VictoryLine
          data={theoreticalNdvi}
          style={{
            data: { stroke: COLOR_NDVI_THEORETICAL_CURVE },
          }}
        />
      </VictoryChart>
      <ChartLegend items={chartLegendList.ndvi} />
      <Subitle>
        Nota: Compare o desenvolvimento da sua lavoura ao de uma lavoura hipotética sob condições ótimas,
        {' '}
        para a lavoura selecionada e para a data de plantio definido.
      </Subitle>
    </Container>
  );
};

NdviChart.propTypes = {
  lastYearNdvi: PropTypes.array.isRequired,
  currentYearNdvi: PropTypes.array.isRequired,
  theoreticalNdvi: PropTypes.array.isRequired,
};

export default NdviChart;
