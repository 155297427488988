import styled from 'styled-components';
import { Dropdown } from 'antd';

export const Wrapper = styled(Dropdown)`
    background-color: none;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 4px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
    transition: transform .7s ease-in-out;

    &:hover {
        background-color: none;
        transform: rotate(180deg);

        svg {
        box-shadow: 4px 6px 19px #00000029;
        }
    }
`;

export const WrapperFarm = styled(Dropdown)`
    background-color: none;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 4px;
    margin-right: 0px;
    cursor: pointer;
`;
