import styled from 'styled-components';

export const Container = styled.div`
  padding: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 8px;
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
`;
