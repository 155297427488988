import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Form,
  FormGroup,
} from 'reactstrap';

import {
  Container,
} from './styles';

import IconSearch from '../../assets/images/search.svg';

const SearchInput = (props) => {
  const {
    value,
    onChange,
  } = props;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Container>
      <Form>
        <FormGroup>
          <Input
            type="search"
            name="search"
            placeholder="Procurar"
            value={value}
            onChange={handleChange}
          />
          <img src={IconSearch} alt="" />
        </FormGroup>
      </Form>
    </Container>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchInput;
