import styled from 'styled-components';

export const LogoImg = styled.img`
  width: 25rem;
  margin-bottom: 6rem;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    color: #FFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 3.85rem;
    text-align: center;
    font-weight: 300;
    line-height: 5rem;
  }
  b {
    font-weight: bold;
  }
`;
