import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Form from '../components/form';
import { saveFarm } from '../../../store/actions/farm';

const CreateFarm = (props) => {
  const { onSaveFarm } = props;

  const history = useHistory();

  const handleSaveFarm = async (values) => {
    const farm = {
      name: values.name,
      state: values.state.value,
      cityId: values.cityId.value,
      neighborhood: values.neighborhood,
      street: values.street,
      number: values.number,
    };
    const result = await onSaveFarm(farm);
    if (result) {
      toast.success('Fazenda cadastrada com sucesso');
      history.push('/home');
      return;
    }
    toast.error('Falha ao cadastrar fazenda');
  };

  return (
    <Form
      handleSaveFarm={handleSaveFarm}
    />
  );
};

CreateFarm.propTypes = {
  onSaveFarm: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onSaveFarm: saveFarm,
};

export default connect(null, mapDispatchToProps)(CreateFarm);
