import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('O campo nome é obrigatório'),
  coordinates: Yup.array().min(3, 'É necessario selecionar a área do talhão'),
  category: Yup.number().required('O campo cultivar é obrigatório'),
  subCategoryId: Yup.object().required('O campo cultivar é obrigatório').nullable(),
  sowingPlantingDate: Yup.string().required('O campo data de semeadura ou plantio é obrigatório'),
  productionCycle: Yup.number()
    .when(['subCategory', 'category'], (subCategory, category) => {
      if (category === 1 || category === 3) {
        const { minProductionCycleDays, maxProductionCycleDays } = subCategory || {};
        if (!minProductionCycleDays || !maxProductionCycleDays) {
          return Yup.number()
            .required('O campo ciclo de produção é obrigatório e deve ser um número inteiro positivo')
            .typeError('O campo ciclo de produção é obrigatório e deve ser um número inteiro positivo');
        }
        return Yup.number()
          .min(minProductionCycleDays, `O campo ciclo de produção deve estar entre ${minProductionCycleDays} e ${maxProductionCycleDays}`)
          .max(maxProductionCycleDays, `O campo ciclo de produção deve estar entre ${minProductionCycleDays} e ${maxProductionCycleDays}`)
          .typeError('O campo ciclo de produção é obrigatório e deve ser um número inteiro positivo');
      }
      return Yup.number()
        .required('O campo ciclo de produção é obrigatório e deve ser um número inteiro positivo')
        .typeError('O campo ciclo de produção é obrigatório e deve ser um número inteiro positivo');
    }),
  soilType: Yup.object().required('O campo tipo de solo é obrigatório').typeError('O campo tipo de solo é obrigatório'),
});

export default validationSchema;
