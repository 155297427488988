import api from '../config';

export const getCategories = async () => {
  try {
    const response = await api.get('/categories');
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getSubCategories = async (categoryId) => {
  try {
    const response = await api.get(`/sub-categories?categoryId=${categoryId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};
