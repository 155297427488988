const hasStorage = (storageType) => {
  let supportsStorage = false;
  if (typeof window.Storage !== 'undefined') {
    try {
      supportsStorage = storageType in window && window[storageType] !== null;
      window[storageType].setItem(storageType, 1);
      window[storageType].removeItem(storageType);
      supportsStorage = true;
    } catch (e) { supportsStorage = false; }
  }
  return supportsStorage;
};

export const getData = (key) => {
  let data = null;
  if (window.localStorage) {
    try {
      data = JSON.parse(window.localStorage.getItem(key));
    } catch (e) { /* Ignore */ }
  }

  return data;
};

export const getAuthData = () => getData('weatherAccessData');

export const getRefreshToken = () => {
  const data = getAuthData();
  if (data) {
    return data.token;
  }
  return null;
};

export const getUser = () => getAuthData();

const hasLocalStorage = () => hasStorage('weatherAccessData');

export const saveData = (value) => {
  if (!hasLocalStorage) return;
  window.localStorage.setItem('weatherAccessData', JSON.stringify(value));
};

export const deleteData = () => {
  if (!hasLocalStorage) return;
  try {
    window.localStorage.removeItem('weatherAccessData');
  } catch (e) { /* Ignore */ }
};
