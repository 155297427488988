import api from '../config';

export const getFields = async () => {
  try {
    const response = await api.get('/fields');
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getFieldByFarmId = async (farmId, isActive) => {
  try {
    const response = await api.get('/fields/filtered', {
      params: {
        farmId,
        isActive,
      },
    });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const postField = async (field) => {
  try {
    const response = await api.post('/fields', field);
    if (response.status === 201) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const putField = async (farmId, field) => {
  try {
    const response = await api.put(`/fields/${farmId}`, field);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const sendDeleteField = async (fieldId) => {
  try {
    const response = await api.delete(`/fields/${fieldId}/`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const sendInactiveField = async (fieldId) => {
  try {
    const response = await api.post(`/fields/${fieldId}/inactive`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    console.log({ err });
    return null;
  }
};

export const getFieldById = async (fieldId) => {
  try {
    const response = await api.get(`/fields/${fieldId}`);

    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getClimatePrecipitation = async (fieldId) => {
  try {
    const response = await api.get(`/climate-precipitation?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getMonthlyPrecipitation = async (fieldId) => {
  try {
    const response = await api.get(`/monthly-precipitation?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getTemperatureClimatology = async (fieldId) => {
  try {
    const response = await api.get(`/temperature-climatology?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getMonthlyTemperature = async (fieldId) => {
  try {
    const response = await api.get(`/month-temperature?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getAccumulatedPrecipitation = async (fieldId) => {
  try {
    const response = await api.get(`/accumulated-precipitation?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getDailyPrecipitationForecast = async (fieldId) => {
  try {
    const response = await api.get(`/accumulated-precipitation-forecast?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getMonthlyPrecipitationForecast = async (fieldId) => {
  try {
    const response = await api.get(`/seasonal-precipitation/${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getDailyTemperatureForecast = async (fieldId) => {
  try {
    const response = await api.get(`/climatology/hydric-balance/temperature?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getMonthlyTemperatureForecast = async (fieldId) => {
  try {
    const response = await api.get(`/seasonal-temperature/${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getHydricBalance = async (fieldId) => {
  try {
    const response = await api.get(`/agribusiness/hydric-balance?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getNdvi = async (fieldId) => {
  try {
    const response = await api.get(`/ndvi?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getPulverization = async (fieldId) => {
  try {
    const response = await api.get(`/pulverization?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getGlmImages = async (fieldId) => {
  try {
    const response = await api.get(`/fields/v2/${fieldId}/images/glm`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getRrqpefImages = async (fieldId) => {
  try {
    const response = await api.get(`/fields/v2/${fieldId}/images/rrqpef`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getFireRiskImages = async (fieldId) => {
  try {
    const response = await api.get(`/fields/data/${fieldId}/images/fire_risk`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getDiseases = async (fieldId) => {
  try {
    const response = await api.get(`/disease-favorability?fieldId=${fieldId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getFocusFireImages = async (fieldId) => {
  try {
    const response = await api.get(`/fields/data/${fieldId}/images/focus_fire`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};
