import WithoutPrec from '../assets/images/no_water_drops.png';
import DailyPrec5mm from '../assets/images/one_water_drop.png';
import DailyPrec15mm from '../assets/images/two_water_drops.png';
import DailyPrecMax from '../assets/images/three_water_drops.png';

export const getPrecInfo = (value) => {
  let prec = {};
  if (value > 15) {
    prec = {
      icon: DailyPrecMax,
      label: '> 15mm',
    };
  }
  if (value <= 15 && value > 5) {
    prec = {
      icon: DailyPrec15mm,
      label: '5mm e 15mm',
    };
  }
  if (value <= 5 && value > 0) {
    prec = {
      icon: DailyPrec5mm,
      label: '<= 5mm',
    };
  }
  if (value === 0) {
    prec = {
      icon: WithoutPrec,
      label: 'Sem chuva',
    };
  }

  return prec;
};
