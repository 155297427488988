import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import {
  Form,
  FormGroup,
} from 'reactstrap';
// Components
import { ButtonGreen, LinkButton } from '../../../../components/styles/button';
import FormField from '../../../../components/forms/formField';
import AboutContainer from '../../../../components/aboutContainer';

import * as S from '../../styles';

const NewPasswordForm = (props) => {
  const {
    handleSubmit,
  } = props;

  return (
    <S.Layout>
      <S.Layout>
        <S.LeftContainer width={500}>
          <S.TitleRegister>Criar Senha Nova</S.TitleRegister>
          <Form onSubmit={handleSubmit} noValidate>
            <FormGroup>
              <FormField
                id="newPassword"
                name="newPassword"
                label="Senha"
                placeholder="Digite sua senha nova..."
                type="password"
                isRequired
              />
            </FormGroup>
            <FormGroup>
              <S.ButtonsRegister>
                <ButtonGreen type="submit">Resetar Senha</ButtonGreen>
                <LinkButton to="/" className="btn">VOLTAR</LinkButton>
              </S.ButtonsRegister>
            </FormGroup>
          </Form>
          <S.LinksContainer>
            <S.Label>
              Já possui uma conta?
              {' '}
              <S.LinkLabel to="/">ENTRE.</S.LinkLabel>
            </S.Label>
          </S.LinksContainer>
        </S.LeftContainer>
        <S.Content>
          <AboutContainer />
        </S.Content>
      </S.Layout>
    </S.Layout>
  );
};

NewPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    newPassword: '',
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handlePasswordRecovery(values),
})(NewPasswordForm);
