import styled from 'styled-components';

export const Container = styled.div`
`;

export const Title = styled.h2`
  margin-top: 6.5rem;
  margin-bottom: 2.25rem;
  text-align: left;
  font-weight: bold;
  font-size: 2.25rem;
  color: #303D31;
`;

export const ContainerLegend = styled.div`
  margin-bottom: 50px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-right: 2.5vw;
`;

export const Legend = styled.div`
  display: flex;
`;

export const ColorLegend = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.color};
  background-color: ${(props) => props.color};
`;

export const TextLengend = styled.div`
  margin-left: 5px;
`;
