import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.25rem;
`;

export const Title = styled.h2`
  text-align: left;
  font-weight: bold;
  font-size: 2.25rem;
  color: #303D31;
  margin-bottom: 1.5rem;
  margin-top: 6.5rem;
`;
