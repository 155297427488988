import React from 'react';
import Logo from '../../../../assets/images/agro-logo2.svg';
import IconAndroid from '../../../../assets/images/icon-android.png';
import IconIos from '../../../../assets/images/icon-ios.png';

import {
  Mobile,
  ContainerMobile,
  ContainerFlex,
  ButtonAndroid,
  ButtonIos,
} from '../../styles';

const ContentMobile = () => (
  <Mobile>
    <ContainerMobile>
      <img src={Logo} alt="Weather Service" />
      <h3>Não foi possível acessar o site</h3>
      <h4>Baixe o aplicativo:</h4>
      <ContainerFlex>
        <a
          href="https://play.google.com/store/apps/details?id=com.weatherappmobile"
          target="_blank"
          rel="noreferrer"
        >
          <ButtonAndroid type="button">
            Google Play
            <img src={IconAndroid} alt="" />
          </ButtonAndroid>
        </a>
        <a
          href="https://apps.apple.com/us/app/weather-service/id1574126752#?platform=iphone"
          target="_blank"
          rel="noreferrer"
        >
          <ButtonIos type="button">
            App Store
            <img src={IconIos} alt="" />
          </ButtonIos>
        </a>
      </ContainerFlex>
    </ContainerMobile>
  </Mobile>
);

export default ContentMobile;
