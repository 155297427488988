import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  form {
    width: 100%;
  }

  .form-group {
    display: flex;
    position: relative;
  }

  .form-control {
    padding: 0.875rem 2rem;
    height: auto;
    font-size: 1.5rem;
  }

  .mb-3 img { 
    position: absolute;
    right: 50px;
    top: 33px;
  }
`;
