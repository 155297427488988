import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdOutlineLogout, MdNoteAlt } from 'react-icons/md';
// assets
import Logo from '../../../assets/images/agro-logo2.svg';
// components
import Avatar from '../../avatar';
// actions
import { doLogout } from '../../../store/actions/user';

import { getUser } from '../../../storage';

import { PrivacyPolicyLink } from '../../../constants/links';

import * as S from './styles';

const Navbar = (props) => {
  const [userName, setUserName] = useState();

  const { onLogout } = props;

  const history = useHistory();

  const logoutUser = async () => {
    const result = await onLogout();
    if (result) {
      history.push('/');
    }
  };

  useEffect(() => {
    const user = getUser();
    setUserName(user && user.name);
  }, []);

  return (
    <S.Nav>
      <S.ContainerLogo>
        <Link to="/home">
          <img src={Logo} alt="Weather Service" />
        </Link>
      </S.ContainerLogo>
      <S.MenuOptions>
        <Link to="/home">
          <Label>DASHBOARD</Label>
          <hr />
        </Link>
      </S.MenuOptions>
      <S.ContainerRight>
        <S.Dropdown>
          <S.DropdownLabel>
            <S.ContainerUser>
              <Avatar fullName={userName || ''} />
            </S.ContainerUser>
          </S.DropdownLabel>
          <S.Container className="dropdwn">
            <S.Content onClick={() => window.open(PrivacyPolicyLink)}>
              <MdNoteAlt size={30} />
              <span>Política de privacidade</span>
            </S.Content>
            <S.Content onClick={() => logoutUser()}>
              <MdOutlineLogout size={30} />
              <span>Sair</span>
            </S.Content>
          </S.Container>
        </S.Dropdown>
      </S.ContainerRight>
    </S.Nav>
  );
};

Navbar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onLogout: doLogout,
};

export default connect(null, mapDispatchToProps)(Navbar);
