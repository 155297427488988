import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import {
  Form,
  FormGroup,
} from 'reactstrap';
// Components
import { ButtonGreen, LinkButton } from '../../../../components/styles/button';
import FormField from '../../../../components/forms/formField';
import AboutContainer from '../../../../components/aboutContainer';
import CheckBox from '../../../../components/checkbox';

import { PrivacyPolicyLink } from '../../../../constants/links';

import * as S from '../../styles';

const RegisterProducer = (props) => {
  const {
    handleSubmit,
    values,
  } = props;

  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const isSiginComplete = values.name.length > 0
    && values.email.length > 0
    && values.password.length > 0
    && privacyPolicy === true;

  return (
    <S.Layout>
      <S.Layout>
        <S.LeftContainer width={500}>
          <S.TitleRegister>Cadastrar Novo Produtor</S.TitleRegister>
          <Form onSubmit={handleSubmit} noValidate>
            <FormGroup>
              <FormField
                id="name"
                name="name"
                label="Nome"
                placeholder="Nome"
                isRequired
              />
            </FormGroup>
            <FormGroup>
              <FormField
                id="email"
                name="email"
                label="E-mail"
                placeholder="E-mail"
                isRequired
              />
            </FormGroup>
            <FormGroup>
              <FormField
                id="password"
                name="password"
                label="Senha"
                placeholder="Senha"
                type="password"
                isRequired
              />
            </FormGroup>
            <FormGroup>
              <CheckBox
                handleClick={() => setPrivacyPolicy(!privacyPolicy)}
                openLink={() => window.open(PrivacyPolicyLink)}
                label="Aceito a "
                labelLink="Política de Privacidade e os Termos de Uso"
              />
            </FormGroup>
            <FormGroup>
              <S.ButtonsRegister>
                <ButtonGreen type="submit" disabled={!isSiginComplete}>CADASTRAR</ButtonGreen>
                <LinkButton to="/" className="btn">VOLTAR</LinkButton>
              </S.ButtonsRegister>
            </FormGroup>
          </Form>
          <S.LinksContainer>
            <S.Label>
              Já possui uma conta?
              {' '}
              <S.LinkLabel to="/">ENTRE.</S.LinkLabel>
            </S.Label>
          </S.LinksContainer>
        </S.LeftContainer>
        <S.Content>
          <AboutContainer />
        </S.Content>
      </S.Layout>
    </S.Layout>
  );
};

RegisterProducer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: '',
    email: '',
    password: '',
  }),
  handleSubmit: async (values, formikBag) => formikBag.props.handleRegisterUser(values),
})(RegisterProducer);
