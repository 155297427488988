import styled, { css } from 'styled-components';

import { Col } from 'react-bootstrap';

export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.875rem;
`;

export const ContainerCard = styled(Col)`
  min-width: 11.5rem;
  height: auto;
  padding-left: 0px;
  padding-right: 20px;
  margin-top: 20px;
  
  ${(props) => props.lastCard && css`
    padding-right: 0px;
  `}
`;

export const ContainerDetails = styled.div`
  width: 100%;
  padding: 3rem 2rem 0 4.75rem;
`;

export const Content = styled.div`
  padding-bottom: 30px;
  margin-bottom: 25px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #CED4DA;
  background-color: #fff;
`;

export const Details = styled.div`
  padding: 3rem 5rem;
`;

export const ImageField = styled.div`
  height: 199px;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  position: relative;
  z-index: 3;
  & > img {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    height: auto;
  }
`;

export const EditMap = styled.div`
  & > img {
    margin-top: -180px;
    margin-left: 870px;
    position: absolute;
    z-index: 4;
  }
  @media (max-width: 1024px) {
    & > img {
      margin-top: -140px;
      margin-left: 606px;
    }
  }
  @media (max-width: 768px) {
    & > img {
      margin-left: 627px;
    }
  }
`;

export const ContainerName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 1.5rem;
  color: #303D31;
  text-transform: capitalize;
`;

export const ContainerLocal = styled.div``;

export const ImagePin = styled.div`
  margin-right: 0px;
  margin-top: 20px;
  & > img {
    width: 16px;
    height: 23.11px;
    color: #6E7F6F;
  }
  @media (max-width: 1024px) {
    margin-left: 320px;
  }
  @media (max-width: 768px) {
    margin-left: 340px;
  }
`;

export const LocalField = styled.h6`
  font-weight: bold;
  color: #6C757D;
`;

export const ContainerGrains = styled.div`
  margin-top: 2.5rem;
  margin-right: 0px;
`;

export const CardGrain = styled.div`
  min-width: 143px;
  height: 132px;
  margin-top: 20px;
  margin-bottom: 0px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #ECEFEA;
  background-color: #fff;
  text-align: center;
  & > img {
    width: 43.4px;
    height: 43.4px;
    margin-top: 15px;
  }
`;

export const EditStepCulture = styled.div`
  width: 157px;
  height: 32px;
  margin-top: 10px;
  padding: 0px;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  color: #20C997;
  border-radius: 4px;
  border: 1px solid #20C997;
  & > label {
    cursor: pointer;
  }
  & > img {
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 10px;
  }
`;

export const ContainerEditSoil = styled.div`
  width: 36px;
  height: 32px;
  margin-top: 10px;
  padding: 0px;
  background-color: #fff;
  color: #20C997;
  border-radius: 4px;
  border: 1px solid #20C997;
  cursor: pointer;
  & > img {
    margin-top: 0px;
    margin-left: 0px;
  }
`;

export const TypeGrain = styled.h6`
  margin-left: 0px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  color: #314B4D;
`;

export const NameGrain = styled.h6`
  margin-left: 0px;
  margin-top: 10px;
  font-size: 19px;
  font-weight: bold;
  color: #314B4D;
`;

export const CardGround = styled.div`
  height: 74px;
  text-align: left;
  border-radius: 10px;
  border: 1px solid #ECEFEA;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  // padding-right: 0.5vw;
  padding-right: 0vw;
  padding-left: 1vw;
  & > img {
    width: 34.96px;
    height: 40.19px;
    margin-left: 0px;
    margin-right: 1vw;
    margin-top: 15px;
  }
  @media (max-width: 1024px) {
    margin-right: 18px;
    margin-top: 18px;
    & > img {
      margin-top: 10px;
    }
  }
  @media (max-width: 768px) {
    margin-right: 22px;
  }
`;

export const ContentGround = styled.div``;

export const TypeGround = styled.h6`
  margin-left: 0px;
  margin-top: 0.68vw;
  font-size: 12px;
  font-weight: normal;
  color: #314B4D;
  @media (max-width: 1024px) {
    margin-left: 10px;
  }
`;

export const NameGround = styled.h6`
  margin-left: 0px;
  font-size: 18px;
  font-weight: bold;
  color: #314B4D;
  @media (max-width: 1024px) {
    margin-left: 10px;
  }
`;

export const ContainerSoilType = styled.div``;

export const OptionsChart = styled.a`
  width: auto;
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: normal;
  color: #314B4D;
`;
