import _ from 'lodash';
import { DateTime } from 'luxon';

export const getLabelMonths = () => {
  const now = DateTime.now().setLocale('pt-br').minus({ month: 1 });
  const months = [];
  _.range(12).reverse().forEach((i) => {
    months.push(now.minus({ month: i }).monthShort);
  });
  return months;
};
