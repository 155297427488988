import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { codeValidation } from '../../../store/actions/user';
import HaveCodeForm from './components/form';

const HaveCode = (props) => {
  const { onHaveCode } = props;
  const history = useHistory();

  const handleCodeValidate = async (code) => {
    await onHaveCode(code);
    history.push('/new-password');
  };

  return (
    <HaveCodeForm
      handleCodeValidate={handleCodeValidate}
    />
  );
};

HaveCode.propTypes = {
  onHaveCode: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onHaveCode: codeValidation,
};

export default connect(null, mapDispatchToProps)(HaveCode);
