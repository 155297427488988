import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import Layout from './components/layout';
import Login from './pages/public/login';
import Home from './pages/home';
import Register from './pages/public/register';
import ResetPassword from './pages/public/reset-password';
import HaveCode from './pages/public/have-code';
import NewPassword from './pages/public/new-password';
import CreateFarm from './pages/farms/create';
import AuthGate from './components/authGate';
import CreateField from './pages/fields/create';
import EditFieldArea from './pages/fields/edit/selectArea';
import EditFieldCulture from './pages/fields/edit/culture';
import EditFieldSoil from './pages/fields/edit/soil';
import DetailsField from './pages/fields/details';
import ListFields from './pages/fields/list';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/have-code" component={HaveCode} />
      <Route exact path="/new-password" component={NewPassword} />
      <Route>
        <AuthGate />
        <Layout>
          {/* <Route exact path="/"><Redirect to="/home" /></Route> */}
          <Route exact path="/home" component={Home} />
          <Route exact path="/farm/new" component={CreateFarm} />
          <Route exact path="/field/new" component={CreateField} />
          <Route exact path="/field/area" component={EditFieldArea} />
          <Route exact path="/field/culture" component={EditFieldCulture} />
          <Route exact path="/field/soil" component={EditFieldSoil} />
          <Route exact path="/field/details/:id" component={DetailsField} />
          <Route exact path="/fields/:id" component={ListFields} />

        </Layout>
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Router;
