import api from '../config';

export const getStates = async () => {
  try {
    const response = await api.get('/states');
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getCities = async (stateId) => {
  try {
    const response = await api.get(`/cities?stateId=${stateId}`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (err) {
    return null;
  }
};
