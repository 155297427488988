import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Form from '../components/form';
import { addField } from '../../../store/actions/field';
import { getPolygonCenterPoint } from '../../../helpers/calcs';
import { formatToSubmit } from '../../../helpers/date-helper';
import { fetchFarms } from '../../../store/actions/farm';

const CreateField = (props) => {
  const {
    farmSelected,
    farmLocation,
    onAddField,
    onFetchFarms,
  } = props;

  const history = useHistory();

  const handleSaveField = async (values) => {
    const field = {
      name: values.name,
      coordinates: values.coordinates,
      category: values.category,
      subCategoryId: values.subCategoryId.value,
      sowingPlantingDate: formatToSubmit(values.sowingPlantingDate),
      productionCycle: values.productionCycle,
      productionCycleUnit: values.productionCycleUnit,
      soilType: values.soilType.value,
      sandContent: values.sandContent,
      siltContent: values.siltContent,
      clayContent: values.clayContent,
      soilDensity: values.soilDensity,
      humidityFieldCapacity: values.humidityFieldCapacity,
      humidityFieldCapacityUnit: values.humidityFieldCapacityUnit.value,
      limitHumidityContent: values.limitHumidityContent,
      limitHumidityContentUnit: values.limitHumidityContentUnit.value,
      soilWaterCapacity: values.soilWaterCapacity,
      altitude: 0,
      centerPoint: getPolygonCenterPoint(values.coordinates),
      farmId: farmSelected,
    };
    try {
      const result = await onAddField({ ...field });
      if (result) {
        toast.success('Talhão cadastrado com sucesso');
        history.push(`/fields/${farmSelected}`);
        await onFetchFarms();
      }
    } catch (error) {
      toast.error('Falha ao cadastrar talhão');
    }
  };

  return (
    <Form handleSaveField={handleSaveField} farmId={farmSelected} farmLocation={farmLocation} />
  );
};

CreateField.propTypes = {
  onAddField: PropTypes.func.isRequired,
  farmSelected: PropTypes.number.isRequired,
  farmLocation: PropTypes.object.isRequired,
  onFetchFarms: PropTypes.func.isRequired,
};

const mapStateToProps = (props) => {
  const {
    farm: {
      farms, farmSelected, farmLocation,
    },
  } = props;
  return {
    farms, farmSelected, farmLocation,
  };
};

const mapDispatchToProps = {
  onAddField: addField,
  onFetchFarms: fetchFarms,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateField);
