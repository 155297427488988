import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, selected }) => css`
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    background-color: ${selected ? theme.colors.card.selected : theme.colors.white};
    border-top: 1px solid ${theme.border.colors.regular};

    &:last-child {
      border-bottom: 1px solid ${theme.border.colors.regular};
    }

    &:hover { 
      cursor: pointer;
    }

    h3 {
      color: #314B4D;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
    }

    h4 { 
      color: #6E7F6F;
      font-size: 1.2rem;
      text-decoration: underline;
      font-weight: bold;
      margin-bottom: 0px;
      padding-right: 10px;
      margin-bottom: 1rem;
    }

    h5 { 
      color: ${theme.colors.mediumGrey};
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  `}

`;

export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FieldsCount = styled.div`
  display: flex;
  flex-direction: row;
`;
