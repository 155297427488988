import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 3rem 2rem 0 4.75rem;
`;

export const Title = styled.h6`
  ${({ theme }) => css`
    margin-bottom: 3rem;
    font-size: ${theme.fonts.sizes.xxlarge};
    font-weight: bold;
    color: ${theme.colors.darkGrey};
  `}
`;

export const ContainerFlex = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const ContainerQuantity = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 25rem;
    height: 24rem;
    margin-bottom: 2rem;
    border-radius: 1.5rem;
    background-color: ${theme.colors.white};
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.border.colors.light};
  `}
`;

export const QuantityLabel = styled.div`
    color: #A2C667;
    font-weight: bold;
    font-size: 30px;
    align-self: center;
    align-items: center;
`;

export const InfoLabel = styled.div`
    color: #303D31;
    font-weight: normal;
    align-self: center;
    align-items: center;
    font-size: 18px;
`;
